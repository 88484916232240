import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as _ from 'lodash'

import {CloudflareSettings} from '../../../domains-list/core/_models'
import CloudflareBinarySetting from '../common/CloudflareBinarySetting'
import CloudflareMultipleSetting from '../common/CloudflareMultipleSetting'
import {getCloudflareCertificates} from '../../../domains-list/core/_requests'
export function SSL() {
  const {cloudflareDomainId} = useParams()
  const [certificates, setCertificates] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getCloudflareCertificates(cloudflareDomainId ? cloudflareDomainId : '').then((resp) => {
      setCertificates(resp != undefined ? resp : [])
      setLoading(false)
    })
  }, [])

  return (
    <>
      <div className='card mb-5 mb-xl-10 w-100' id='ssl_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>SSL</h3>
          </div>
        </div>

        <div className='m-8'>
          {/* SSL/TLS Encryption Mode */}
          <CloudflareMultipleSetting
            title='SSL/TLS Encryption Mode'
            description='Encryption modes secure connections between visitors and Cloudflare, and Cloudflare
            and origin servers by determining how data is encrypted during transit. TLS is
            commonly used for visitor connections, and these modes dictate the security for data
            between Cloudflare and the origin server.'
            setting={CloudflareSettings.SSL}
            options={[
              ['Off', 'off'],
              ['Full', 'full'],
              ['Flexible', 'flexible'],
              ['Strict', 'strict'],
            ]}
            cloudflareDomainId={cloudflareDomainId}
          />

          {/* TLS 1.3 */}
          <CloudflareBinarySetting
            title='TLS 1.3'
            description='Enable the latest version of the TLS protocol for improved security and performance.'
            setting={CloudflareSettings.TLS_1_3}
            cloudflareDomainId={cloudflareDomainId}
          />

          {/* Opportunistic Encryption */}
          <CloudflareBinarySetting
            title='Opportunistic Encryption'
            description='Opportunistic Encryption allows browsers to benefit from the improved performance of
            HTTP/2 by letting them know that your site is available over an encrypted
            connection. Browsers will continue to show “http” in the address bar, not “https”.'
            setting={CloudflareSettings.OPPURTUNISTIC_ENCRYPTION}
            cloudflareDomainId={cloudflareDomainId}
          />

          {/* Edge Certificates */}
          <div className='rounded-3 border p-4 mb-4 d-flex flex-column'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>Edge Certificates</h5>
              <p className='text-muted'>List of your SSL certificates deployed on Cloudflare</p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center table-responsive'
              style={{flex: '0 0 20%'}}
            >
              <table className='table table-rounded table-striped border gy-7 gs-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th style={{width: '18%'}}>Hosts</th>
                    <th style={{width: '18%'}}>Type</th>
                    <th style={{width: '18%'}}>Status</th>
                    <th style={{width: '18%'}}>Created On</th>
                    <th style={{width: '18%'}}>Validity</th>
                    <th style={{width: '18%'}}>CA</th>
                  </tr>
                </thead>
                <tbody>
                  {certificates.map((certificate) => (
                    <tr>
                      <td>{certificate.hosts.join(', ')}</td>
                      <td>{_.capitalize(certificate.type)}</td>
                      <td>{_.capitalize(certificate.status)}</td>
                      <td>{new Date(certificate.created_on).toISOString().substring(0, 10)}</td>
                      <td>{certificate.validity_days} Days</td>
                      <td>{_.capitalize(certificate.certificate_authority)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
