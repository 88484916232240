import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {
  BackupSettings,
  BackupLocationType,
  StorageProvider,
} from '../../../../applications-list/core/_models'
import {
  getAvailableStorageProviders,
  getBackupSettings,
  setScheduledBackupSettings,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const BackupSettingsPage: React.FC = () => {
  const {applicationId} = useParams()
  const [remoteStorages, setRemoteStorages] = useState<StorageProvider[]>([])

  const [initialSettings, setInitialSettings] = useState<BackupSettings>({
    status: true,
    scheduled_time: {
      hours: '12',
      minutes: '30',
    },
    frequency: 24,
    retention_period: 2,
    location: BackupLocationType.LOCAL,
  })

  const hoursOptions = Array.from({length: 24}, (_, index) => ({
    value: index.toString().padStart(2, '0'),
    label: index.toString().padStart(2, '0'),
  }))
  const minutesOptions = Array.from({length: 60}, (_, index) => ({
    value: index.toString().padStart(2, '0'),
    label: index.toString().padStart(2, '0'),
  }))

  const frequencyOptions = [
    {value: 1, label: '1 hour'},
    {value: 3, label: '3 hours'},
    {value: 6, label: '6 hours'},
    {value: 12, label: '12 hours'},
    {value: 24, label: '1 day'},
    {value: 48, label: '2 days'},
    {value: 72, label: '3 days'},
    {value: 96, label: '4 days'},
    {value: 120, label: '5 days'},
    {value: 144, label: '6 days'},
    {value: 168, label: '1 week'},
  ]

  const retentionPeriodOptions = [
    {value: 1, label: '1 week'},
    {value: 2, label: '2 weeks'},
    {value: 3, label: '3 weeks'},
    {value: 4, label: '4 weeks'},
  ]

  const formik = useFormik<BackupSettings>({
    initialValues: initialSettings,
    onSubmit: (values) => {
      setScheduledBackupSettings(applicationId ?? '', values)
        .then(() => {
          notifySuccess('Backup Settings Saved')
        })
        .catch((error) => {
          notifyError('Backup Settings failed: ' + error.message)
        })
    },
  })

  const getBackupSetting = async () => {
    try {
      const settings = await getBackupSettings(applicationId ?? '')
      if (settings) {
        setInitialSettings(settings)
      } else {
        formik.setFieldValue('status', false)
      }
    } catch (error) {
      console.log(error)
      formik.setFieldValue('status', false)
    }
  }

  const getStorageProviders = async () => {
    try {
      const providers = await getAvailableStorageProviders()
      if (providers) {
        setRemoteStorages(providers)
      } else {
        setRemoteStorages([])
      }
    } catch (error) {
      console.log(error)
      setRemoteStorages([])
    }
  }

  useEffect(() => {
    getBackupSetting()
    getStorageProviders()
  }, [applicationId])

  useEffect(() => {
    if (formik.values.location === BackupLocationType.LOCAL) {
      formik.setFieldValue('storage_provider', null)
    }
    if (formik.values.location === BackupLocationType.REMOTE) {
      formik.setFieldValue('storage_provider', remoteStorages[0])
    }
  }, [formik.values.location])

  return (
    <div className='container-fluid'>
      <TabHeader heading='Schedule Backup' />
      <TabBody>
        <div className='text-primary'>Configure the settings to schedule your backup</div>
        <div className='separator border-secondary my-6'></div>
        <div className='mt-2'></div>
        <form onSubmit={formik.handleSubmit}>
          <Row className='mb-5 align-items-center'>
            <Col sm='4'>
              <label className='form-label'>Status :</label>
            </Col>
            <Col sm='7'>
              <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                <input
                  type='checkbox'
                  name='status'
                  className='form-check-input w-45px h-30px'
                  checked={formik.values.status}
                  onChange={formik.handleChange}
                  style={{
                    backgroundColor: formik.values.status ? 'green' : 'grey',
                    borderColor: formik.values.status ? 'green' : 'grey',
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row className='mb-5 align-items-center'>
            <Col sm='4'>
              <label className='form-label'>Scheduled Time :</label>
            </Col>
            <Col sm='4'>
              <label className='form-label'>Hours:</label>
              <select
                name='scheduled_time.hours'
                disabled={formik.values.status == false}
                value={formik.values.scheduled_time.hours}
                onChange={formik.handleChange}
                className='form-control'
              >
                {hoursOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm='4'>
              <label className='form-label'>Minutes:</label>
              <select
                name='scheduled_time.minutes'
                disabled={formik.values.status == false}
                value={formik.values.scheduled_time.minutes}
                onChange={formik.handleChange}
                className='form-control'
              >
                {minutesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className='mb-5 align-items-center'>
            <Col sm='4'>
              <label className='form-label'>Frequency (in Hours) :</label>
            </Col>
            <Col sm='8'>
              <select
                name='frequency'
                disabled={formik.values.status == false}
                value={formik.values.frequency}
                onChange={formik.handleChange}
                className='form-control'
              >
                {frequencyOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className='mb-5 align-items-center'>
            <Col sm='4'>
              <label className='form-label'>Retention Period (in Weeks) :</label>
            </Col>
            <Col sm='8'>
              <select
                name='retention_period'
                disabled={formik.values.status == false}
                value={formik.values.retention_period}
                onChange={formik.handleChange}
                className='form-control'
              >
                {retentionPeriodOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className='mb-5 align-items-center'>
            <Col sm='4'>
              <label className='form-label'>Location :</label>
            </Col>
            <Col sm='2'>
              <label className='form-label'>
                <input
                  type='radio'
                  name='location'
                  className='form-check-input'
                  disabled={formik.values.status == false}
                  value={BackupLocationType.LOCAL}
                  checked={formik.values.location === BackupLocationType.LOCAL}
                  onChange={formik.handleChange}
                />
                {'  '} Local
              </label>
            </Col>
            <Col sm='2'>
              <label className='form-label'>
                <input
                  type='radio'
                  name='location'
                  className='form-check-input'
                  disabled={formik.values.status == false}
                  value={BackupLocationType.REMOTE}
                  checked={formik.values.location === BackupLocationType.REMOTE}
                  onChange={formik.handleChange}
                />
                {'  '} Remote
              </label>
            </Col>
          </Row>

          {formik.values.location === BackupLocationType.REMOTE && (
            <Row className='mb-5 align-items-center'>
              <Col sm='4'>
                <label className='form-label'>Storage Provider :</label>
              </Col>
              <Col sm='8'>
                {remoteStorages.length > 0 ? (
                  <select
                    name='storage_provider'
                    disabled={formik.values.status == false}
                    value={formik.values.storage_provider?.platform}
                    onChange={(event) => {
                      const selectedProvider = remoteStorages.find(
                        (provider) => provider.platform === event.target.value
                      )
                      formik.setFieldValue('storage_provider', selectedProvider || null)
                    }}
                    className='form-control'
                  >
                    {remoteStorages.map((storageProvider) => (
                      <option key={storageProvider.platform} value={storageProvider.platform}>
                        {storageProvider.platform}
                      </option>
                    ))}
                  </select>
                ) : (
                  <label className='form-label'>No Storage Added</label>
                )}
              </Col>
            </Row>
          )}

          <Row className='mb-5 align-items-center'>
            <Col sm={{span: 10}}>
              <Button
                variant='primary'
                className='submit'
                type='submit'
                disabled={
                  !formik.values.status ||
                  (formik.values.location == BackupLocationType.REMOTE &&
                    remoteStorages.length == 0)
                }
              >
                Save
              </Button>
            </Col>
          </Row>
        </form>
      </TabBody>
    </div>
  )
}

export default BackupSettingsPage
