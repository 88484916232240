import {useEffect} from 'react'
import {ServerEditModalFormWrapper} from './ServerEditModalFormWrapper'
import {ServerEditModalHeader} from './ServerEditModalHeader'

const ServerEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_server'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-950px'>
          <div className='modal-content'>
            <ServerEditModalHeader />
            <div className='modal-body scroll-y mx-15 my-4'>
              <ServerEditModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {ServerEditModal}
