import {useEffect, useState} from 'react'
import TabBody from '../../../../../../utils/TabBody'
import TabHeader from '../../../../../../utils/TabHeader'
import {NewrelicService, ServerServiceStatus} from '../../../../servers-list/core/_models'
import {Link, useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {getNewrelicService, updateNewrelicService} from '../../../../servers-list/core/_requests'
import {ErrorResponse} from '../../../../../common/models/ErrorResponse'

const Newrelic = () => {
  const [newrelicService, setNewrelicService] = useState<NewrelicService>({
    state: false,
    status: ServerServiceStatus.STOPPED,
  })

  const {serverId} = useParams()
  const [error, setError] = useState<ErrorResponse>({code: 0, message: ''})

  const [isUpdating, setIsUpdating] = useState(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  const getNewrelic = () => {
    getNewrelicService(serverId)
      .then((data) => {
        if (data.code) {
          setError({code: data.code, message: data.message})
        } else {
          setNewrelicService(data.data)
          setError({code: 0, message: ''})
        }

        setIsUpdating(false)
      })
      .catch((e) => {
        setError({code: 0, message: 'Failed to retrieve newrelic state: ' + e.message})
      })
  }

  useEffect(() => {
    getNewrelic()
  }, [])

  const handleSaveButtonClick = () => {
    setIsUpdating(true)
    updateNewrelicService(serverId ?? '', newrelicService.state)
      .then((response) => {
        if (response) {
          notifySuccess('Newrelic state changed successfully...')
        } else {
          notifyError('Newrelic state change failed...')
          setNewrelicService((x) => {
            x.state = !newrelicService.state
            return x
          })
        }
        getNewrelic()
      })
      .catch((error) => {
        notifyError(error.message)
        setNewrelicService((x) => {
          x.state = !newrelicService.state
          return x
        })
      })
      .finally(() => {
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      })
  }

  const handleToggleChange = () => {
    setNewrelicService((x) => {
      x.state = !x.state
      return x
    })

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='newrelic_tab'>
      <TabHeader heading='Newrelic' />
      <TabBody>
        {error.code !== 0 && error.message !== '' ? (
          <div className='alert alert-warning d-flex align-items-center'>
            {error.code === 71504 ? (
              <span className='fs-6'>
                Please integrate newrelic with cloudphant by adding API Key.{' '}
                <Link to='/integrations/newrelic/key/'>Integrate Newrelic.</Link>
              </span>
            ) : (
              <span className='fs-6'>Newrelic Error: {error.message}</span>
            )}
          </div>
        ) : (
          <>
            {newrelicService?.status === ServerServiceStatus.PENDING ? (
              <div className='alert alert-warning d-flex align-items-center p-4'>
                <div className='d-flex flex-column'>
                  <h5 className='mb-1'>Newrelic operation is in progress!</h5>
                  <span>Please wait for some time and try again.</span>
                </div>
              </div>
            ) : (
              <>
                {newrelicService?.status === ServerServiceStatus.UNKNOWN && (
                  <div className='alert alert-danger d-flex align-items-center p-4'>
                    <div className='d-flex flex-column'>
                      <p className='mb-1'>
                        Seems like your newrelic installation had FAILED! Try enabling newrelic
                        again.
                      </p>
                    </div>
                  </div>
                )}
                <div className='row mb-10'>
                  <div className='col-lg-12 mt-3'>
                    <div className='row align-items-center'>
                      <div className='col-lg-2'>Newrelic Status:</div>
                      <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                        <input
                          className='form-check-input w-45px h-30px'
                          type='checkbox'
                          checked={newrelicService.state}
                          onChange={handleToggleChange}
                          style={{
                            backgroundColor: newrelicService.state ? 'green' : 'grey',
                            borderColor: newrelicService.state ? 'green' : 'grey',
                          }}
                        />
                      </div>
                      <div className='col-lg-6'>
                        <button
                          type='button'
                          className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                          onClick={handleSaveButtonClick}
                          disabled={!isSaveButtonActive || isUpdating}
                        >
                          <span className='indicator-label'>
                            {isUpdating ? 'Please wait . . .' : 'Save'}
                          </span>
                          {isUpdating && (
                            <span className='indicator-progress'>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {newrelicService?.status === ServerServiceStatus.RUNNING && (
                  <div className='alert alert-primary d-flex align-items-center p-4'>
                    <div className='d-flex flex-column'>
                      <p className='mb-1'>
                        To enable <b>Website Monitoring</b>, please go to Website &rarr; Monitoring
                        &rarr; Newrelic.
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </TabBody>
    </div>
  )
}

export default Newrelic
