export interface INewrelicAPIKeys {
  region: NewrelicRegion
  account_id: string
  user_api_key: string
  account_api_key?: string
}

export enum NewrelicRegion {
  EU = 'EU',
  US = 'US',
}
