/* eslint-disable */
import React from 'react'
import {Link, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Scale from './components/Scale'
import Transfer from './components/Transfer'
import Delete from './components/Delete'
import Renew from './components/Renew'
import Tabs from '../../../../../utils/Tabs'

const Manage = () => {
  const {serverId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
            <div className='d-flex overflow-auto'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Manage</h3>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='py-5 px-9'>
                <div className='card-title m-0'>
                  <h5 className='fw-bolder mb-3'>Scaling</h5>
                </div>
                <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6'>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/server/${serverId}/manage/scale`}
                      className={
                        `nav-link ` +
                        (location.pathname === `/server/${serverId}/manage/scale` && 'active')
                      }
                    >
                      Scale Server
                    </Link>
                  </li>
                </ul>
                <div className='separator border-secondary my-4'></div>
                <div className='card-title m-0'>
                  <h5 className='fw-bolder mb-3'>Subscription</h5>
                </div>
                <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6'>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/server/${serverId}/manage/renew`}
                      className={
                        `nav-link ` +
                        (location.pathname === `/server/${serverId}/manage/renew` && 'active')
                      }
                    >
                      Renew
                    </Link>
                  </li>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/server/${serverId}/manage/delete`}
                      className={
                        `nav-link ` +
                        (location.pathname === `/server/${serverId}/manage/delete` && 'active')
                      }
                    >
                      Delete
                    </Link>
                  </li>
                </ul>
              </div>
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path='scale' element={<Scale />} />
        <Route path='transfer' element={<Transfer />} />
        <Route path='renew' element={<Renew />} />
        <Route path='delete' element={<Delete />} />
      </Route>

      <Route index element={<Navigate to='scale' />} />
    </Routes>
  )
}

export default Manage
