import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  Optimization,
  OptimizationStatus,
  ServerOptimization,
} from '../../../../servers-list/core/_models'
import {
  getServerOptimization,
  updateServerOptimizationState,
} from '../../../../servers-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const Networking = () => {
  const {serverId} = useParams()

  const [optimization, setOptimization] = useState<Optimization | undefined>({
    state: false,
    status: OptimizationStatus.SUCCESS,
  })

  const [isUpdating, setIsUpdating] = useState(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  useEffect(() => {
    getServerOptimization(serverId ?? '', ServerOptimization.NETWORKING).then((resp) => {
      if (resp) {
        setOptimization(resp)
      }
    })
  }, [])

  const handleToggleChange = () => {
    setOptimization({
      ...optimization!,
      state: !optimization!.state,
    })

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  const handleSaveButtonClick = () => {
    setIsUpdating(true)

    updateServerOptimizationState(
      serverId ?? '',
      ServerOptimization.NETWORKING,
      optimization!.state
    ).then((response) => {
      if (response) {
        notifySuccess('Networking is updated...')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Networking updation failed...')
      }
      setIsUpdating(false)
      setIsSaveButtonActive(false)
    })
  }

  return (
    <div className='card mb-3 mb-xl-10' id='swap_space_tab' style={{minWidth: '1000px'}}>
      <TabHeader heading='Networking' />
      <TabBody>
        {optimization?.status === OptimizationStatus.FAILED && (
          <div className='alert alert-danger d-flex align-items-center p-4'>
            <div className='d-flex flex-column'>
              <p className='mb-1'>* Seems like your previous opearation had FAILED.</p>
            </div>
          </div>
        )}

        <div className='alert alert-warning d-flex align-items-center p-4'>
          <div className='d-flex flex-column'>
            <p className='mb-1'>* Server reboot is required when you turn off the feature.</p>
          </div>
        </div>

        {optimization?.status === OptimizationStatus.IN_PROGRESS ? (
          <div className='alert alert-warning d-flex align-items-center '>
            <div className='d-flex flex-column'>
              <h5 className='mb-1'>* Previous operation is in progress!</h5>
              <span>Please wait for some time and try again.</span>
            </div>
          </div>
        ) : (
          <div className='row mb-10'>
            <div className='col-lg-12 mt-3'>
              <div className='row align-items-center'>
                <div className='col-lg-2'>Accelerate Networking:</div>
                <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={optimization!.state}
                    onChange={handleToggleChange}
                    style={{
                      backgroundColor: optimization!.state ? 'green' : 'grey',
                      borderColor: optimization!.state ? 'green' : 'grey',
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <button
                    type='button'
                    className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                    onClick={handleSaveButtonClick}
                    disabled={!isSaveButtonActive || isUpdating}
                  >
                    <span className='indicator-label'>
                      {isUpdating ? 'Please wait . . .' : 'Save'}
                    </span>
                    {isUpdating && (
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </TabBody>
    </div>
  )
}

export default Networking
