import React, {useState} from 'react'
import {transferServer} from '../../../../servers-list/core/_requests'
import {useParams} from 'react-router-dom'

const Transfer = () => {
  const [email, setEmail] = useState<string>('')
  const {serverId} = useParams()
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
  }
  return (
    <div className='px-4 pb-4'>
      <h4>Transfer Server</h4>
      <p>
        Enter email of an existing user below and transfer the server to them easily. Note: The
        other user has to accept the transfer before it can be transferred
      </p>
      <form onSubmit={handleSubmit}>
        <div className='input-group mb-5'>
          <input
            type='text'
            className='form-control'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type='submit' className='btn btn-primary'>
          Transfer
        </button>
      </form>
    </div>
  )
}

export default Transfer
