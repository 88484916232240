import {FC} from 'react'
import {Server, ServerIntermediateState} from '../../../core/_models'

type Props = {
  server: Server
}

const ServerStatusCell: FC<Props> = ({server}) => (
  <div className='d-flex align-items-center'>
    <div>
      {server.server_state}{' '}
      {Object.values(ServerIntermediateState).includes(server.server_state as any) && (
        <span className='spinner-border spinner-border-sm ms-1'></span>
      )}
    </div>
  </div>
)

export {ServerStatusCell}
