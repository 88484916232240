import {Column} from 'react-table'
import {GitActionsCell} from './GitActionsCell'
import {GitCustomHeader} from './GitCustomHeader'
import {GitDescriptionCell} from './GitDescriptionCell'
import {GitNameCell} from './GitNameCell'
import {Git} from '../../../core/_models'
import {GitPlatformCell} from './GitPlatformCell'
import {GitVersionCell} from './GitVersionCell'

const gitsColumns: ReadonlyArray<Column<Git>> = [
  {
    Header: (props) => <GitCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <GitNameCell git={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GitCustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    id: 'description',
    Cell: ({...props}) => <GitDescriptionCell git={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GitCustomHeader tableProps={props} title='Platform' className='min-w-125px' />
    ),
    id: 'platform',
    Cell: ({...props}) => <GitPlatformCell git={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GitCustomHeader tableProps={props} title='Version' className='min-w-125px' />
    ),
    id: 'version',
    Cell: ({...props}) => <GitVersionCell git={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GitCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <GitActionsCell id={props.data[props.row.index].id} />,
  },
]

export {gitsColumns}
