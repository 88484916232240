import {FC} from 'react'
import {Application, ApplicationIntermediateDeploymentStatus} from '../../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  application: Application
}

const ApplicationNameCell: FC<Props> = ({application}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      {Object.values(ApplicationIntermediateDeploymentStatus).includes(
        application.deployment_status as any
      ) ? (
        <>
          <span title='5-8 mins estimated' className='text-gray-600 mb-1 fw-bold'>
            {application.name} <span className='spinner-border spinner-border-sm ms-1'></span>
          </span>
        </>
      ) : (
        <Link
          to={`/application/${application.application_id}/access-details`}
          className='text-blue-500 hover:text-blue-700 hover:bg-blue-100 mb-1'
          style={{textDecoration: 'none'}}
        >
          {application.name}{' '}
        </Link>
      )}
    </div>
  </div>
)

export {ApplicationNameCell}
