import {FC} from 'react'
import {Server} from '../../../core/_models'

type Props = {
  server: Server
}

const ServerIPCell: FC<Props> = ({server}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>{server.ip}</div>
  </div>
)

export {ServerIPCell}
