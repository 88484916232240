import {ID, Response} from '../../../../../_metronic/helpers'

export type Domain = {
  id: ID
  domain_name: string
  domain_status: string
}

export type DomainsQueryResponse = Response<Array<Domain>>

export const initialDomain: Domain = {
  id: '',
  domain_name: '',
  domain_status: '',
}
