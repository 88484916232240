import {Link, Navigate, Outlet, Route, Routes, useLocation, useParams} from 'react-router-dom'
import {ContentOptimization} from './components/contentOptimization'
import {ImageOptimization} from './components/imageOptimization'
import {ProtocolOptimization} from './components/protocolOptimization'
import Tabs from '../../../../../../utils/Tabs'

const Speed = () => {
  const location = useLocation()
  const {cloudflareDomainId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='speed_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Speed</h3>
                  </div>
                </div>
              </div>

              <div className='d-flex pt-3'>
                <Tabs
                  tabs={[
                    {
                      name: 'Content Optimization',
                      path: `/integrations/cloudflare/domain/${cloudflareDomainId}/speed/content-optimization`,
                    },
                    {
                      name: 'Image Optimization',
                      path: `/integrations/cloudflare/domain/${cloudflareDomainId}/speed/image-optimization`,
                    },
                    {
                      name: 'Protocol Optimization',
                      path: `/integrations/cloudflare/domain/${cloudflareDomainId}/speed/protocol-optimization`,
                    },
                  ]}
                />
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route
          path='content-optimization'
          element={
            <>
              <ContentOptimization />
            </>
          }
        />
        <Route
          path='image-optimization'
          element={
            <>
              <ImageOptimization />
            </>
          }
        />
        <Route
          path='protocol-optimization'
          element={
            <>
              <ProtocolOptimization />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='content-optimization' />} />
    </Routes>
  )
}

export default Speed
