import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'

const CloudsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCloudModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-cloud-table-toolbar='base'>
      {/* begin::Add cloud */}
      <button type='button' className='btn btn-primary' onClick={openAddCloudModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Cloud
      </button>
      {/* end::Add cloud */}
    </div>
  )
}

export {CloudsListToolbar}
