import React, {useEffect} from 'react'

const Recaptcha = () => {
  useEffect(() => {
    const loadScriptByURL = (id: string, url: string, callback?: () => void) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env['REACT_APP_RECAPTCHA_KEY']}`
    )
  }, [])

  return <div></div>
}

export default Recaptcha
