import {ID} from '../../../../../../../_metronic/helpers'
import clsx from 'clsx'
import {CloudProvider, PlanType} from '../../../core/_models'

const monthlyPricing = 10
const yearlyPricing = 12 * monthlyPricing

type Step1Props = {
  formik: any
  itemIdForUpdate: ID
  isServerLoading: boolean
  cloudProviders: any
  selectedCloudProvider: string | undefined
  handleCloudProviderChange: any
  instanceOptions: any
  selectedInstanceOption: any
  handleInstanceOptionChange: any
  selectedRegion: any
  handleRegionChange: any
  regions: any
  setStep: any
  handlePlanChange: any
  selectedPlan: PlanType
  hasExternalCloud: boolean
  isExternalCloud: boolean
  toggleIsExternalCloud: () => void
}

const Step1 = (props: Step1Props) => {
  const {
    setStep,
    regions,
    handleRegionChange,
    selectedRegion,
    instanceOptions,
    selectedInstanceOption,
    handleInstanceOptionChange,
    formik,
    itemIdForUpdate,
    selectedCloudProvider,
    cloudProviders,
    isServerLoading,
    handleCloudProviderChange,
    selectedPlan,
    handlePlanChange,
    hasExternalCloud,
    isExternalCloud,
    toggleIsExternalCloud,
  } = props
  return (
    <div>
      {' '}
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7'
        id='kt_modal_add_server_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_server_header'
        data-kt-scroll-wrappers='#kt_modal_add_server_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='row w-100'>
          <label className='required fw-bold fs-6 mb-2'>Server Name</label>
          <input
            placeholder='Server Name'
            {...formik.getFieldProps('name')}
            type='text'
            name='name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.name && formik.errors.name},
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isServerLoading}
            style={{marginLeft: 14 + 'px'}}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {!itemIdForUpdate && <div className='separator border-5 mb-7'></div>}
        {!itemIdForUpdate && (
          <div
            className='row w-100 mb-5'
            data-kt-buttons='true'
            data-kt-buttons-target='.form-check-image, .form-check-input'
          >
            <label className='required fw-bold fs-6 mb-2'>Cloud Provider</label>
            <div className='col'>
              <label
                className={`form-check-image ${
                  selectedCloudProvider === CloudProvider.CUSTOM && 'active'
                }`}
              >
                <div className='form-check-wrapper'>
                  <img
                    src={`/media/cloudproviders/${CloudProvider.CUSTOM}.jpg`}
                    onClick={(e) => handleCloudProviderChange(e, CloudProvider.CUSTOM)}
                  />
                </div>
                {selectedCloudProvider === CloudProvider.CUSTOM && (
                  <div style={{color: 'red', marginTop: '8px', fontSize: '10px'}}>
                    Supports Ubuntu 22.04 LTS
                  </div>
                )}
              </label>
            </div>

            {cloudProviders?.map((option: any) => (
              <div className='col' key={option}>
                <label
                  className={`form-check-image ${selectedCloudProvider === option && 'active'}`}
                >
                  <div className='form-check-wrapper'>
                    <img
                      src={`/media/cloudproviders/${option}.jpg`}
                      onClick={(e) => handleCloudProviderChange(e, option)}
                    />
                  </div>
                </label>
              </div>
            ))}
          </div>
        )}
        {!itemIdForUpdate && hasExternalCloud && (
          <>
            <hr className='m-7' />
            <div className='form-check mx-5 justify-content-center d-flex'>
              <input
                className='form-check-input me-2'
                type='checkbox'
                id='flexCheckChecked'
                checked={isExternalCloud}
                onChange={toggleIsExternalCloud}
              />
              <label className='form-check-label' htmlFor='flexCheckChecked'>
                Launch on your own server?
              </label>
            </div>
            <hr className='m-7' />
          </>
        )}
        {selectedCloudProvider === CloudProvider.CUSTOM ? (
          <>
            <div className='row w-100'>
              <label className='required fw-bold fs-6 mb-2'>IP Address (IPv4)</label>
              <input
                placeholder='IP Address (IPv4)'
                {...formik.getFieldProps('ip')}
                type='text'
                name='ip'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.ip && formik.errors.ip},
                  {
                    'is-valid': formik.touched.ip && !formik.errors.ip,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isServerLoading}
                style={{marginLeft: 14 + 'px'}}
              />
              {formik.touched.ip && formik.errors.ip && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.ip}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='row w-100 mt-5'>
              <div className='col-6'>
                <label className='required fw-bold fs-6 mb-2'>Root Password</label>
                <input
                  placeholder='Root Password'
                  {...formik.getFieldProps('root_password')}
                  type='password'
                  name='root_password'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.root_password && formik.errors.root_password},
                    {
                      'is-valid': formik.touched.root_password && !formik.errors.root_password,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isServerLoading}
                  style={{marginLeft: 14 + 'px'}}
                />
                {formik.touched.root_password && formik.errors.root_password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.root_password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6'>
                <label className='required fw-bold fs-6 mb-2'>SSH Port</label>
                <input
                  placeholder='SSH Port'
                  {...formik.getFieldProps('ssh_port')}
                  type='number'
                  name='ssh_port'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.ssh_port && formik.errors.ssh_port},
                    {
                      'is-valid': formik.touched.ssh_port && !formik.errors.ssh_port,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isServerLoading}
                  style={{marginLeft: 14 + 'px'}}
                />
                {formik.touched.ssh_port && formik.errors.ssh_port && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ssh_port}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className='row w-100 mb-7'>
            {!itemIdForUpdate && (
              <div className='col-6'>
                <label className='required fw-bold fs-6 mb-2'>Instance Option</label>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('instance_option')}
                  name='instance_option_id'
                  value={selectedInstanceOption ?? ''}
                  onChange={handleInstanceOptionChange}
                >
                  <option value=''>Select an option</option>
                  {instanceOptions?.instances?.map((option: any) => (
                    <option key={option.id} value={option.id.toString()}>
                      {option.cpu} CPU, {option.ram} MB RAM
                      {option.cloud_provider === CloudProvider.DIGITALOCEAN
                        ? `, ${option.storage_type}`
                        : option.cloud_provider === CloudProvider.VULTR
                        ? `, ${option.instance_category}`
                        : null}
                    </option>
                  ))}
                </select>
                {formik.touched.instance_option_id && formik.errors.instance_option_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.instance_option_id}</div>
                  </div>
                )}
              </div>
            )}
            {!itemIdForUpdate && (
              <div className='col-6'>
                <label className='required fw-bold fs-6 mb-2'>Region</label>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('region')}
                  name='region'
                  value={selectedRegion ?? ''}
                  onChange={handleRegionChange}
                >
                  <option value=''>Select an option</option>
                  {regions &&
                    regions?.regions.map((option: any) => (
                      <option
                        key={option.region + (Math.random() * 1000).toString()}
                        value={option.region}
                      >
                        {option.name}
                      </option>
                    ))}
                </select>
                {formik.touched.region && formik.errors.region && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.region}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {(selectedInstanceOption || selectedCloudProvider === CloudProvider.CUSTOM) && (
          <div className='mt-4'>
            <label className='required fw-bold fs-6 mb-2'>Estimated Cost:</label>
            <div className='d-flex'>
              {[PlanType.MONTHLY, PlanType.YEARLY].map((plan) => {
                return (
                  <label
                    key={plan}
                    className={`me-2 w-50 btn btn-outline btn-outline-dashed ${
                      selectedPlan === plan && 'btn-active-light-primary'
                    } d-flex flex-stack text-start p-2 px-6 ${selectedPlan === plan && ' active'}`}
                  >
                    <div className='d-flex align-items-center me-2'>
                      <div className='form-check form-check-custom form-check-solid form-check-primary me-6'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='plan_type'
                          checked={selectedPlan == plan}
                          value={plan}
                          onClick={handlePlanChange}
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h2 className='d-flex align-items-center fs-3 fw-bold flex-wrap'>
                          {plan == 'MONTHLY' ? 'Monthly' : 'Yearly'}
                        </h2>
                      </div>
                    </div>
                    <div className='ms-5'>
                      <span className='mb-2'>$</span>
                      <span className='fs-2x fw-bold'>
                        {selectedCloudProvider === CloudProvider.CUSTOM
                          ? plan === PlanType.MONTHLY
                            ? monthlyPricing
                            : yearlyPricing
                          : isExternalCloud
                          ? plan == 'MONTHLY'
                            ? instanceOptions &&
                              instanceOptions.instances.filter(
                                (i: any) => i.id == selectedInstanceOption
                              ).length > 0 &&
                              monthlyPricing
                            : instanceOptions &&
                              instanceOptions.instances.filter(
                                (i: any) => i.id == selectedInstanceOption
                              ).length > 0 &&
                              yearlyPricing
                          : plan == 'MONTHLY'
                          ? instanceOptions &&
                            instanceOptions.instances.filter(
                              (i: any) => i.id == selectedInstanceOption
                            ).length > 0 &&
                            instanceOptions.instances.filter(
                              (i: any) => i.id == selectedInstanceOption
                            )[0].monthly_pricing + monthlyPricing
                          : instanceOptions &&
                            instanceOptions.instances.filter(
                              (i: any) => i.id == selectedInstanceOption
                            ).length > 0 &&
                            instanceOptions.instances.filter(
                              (i: any) => i.id == selectedInstanceOption
                            )[0].yearly_pricing + yearlyPricing}
                      </span>
                      <span className='fs-7 opacity-50'>
                        /
                        <span data-kt-element='period'>
                          {plan == 'MONTHLY' ? 'Month' : 'Year'} + 18% GST
                        </span>
                      </span>
                    </div>
                  </label>
                )
              })}
            </div>
          </div>
        )}

        {isExternalCloud &&
          selectedInstanceOption &&
          selectedCloudProvider !== CloudProvider.CUSTOM && (
            <div className='notice d-flex bg-light-primary rounded  border mt-2 p-2'>
              <div className='fw-bold'>
                <div className='fs-8 text-primary'>
                  +{' approx $'}
                  {instanceOptions.instances.filter((i: any) => i.id == selectedInstanceOption)
                    .length > 0 &&
                    instanceOptions.instances.filter((i: any) => i.id == selectedInstanceOption)[0]
                      .monthly_pricing}
                  {'/'}
                  month to your Cloud Provider
                </div>
              </div>
            </div>
          )}

        <div className='text-center pt-8'>
          {!itemIdForUpdate && (
            <button
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setStep(2)
              }}
            >
              <span className='indicator-label'>Continue</span>
            </button>
          )}
          {itemIdForUpdate && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-servers-modal-action='submit'
              disabled={
                isServerLoading ||
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.touched ||
                !formik.dirty
              }
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isServerLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Step1
