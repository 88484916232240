import React from 'react'

const SMTP = () => {
  return (
    <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
      <div className='d-flex overflow-auto'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Services</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SMTP
