import {Response} from '../../../../../../_metronic/helpers'

interface keyval {
  [key: string]: any
}

export type Git = {
  id: string
  name: string
  description: string
  platform: string // TODO: add enum
  api_key?: keyval
  username?: string
  api_key_val?: string
  version: number
}

export type GitsQueryResponse = Response<Array<Git>>

export const initialGit: Git = {
  id: '',
  name: '',
  description: '',
  platform: '',
  version: 1,
  api_key_val: '',
  username: '',
}

export enum GitProvider {
  CUSTOM = 'CUSTOM',
  GITHUB = 'GITHUB',
  GITLAB = 'GITLAB',
  BITBUCKET = 'BITBUCKET',
}

export const availablePlatforms = [GitProvider.GITHUB, GitProvider.GITLAB, GitProvider.BITBUCKET]
