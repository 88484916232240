import {Column} from 'react-table'
import {ProjectActionsCell} from './ProjectActionsCell'
import {ProjectCustomHeader} from './ProjectCustomHeader'
import {ProjectDescriptionCell} from './ProjectDescriptionCell'
import {ProjectNameCell} from './ProjectNameCell'
import {Project} from '../../../core/_models'

const projectsColumns: ReadonlyArray<Column<Project>> = [
  /*
  {
    Header: (props) => <ProjectSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ProjectSelectionCell id={props.data[props.row.index].project_id} />,
  }, */
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <ProjectNameCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    id: 'description',
    Cell: ({...props}) => <ProjectDescriptionCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProjectActionsCell id={props.data[props.row.index].project_id} />,
  },
]

export {projectsColumns}
