import {useParams} from 'react-router-dom'
import {CloudflareSettings} from '../../../../domains-list/core/_models'
import CloudflareBinarySetting from '../../common/CloudflareBinarySetting'
import CloudflareDropdownSetting from '../../common/CloudflareDropDownSetting'
import TabHeader from '../../../../../../../utils/TabHeader'
import TabBody from '../../../../../../../utils/TabBody'

export function ProtocolOptimization() {
  const {cloudflareDomainId} = useParams()

  return (
    <>
      <div className='card mb-5 mb-xl-10 w-100' id='speed_protocol_view'>
        <TabHeader heading='Protocol Optimization' />
        <TabBody>
          <div className='d-flex flex-column'>
            {/* HTTP/2 */}
            <CloudflareBinarySetting
              title='HTTP/2'
              description='Accelerates your website with HTTP/2.'
              setting={CloudflareSettings.HTTP2}
              cloudflareDomainId={cloudflareDomainId}
            />

            {/* HTTP/2 to Origin */}
            {/*TODO: This return 1/2 instead of true/false, this is a problem */}
            <CloudflareDropdownSetting
              title='HTTP/2 to Origin'
              description="Allow HTTP/2 requests between Cloudflare's edge and your origin."
              setting={CloudflareSettings.HTTP2_TO_ORIGIN}
              cloudflareDomainId={cloudflareDomainId}
              options={[
                ['1', '1'],
                ['2', '2'],
              ]}
            />

            {/* HTTP/3 (with QUIC) */}
            <CloudflareBinarySetting
              title='HTTP/3 (with QUIC)'
              description='Accelerates HTTP requests by using QUIC, which provides encryption and performance
            improvements compared to TCP and TLS.'
              setting={CloudflareSettings.HTTP3}
              cloudflareDomainId={cloudflareDomainId}
            />

            {/* Enhanced HTTP/2 Prioritization */}
            <CloudflareBinarySetting
              title='Enhanced HTTP/2 Prioritization'
              description='Optimizes the order of resource delivery, independent of the browser. Greatest
            improvements will be experienced by visitors using Safari and Edge browsers.'
              setting={CloudflareSettings.HTTP2_PRIORITIZATION}
              cloudflareDomainId={cloudflareDomainId}
            />

            {/* 0-RTT Connection Resumption */}
            <CloudflareBinarySetting
              title='0-RTT Connection Resumption'
              description='Improves performance for clients who have previously connected to your website.'
              setting={CloudflareSettings.ORTT}
              cloudflareDomainId={cloudflareDomainId}
            />
          </div>
        </TabBody>
      </div>
    </>
  )
}
