import clsx from 'clsx'
import React from 'react'
import {ID} from '../../../../../../../_metronic/helpers'
import {ServerLaunchButton} from '../ServerLaunchButton'

type Step2Props = {
  itemIdForUpdate: ID
  formik: any
  isServerLoading: boolean
  selectedProject: string | undefined
  handleProjectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  projects: any
  selectedLaunchOption: number | undefined
  launchOptions: any
  handleLaunchOptionChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  setStep: (x: number) => void
  isBareMetal: boolean
  toggleIsBareMetal: () => void
  isExternalCloud: boolean
  isFormValid: boolean
}

const Step2 = (props: Step2Props) => {
  const {
    itemIdForUpdate,
    handleLaunchOptionChange,
    formik,
    isServerLoading,
    projects,
    handleProjectChange,
    selectedProject,
    selectedLaunchOption,
    launchOptions,
    setStep,
    isBareMetal,
    toggleIsBareMetal,
    isExternalCloud,
    isFormValid,
  } = props
  return (
    <>
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7'
        id='kt_modal_add_server_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_server_header'
        data-kt-scroll-wrappers='#kt_modal_add_server_scroll'
        data-kt-scroll-offset='300px'
      >
        {!itemIdForUpdate && (
          <div className='form-check mx-5 mt-5 justify-content-center d-flex'>
            <input
              className='form-check-input me-2'
              type='checkbox'
              id='flexCheckChecked'
              checked={isBareMetal}
              onChange={toggleIsBareMetal}
            />
            <label className='form-check-label' htmlFor='flexCheckChecked'>
              Launch server without any website?
            </label>
          </div>
        )}
        {!isBareMetal && (
          <>
            <hr className='my-10'></hr>
            <div className='row w-100 mb-7'>
              {!itemIdForUpdate && (
                <div className='col-6'>
                  <label className='required fw-bold fs-6 mb-2'>Website Name</label>
                  <input
                    placeholder='Website Name'
                    {...formik.getFieldProps('application_name')}
                    type='text'
                    name='application_name'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.application_name && formik.errors.application_name,
                      },
                      {
                        'is-valid':
                          formik.touched.application_name && !formik.errors.application_name,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isServerLoading}
                    style={{marginLeft: 14 + 'px'}}
                  />
                  {formik.touched.application_name && formik.errors.application_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.application_name}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!itemIdForUpdate && (
                <div className='col-6'>
                  <label className='fw-bold fs-6 mb-2'>Project</label>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('project')}
                    name='project_id'
                    value={selectedProject ?? ''}
                    onChange={handleProjectChange}
                  >
                    <option value=''>Select an option</option>
                    {projects?.map((option: any) => (
                      <option key={option.project_id} value={option.project_id?.toString()}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.project_id && formik.errors.project_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.project_id}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {!itemIdForUpdate && (
              <div className='row w-100 mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Launch Options</label>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('launch_option_id')}
                  name='launch_option_id'
                  value={selectedLaunchOption ?? 0}
                  onChange={handleLaunchOptionChange}
                  style={{marginLeft: 14 + 'px'}}
                >
                  <option value='0'>Select an option</option>
                  {launchOptions?.map((option: any) => (
                    <option key={option.id} value={option.id}>
                      {option.launch_option_platform} - {option.launch_option_type}
                    </option>
                  ))}
                </select>
                {formik.touched.launch_option_id && formik.errors.launch_option_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.launch_option_id}</div>
                  </div>
                )}
              </div>
            )}
            <hr className='my-5'></hr>
            {!itemIdForUpdate && (
              <div className='row w-100 mb-7'>
                <div className='col-6'>
                  <label className='required fw-bold fs-6 mb-2'>WordPress Title</label>
                  <input
                    placeholder='WordPress Title'
                    {...formik.getFieldProps('wordpress_title')}
                    type='text'
                    name='wordpress_title'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.wordpress_title && formik.errors.wordpress_title,
                      },
                      {
                        'is-valid':
                          formik.touched.wordpress_title && !formik.errors.wordpress_title,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isServerLoading}
                    style={{marginLeft: 14 + 'px'}}
                  />
                  {formik.touched.wordpress_title && formik.errors.wordpress_title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.wordpress_title}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-6'>
                  <label className='fw-bold fs-6 mb-2'>WordPress Email (Optional)</label>
                  <input
                    placeholder='WordPress Email'
                    {...formik.getFieldProps('wordpress_email')}
                    type='email'
                    name='wordpress_email'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.wordpress_email && formik.errors.wordpress_email,
                      },
                      {
                        'is-valid':
                          formik.touched.wordpress_email && !formik.errors.wordpress_email,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isServerLoading}
                    style={{marginLeft: 14 + 'px'}}
                  />
                  {formik.touched.wordpress_email && formik.errors.wordpress_email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.wordpress_email}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-6 mt-10'>
                  <label className='required fw-bold fs-6 mb-2'>Admin Username</label>
                  <input
                    placeholder='Admin Username'
                    {...formik.getFieldProps('admin_username')}
                    type='text'
                    name='admin_username'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid': formik.touched.admin_username && formik.errors.admin_username,
                      },
                      {
                        'is-valid': formik.touched.admin_username && !formik.errors.admin_username,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isServerLoading}
                    style={{marginLeft: 14 + 'px'}}
                  />
                  {formik.touched.admin_username && formik.errors.admin_username && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.admin_username}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-6 mt-10'>
                  <label className='required fw-bold fs-6 mb-2'>Admin Password</label>
                  <input
                    placeholder='Admin Password'
                    {...formik.getFieldProps('admin_password')}
                    type='password'
                    name='admin_password'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {
                        'is-invalid': formik.touched.admin_password && formik.errors.admin_password,
                      },
                      {
                        'is-valid': formik.touched.admin_password && !formik.errors.admin_password,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isServerLoading}
                    style={{marginLeft: 14 + 'px'}}
                  />
                  {formik.touched.admin_password && formik.errors.admin_password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.admin_password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className='text-center pt-15'>
        <button
          type='reset'
          onClick={() => setStep(1)}
          className='btn btn-light me-3'
          disabled={formik.isSubmitting || isServerLoading}
        >
          Back
        </button>

        {!itemIdForUpdate && (
          <ServerLaunchButton
            disabled={
              isFormValid ||
              isServerLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched
            }
            data={formik.values}
            isBareMetal={isBareMetal}
            isExternalCloud={isExternalCloud}
          />
        )}
      </div>
    </>
  )
}

export default Step2
