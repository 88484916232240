import {KTSVG} from '../../../../_metronic/helpers'
import {KnowledgeBaseAccordionProps} from './KnowledgeBaseAccordionProps'

function KnowledgeBaseAccordion({body}: KnowledgeBaseAccordionProps) {
  return (
    <div className='accordion mb-2 rounded-5' id='kt_accordion_1'>
      <div className='accordion-item'>
        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
          <button
            className='accordion-button collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_1_body_1'
            aria-expanded='false'
            aria-controls='kt_accordion_1_body_1'
          >
            <div className='d-flex w-100 align-items-center pe-4'>
              <i className='fa-solid fa-circle-question text-primary me-1 fs-7'> </i>
              <div className='text-primary fs-7 pe-1'>User Guide</div>
            </div>
          </button>
        </h2>
        <div
          id='kt_accordion_1_body_1'
          className='accordion-collapse collapse'
          aria-labelledby='kt_accordion_1_header_1'
          data-bs-parent='#kt_accordion_1'
        >
          {<div className='accordion-body' dangerouslySetInnerHTML={{__html: body}} />}
        </div>
      </div>
    </div>
  )
}

export default KnowledgeBaseAccordion
