import {Response} from '../../../../../../../../../_metronic/helpers'

export enum DNSRecordType {
  A = 'A',
  AAAA = 'AAAA',
  CNAME = 'CNAME',
  MX = 'MX',
  TXT = 'TXT',
  // NS = 'NS',
  // SRV = 'SRV',
}

export const initialRecord: Record = {
  id: '',
  content: '',
  priority: 0,
  weight: 0,
  port: 0,
  type: DNSRecordType.A,
  name: '',
  ttl: 600,
  proxied: false,
  proxiable: false,
}

export type RecordsQueryResponse = Response<Array<Record>>

export type Record = {
  id: string
  type: DNSRecordType
  name: string
  content?: string
  priority?: number
  weight?: number
  port?: number
  ttl: number
  proxied: boolean
  proxiable: boolean
}
