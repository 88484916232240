import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {CloudflareSettings} from '../../../../domains-list/core/_models'
import CloudflareBinarySetting from '../../common/CloudflareBinarySetting'
import CloudflareMultipleSetting from '../../common/CloudflareMultipleSetting'
import CloudflareDropdownSetting from '../../common/CloudflareDropDownSetting'
import ConfirmDeleteModal from '../../../../../../../utils/ConfirmDeleteModal'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../../utils/NotifyUtils'
import {purgeEverything} from '../../../../domains-list/core/_requests'
import CustomPurgeModal from './CustomPurgeModal'

export function Configuration() {
  const {cloudflareDomainId} = useParams()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showCustomPurgeModal, setShowCustomPurgeModal] = useState(false)

  const handleConfirmDelete = () => {
    const toastId = notifyProgress('Cache purge in progess...!')
    const success = purgeEverything(cloudflareDomainId ? cloudflareDomainId : '')
      .then((data) => {
        notifySuccess('Cache purge successful', toastId)
      })
      .catch((error) => {
        notifyError(`Cache purge failed: ${error.message}`, toastId)
      })
      .finally(() => {
        setShowDeleteConfirmation(false)
      })
  }

  const browserCacheTTLOptions: [option: string, key: string][] = [
    ['Respect Existing Headers', '0'],
    ['2 mins', '120'],
    ['5 mins', '300'],
    ['20 mins', '1200'],
    ['30 mins', '1800'],
    ['1 hr', '3600'],
    ['2 hrs', '7200'],
    ['3 hrs', '10800'],
    ['4 hrs', '14400'],
    ['5 hrs', '18000'],
    ['8 hrs', '28800'],
    ['12 hrs', '43200'],
    ['16 hrs', '57600'],
    ['20 hrs', '72000'],
    ['1 day', '86400'],
    ['2 days', '172800'],
    ['3 days', '259200'],
    ['4 days', '345600'],
    ['5 days', '432000'],
    ['8 days', '691200'],
    ['16 days', '1382400'],
    ['24 days', '2073600'],
    ['1 month', '2678400'],
    ['2 months', '5356800'],
    ['6 months', '16070400'],
    ['1 year', '31536000'],
  ]

  const handleCustomPurgeUrlClick = () => {
    setShowCustomPurgeModal(true)
  }

  const handlePurgeEverythingClick = () => {
    setShowDeleteConfirmation(true)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10 w-100' id='configuration_view'>
        {/* <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Configuration</h3>
          </div>
        </div> */}

        <div className='d-flex flex-column mx-8 my-6'>
          {/* Purge Cache */}
          <div className='rounded-3 border p-4 mb-4 d-flex'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>Purge Cache</h5>
              <p className='text-muted'>
                Manually purge cached content to ensure the latest version is served to visitors.
              </p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center'
              style={{flex: '0 0 20%'}}
            >
              <div className='d-flex flex-column'>
                <button className='btn btn-primary btn-sm mb-1' onClick={handleCustomPurgeUrlClick}>
                  Custom Purge Url
                </button>
                <button className='btn btn-primary btn-sm' onClick={handlePurgeEverythingClick}>
                  Purge Everything
                </button>
              </div>
            </div>
          </div>

          {/* Caching Level */}
          <CloudflareMultipleSetting
            title='Caching Level'
            description='Choose the caching level for your website to control how Cloudflare caches your
          content.'
            setting={CloudflareSettings.CACHE_LEVEL}
            options={[
              ['Standard', 'aggressive'],
              ['No Query String', 'basic'],
              ['Ignore Query String', 'simplified'],
            ]}
            cloudflareDomainId={cloudflareDomainId}
          />

          {/* Browser Cache TTL */}
          <CloudflareDropdownSetting
            title='Browser Cache TTL'
            description="Set the time-to-live (TTL) for cached content in visitors' browsers."
            setting={CloudflareSettings.BROWSER_CAHCE_TTL}
            cloudflareDomainId={cloudflareDomainId}
            options={browserCacheTTLOptions}
            isIntegerAPI={true}
          />

          {/* Crawler Hints */}
          {/* NOTE: API not available*/}
          {/* <div className='rounded-3 border p-4 mb-4 d-flex'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>Crawler Hints</h5>
              <p className='text-muted'>
                Improve cache efficiency by providing hints to web crawlers. <strong>(Not integrated)</strong>
              </p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center'
              style={{flex: '0 0 20%'}}
            >
              <label className='form-check form-switch mt-2'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  checked={crawlerHints}
                  onChange={handleCrawlerHintsChange}
                />
              </label>
            </div>
          </div> */}

          {/* Always Online */}
          <CloudflareBinarySetting
            title='Always Online'
            description='Keep your website online, even if your origin server is temporarily unavailable.'
            setting={CloudflareSettings.ALWAYS_ONLINE}
            cloudflareDomainId={cloudflareDomainId}
          />

          {/* Development Mode */}
          <CloudflareBinarySetting
            title='Development Mode'
            description='Temporarily bypass the cache to facilitate development and debugging.'
            setting={CloudflareSettings.DEVELOPMENT_MODE}
            cloudflareDomainId={cloudflareDomainId}
          />

          {/* //TODO: I don't think using this listview modal makes any sense here, create a small modal and use it just like the delete
          //confirmation modal */}
          {showCustomPurgeModal && (
            <CustomPurgeModal
              title='Purge URLs'
              content='Add the urls you want to purge one per line. You can purge a maximum of upto 30 urls'
              setIsOpen={setShowCustomPurgeModal}
              domainId={cloudflareDomainId ?? ''}
            />
          )}

          {showDeleteConfirmation && (
            <ConfirmDeleteModal
              setIsOpen={setShowDeleteConfirmation}
              title='Are you sure?'
              content='This will purge the complete cache and might degrade the performance for some time'
              deleteFunction={handleConfirmDelete}
            />
          )}
        </div>
      </div>
    </>
  )
}
