import {Response} from '../../../../../_metronic/helpers'

export type Server = {
  server_id?: string
  name: string
  ip?: string
  server_state?: ServerState
  deployment_job?: {
    status: ServerDeploymentJobStatus
  }
  application_name?: string
  project?: string
  launch_option?: string
  instance_option?: string
  identifier?: string
  region?: string
  storage?: string
  logo?: string
  description?: string
  cloud_account_type?: CloudAccountType
}

export type Firewall = {
  ips: string[]
  port: number
  type: FirewallType
}

export enum CloudAccountType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum FirewallType {
  WHITELIST = 'WHITELIST',
  BLACKLIST = 'BLACKLIST',
}

export enum Port {
  SSH = 22,
}

export type ServersQueryResponse = Response<Array<Server>>

export enum PlanType {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum ServerState {
  PENDING = 'PENDING',
  STARTING = 'STARTING',
  STOPPING = 'STOPPING',
  RESTARTING = 'RESTARTING',
  STOPPED = 'STOPPED',
  STARTED = 'STARTED',
  UNKNOWN = 'UNKNOWN',
}

export enum ServerIntermediateState {
  PENDING = 'PENDING',
  STARTING = 'STARTING',
  STOPPING = 'STOPPING',
  RESTARTING = 'RESTARTING',
}

export const initialServer: ServerDto = {
  server_id: '',
  launch_option_id: 0,
  application_name: '',
  name: '',
  instance_option_id: 0,
  region: '',
  plan_type: PlanType.MONTHLY,
  country: 'INDIA',
  wordpress_title: '',
  admin_username: '',
  admin_password: '',
}

export type ServerDto = {
  server_id?: string
  launch_option_id?: number
  application_name?: string
  name: string
  instance_option_id: number
  region: string
  storage?: number
  project_id?: string
  plan_type: PlanType
  country: string
  ip?: string
  root_password?: string
  ssh_port?: number
  wordpress_title: string
  wordpress_email?: string
  admin_username: string
  admin_password: string
}

export type LaunchOption = {
  id: number
  launch_option_platform: string
  launch_option_type: string
}

export type LaunchOptionsQueryResponse = Response<Array<LaunchOption>>

export type InstanceOption = any

export type InstanceOptionsQueryResponse = Response<InstanceOption>

export type InstanceOptionDetails = {
  instances: [
    {
      id: number
      cpu: number
      ram: number
      storage?: number
      storage_type?: string
      cloud_provider?: string
      instance_category?: string
      monthly_pricing: number
      yearly_pricing: number
    }
  ]
}

export type InstanceOptionRegions = {
  regions: [
    {
      name: string
      region: string
    }
  ]
}

export type SSHDetails = {
  server: Server
  username: string
  password: string
}

export type ServerServicesSetting = {
  id: string
  server: Server
  service: string
  status: string
  version: string
  settings: {[key: string]: any}
  template: string
}

export enum ServerActionType {
  SCALE = 'SCALE',
  START = 'START',
  STOP = 'STOP',
  RESTART = 'RESTART',
  CLONE = 'CLONE',
  SNAPSHOT = 'SNAPSHOT',
}

export enum Currency {
  USD = 'USD',
  INR = 'INR',
}

export type RazorpayOrder = {
  id: string
  amount: number
  currency: Currency
}

export type RazorpayPaymentVerificationDto = {
  order_creation_id: string
  razorpay_payment_id: string
  razorpay_order_id: string
  razorpay_signature: string
}

export enum PaymentProvider {
  RAZORPAY = 'RAZORPAY',
}

export type LaunchServerDto = {
  launch_option_id: number
  application_name: string
  name: string
  instance_option_id: number
  project_id?: string
  storage?: number
  region: string
  plan_type: PlanType
  country: string
  cloud_account_type?: CloudAccountType
  ip?: string
  root_password?: string
  ssh_port?: number
}

export type ScaleServerDto = {
  launch_option_id: number
}

export type ServerSwap = {
  state: boolean
}

export type Optimization = {
  state: boolean
  status: OptimizationStatus
}

export enum ServerOptimization {
  SWAP_SPACE = 'SWAP_SPACE',
  NETWORKING = 'NETWORKING',
  FAIL2BAN = 'FAIL2BAN',
}

export enum OptimizationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum CurrencySymbol {
  INR = '₹',
  USD = '$',
}

export enum CloudProvider {
  CUSTOM = 'CUSTOM',
  AWS = 'AWS',
  GCP = 'GCP',
  DIGITALOCEAN = 'DIGITALOCEAN',
  LINODE = 'LINODE',
  VULTR = 'VULTR',
}

export enum ServerUserType {
  ROOT = 'ROOT',
  FTP = 'FTP',
}

export enum ServerDeploymentJobStatus {
  INITIATED = 'INITIATED',
  SERVER_DEPLOYMENT_SUCCESSFUL = 'SERVER_DEPLOYMENT_SUCCESSFUL',
  DEPLOYMENT_SCRIPT_SUCCESSFUL = 'DEPLOYMENT_SCRIPT_SUCCESSFUL',
  INTERMEDIATE_SERVER_DEPLOYMENT_FAILED = 'INTERMEDIATE_SERVER_DEPLOYMENT_FAILED',
  SERVER_DEPLOYMENT_FAILED = 'SERVER_DEPLOYMENT_FAILED',
  DEPLOYMENT_SCRIPT_FAILED = 'DEPLOYMENT_SCRIPT_FAILED',
  POST_SERVER_DEPLOYMENT_OPERATIONS_SUCCESSFUL = 'POST_SERVER_DEPLOYMENT_OPERATIONS_SUCCESSFUL',
  SERVER_DELETED = 'SERVER_DELETED',
}

export enum ServerServiceStatus {
  PENDING = 'PENDING', // when some action is going on for that service
  UNKNOWN = 'UNKNOWN', // this is an error state, and any action would lead to a redeploy
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
}

export type MonitService = {
  url: string
  username: string
  password: string
  status: ServerServiceStatus
  state: boolean
}

export type NewrelicService = {
  status: ServerServiceStatus
  state: boolean
}

export enum AlertType {
  RESOURCE = 'RESOURCE',
  SERVICE = 'SERVICE',
  // APPLICATION = 'APPLICATION',
}

export enum AlertSystemType {
  MONIT = 'MONIT',
}

export enum AlertLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum AlertEntityType {
  SERVER = 'SERVER',
  APPLICATION = 'APPLICATION',
}

export enum AlertResource {
  CPU = 'CPU',
  MEMORY = 'MEMORY',
  SWAP = 'SWAP',
  DISK = 'DISK',
  INODE = 'INODE',
}

export const AlertPeriod = {
  '1 min': 1,
  '2 min': 2,
  '5 min': 5,
  '10 min': 10,
  '30 min': 30,
  '1 hr': 60,
  '2 hr': 120,
}

export enum AlertOperator {
  'GE' = '>',
  'GEQ' = '>=',
  'LE' = '<',
  'LEQ' = '<=',
  'EQ' = '==',
  'NEQ' = '!=',
}

export type Alert = {
  id: string
  entity: AlertEntityType
  entity_id: string
  system_type: AlertSystemType
  name: string
  description: string
  data: {
    resource?: AlertResource
    operator?: AlertOperator
    percentage?: number
  }
  type: AlertType
  level: AlertLevel
  consecutive_period: number
  period: number
  created_at: string
}

export type CreateAlert = {
  name: string
  description?: string
  type: AlertType
  consecutive_period: number
  period: number // Seconds
  resource?: string
  operator?: string
  percentage?: number
  level?: AlertLevel
}
