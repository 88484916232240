import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {purgeApplicationOPCache} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const OPCache = () => {
  const {applicationId} = useParams()

  const [isUpdating, setIsUpdating] = useState(false)
  const [isPurgeCacheConfirmationVisible, setIsPurgeCacheConfirmationVisible] = useState(false)

  const handleConfirmPurgeCache = () => {
    setIsPurgeCacheConfirmationVisible(false)
    setIsUpdating(true)

    purgeApplicationOPCache(applicationId ?? '').then((response) => {
      if (response) {
        notifySuccess('OPCache purge successful')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Something went wrong')
      }
      setIsUpdating(false)
    })
  }

  return (
    <div className='container-fluid' id='op_cache_tab'>
      <TabHeader heading='PHP OPCache' />
      <TabBody>
        <div className='row mb-10'>
          <div className='mt-2'>
            <button
              type='button'
              className={`btn ${isUpdating ? 'btn-light' : 'btn-success'} me-10`}
              onClick={() => setIsPurgeCacheConfirmationVisible(true)}
              disabled={isUpdating}
            >
              <span className='indicator-label'>
                {isUpdating ? 'Please wait . . .' : 'Purge Cache'}
              </span>
              {isUpdating && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </TabBody>

      {isPurgeCacheConfirmationVisible && (
        <ConfirmSuccessModal
          setIsOpen={setIsPurgeCacheConfirmationVisible}
          title='Are you sure?'
          btnTitle='Purge Cache'
          content='Proceed with the opcache purge?'
          successFunction={handleConfirmPurgeCache}
        />
      )}
    </div>
  )
}

export default OPCache
