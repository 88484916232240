import {ID, Response} from '../../../../../../_metronic/helpers'

export type CloudflareDomain = {
  id: ID
  domain: string
  status: string
  nameservers?: string[]
}

export type CloudflareDomainsQueryResponse = Response<Array<CloudflareDomain>>

export const initialCloudflareDomain: CloudflareDomain = {
  id: '',
  domain: '',
  status: '',
  nameservers: [],
}

export interface ICloudflareAPIKeys {
  email: string
  api_key: string
}

export enum CloudflareSettings {
  IMAGE_RESIZING = 'image_resizing',
  TLS_1_3 = 'tls_1_3',
  OPPURTUNISTIC_ENCRYPTION = 'opportunistic_encryption',
  BROTLI = 'brotli',
  EARLY_HINTS = 'early_hints',
  ROCKET_LOADER = 'rocket_loader',
  AUTO_MINIFY = 'minify',
  AUTOMATIC_PLATFORM_OPTIMIZATION = 'automatic_platform_optimization',
  HTTP2 = 'http2',
  HTTP2_TO_ORIGIN = 'origin_max_http_version',
  HTTP3 = 'http3',
  ORTT = '0rtt',
  HTTP2_PRIORITIZATION = 'h2_prioritization',
  DEVELOPMENT_MODE = 'development_mode',
  ALWAYS_ONLINE = 'always_online',
  POLISH = 'polish',
  SSL = 'ssl',
  CACHE_LEVEL = 'cache_level',
  BROWSER_CAHCE_TTL = 'browser_cache_ttl',
  EDGE_CACHE = 'edge_cache',
}
