import {Column} from 'react-table'
import {StorageActionsCell} from './StorageActionsCell'
import {StorageCustomHeader} from './StorageCustomHeader'
import {StorageDescriptionCell} from './StorageDescriptionCell'
import {StorageNameCell} from './StorageNameCell'
import {Storage} from '../../../core/_models'
import {StoragePlatformCell} from './StoragePlatformCell'
import {StorageVersionCell} from './StorageVersionCell'

const storagesColumns: ReadonlyArray<Column<Storage>> = [
  {
    Header: (props) => (
      <StorageCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <StorageNameCell storage={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StorageCustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    id: 'description',
    Cell: ({...props}) => <StorageDescriptionCell storage={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StorageCustomHeader tableProps={props} title='Platform' className='min-w-125px' />
    ),
    id: 'platform',
    Cell: ({...props}) => <StoragePlatformCell storage={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StorageCustomHeader tableProps={props} title='Version' className='min-w-125px' />
    ),
    id: 'version',
    Cell: ({...props}) => <StorageVersionCell storage={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StorageCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <StorageActionsCell id={props.data[props.row.index].id} />,
  },
]

export {storagesColumns}
