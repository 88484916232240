import {FC} from 'react'
import {Project} from '../../../core/_models'

type Props = {
  project: Project
}

const ProjectDescriptionCell: FC<Props> = ({project}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>{project.description}</p>
    </div>
  </div>
)

export {ProjectDescriptionCell}
