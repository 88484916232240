import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core/PageData'
import APIKeyPage from './APIKeyPage'
import KnowledgeBaseAccordion from '../../../common/knowledgebase/KnowledgeBaseAccordion'

const newrelicBreadcrumbs: Array<PageLink> = [
  {
    title: 'Newrelic Integration',
    path: '/integrations/newrelic/key',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const NewrelicPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='key'
          element={
            <>
              <PageTitle breadcrumbs={newrelicBreadcrumbs}>API Details</PageTitle>
              <KnowledgeBaseAccordion
                body={`This page is to integrate Newrelic account with Cloudphant.
                <br>
                <br>
                Provide with the Newrelic <b>Region (EU or US), Account ID, User API Key and optional Account API Key</b> as provided in your account. Below is detailed description of each field.
                <br>
                <br>
                <ol>
                  <li><b>Region</b>: Newrelic supports two regions, EU and US. Please make sure you select your correct region.</li>
                  <li><b>Account ID</b>: This is your account in Newrelic. When you login into Newrelic, you can see your url like: <mark>https://one.eu.newrelic.com/nr1-core?account=3672794&state=55fa4b4a-935b-b69d-3968-635d4e43d87f</mark>. Here, <b>3672794</b> will be your Account ID.</li>
                  <li><b>User API Key</b>: User key is required for infrastructure monitoing setup. You can create User Key by clicking <b>Create a key</b> button as shown in below picture. You can also see User keys in <mark>Type - USER</mark> marked with red box.</li>
                  <li><b>Account API Key</b>: Account key is required for <b>Application Monitoring</b> setup. You can use default <mark>INGEST - LICENSE</mark> key for the same as marked with blue in below image.</li>
                </ol>
                <img width="800px" src="/media/knowledgebase/newrelic-1.png">
                `}
              />
              <APIKeyPage />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/integrations/newrelic/key' />} />
    </Routes>
  )
}

export default NewrelicPage
