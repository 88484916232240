import {useParams} from 'react-router-dom'
import {CloudflareSettings} from '../../../../domains-list/core/_models'
import CloudflareBinarySetting from '../../common/CloudflareBinarySetting'
import CloudflareConfigSetting from '../../common/CloudflareConfigSetting'
import TabHeader from '../../../../../../../utils/TabHeader'
import TabBody from '../../../../../../../utils/TabBody'

export function ContentOptimization() {
  const {cloudflareDomainId} = useParams()

  return (
    <>
      <div className='card mb-5 mb-xl-10 w-100' id='speed_content_view'>
        <TabHeader heading='Content Optimization' />
        <TabBody>
          <div className='d-flex flex-column'>
            {/* Brotli */}
            <CloudflareBinarySetting
              title='Brotli'
              description="Speed up page load times for your visitor's HTTPS traffic by applying Brotli compression."
              setting={CloudflareSettings.BROTLI}
              cloudflareDomainId={cloudflareDomainId}
            />

            {/* Early Hints */}
            <CloudflareBinarySetting
              title='Early Hints'
              description="Cloudflare's edge will cache and send 103 Early Hints responses with Link headers
            from your HTML pages. Early Hints allows browsers to preload linked assets before
            they see a 200 OK or other final response from the origin."
              setting={CloudflareSettings.EARLY_HINTS}
              cloudflareDomainId={cloudflareDomainId}
            />

            {/* Rocket Loader */}
            <CloudflareBinarySetting
              title='Rocket Loader'
              description='Improve the paint time for pages which include JavaScript.'
              setting={CloudflareSettings.ROCKET_LOADER}
              cloudflareDomainId={cloudflareDomainId}
            />

            {/* Auto Minify */}
            <CloudflareConfigSetting
              title='Auto Minify'
              description='Reduce the file size of source code on your website.'
              setting={CloudflareSettings.AUTO_MINIFY}
              options={[
                ['JavaScript', 'js'],
                ['CSS', 'css'],
                ['HTML', 'html'],
              ]}
              cloudflareDomainId={cloudflareDomainId}
            />

            {/* Automatic Platform Optimization */}
            <CloudflareBinarySetting
              title='Automatic Platform Optimization for WordPress'
              description="Improve the performance of your WordPress site. Automatic Platform Optimization for
            WordPress serves your WordPress site from Cloudflare's edge network and caches third
            party fonts. Get the benefits of a static site without any changes to how you manage
            your site. This results in consistent, fast TTFB and content loading faster."
              setting={CloudflareSettings.AUTOMATIC_PLATFORM_OPTIMIZATION}
              cloudflareDomainId={cloudflareDomainId}
              isPro={true}
            />
          </div>
        </TabBody>
      </div>
    </>
  )
}
