import {FC} from 'react'
import {Cloud} from '../../../core/_models'

type Props = {
  cloud: Cloud
}

const CloudNameCell: FC<Props> = ({cloud}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>{cloud.name}</p>
    </div>
  </div>
)

export {CloudNameCell}
