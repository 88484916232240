import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'
import {GitEditModalForm} from './GitEditModalForm'
import {getGitById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {initialGit} from '../../core/_models'

const GitEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: git,
    error,
  } = useQuery(
    `${'gits-list'}-git-${itemIdForUpdate}`,
    () => {
      return getGitById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <GitEditModalForm isGitLoading={isLoading} git={initialGit} />
  }

  if (!isLoading && !error && git) {
    return <GitEditModalForm isGitLoading={isLoading} git={git} />
  }

  return null
}

export {GitEditModalFormWrapper}
