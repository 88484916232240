import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {ApplicationBrowserCache} from '../../../../applications-list/core/_models'
import {
  getBrowserCacheByApplication,
  updateApplicationBrowserCache,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import clsx from 'clsx'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const BrowserCache = () => {
  const {applicationId} = useParams()
  const [isUpdating, setIsUpdating] = useState(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)
  const [initialValues, setInitialValues] = useState<ApplicationBrowserCache | null>(null)

  const formik = useFormik<ApplicationBrowserCache>({
    initialValues: {
      state: false,
      ttl: 2592000,
    },
    onSubmit: async (values) => {
      setIsUpdating(true)
      updateApplicationBrowserCache(applicationId ?? '', values).then((response) => {
        if (response) {
          notifySuccess('Browser cache updated')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notifyError('Something went wrong')
        }
        setIsUpdating(false)
      })
    },
  })

  useEffect(() => {
    getBrowserCacheByApplication(applicationId!).then((resp) => {
      formik.setValues({
        state: resp!.state,
        ttl: resp!.ttl,
      })

      setInitialValues({
        state: resp!.state,
        ttl: resp!.ttl,
      })
    })
  }, [applicationId])

  useEffect(() => {
    if (
      initialValues &&
      (formik.values.state !== initialValues.state ||
        (formik.values.state && formik.values.ttl !== initialValues.ttl))
    ) {
      setIsSaveButtonActive(true)
    } else {
      setIsSaveButtonActive(false)
    }

    if (initialValues && !formik.values.state) {
      formik.values.ttl = initialValues.ttl
    }
  }, [formik.values, initialValues])

  return (
    <div className='container-fluid' id='browser_cache_tab'>
      <TabHeader heading='Browser Cache' />

      <TabBody>
        <form onSubmit={formik.handleSubmit}>
          <div className='row align-items-center mb-8'>
            <div className='form-label col-lg-3'>Browser Cache State:</div>
            <div className='col-lg-4 form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-25px'
                type='checkbox'
                id='state'
                checked={formik.values.state}
                onChange={() => formik.setFieldValue('state', !formik.values.state)}
                style={{
                  backgroundColor: formik.values.state ? 'green' : 'grey',
                  borderColor: formik.values.state ? 'green' : 'grey',
                }}
              />
            </div>
          </div>

          <div className='row align-items-center mb-8'>
            <div className='form-label col-lg-3'>Cache TTL (seconds):</div>
            <div className='col-lg-4' style={{paddingLeft: 0}}>
              <input
                type='number'
                name='ttl'
                min={0}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.ttl && formik.errors.ttl,
                  },
                  {
                    'is-valid': formik.touched.ttl && !formik.errors.ttl,
                  }
                )}
                style={{maxWidth: '150px'}}
                value={formik.values.ttl}
                autoComplete='off'
                onChange={(e) => formik.setFieldValue('ttl', +e.target.value)}
                disabled={formik.isSubmitting || isUpdating}
              />
            </div>
          </div>

          <div className='d-flex align-items-center my-5'>
            <button
              type='submit'
              className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10 p-3`}
              disabled={isUpdating || !isSaveButtonActive}
            >
              <span className='indicator-label'>
                {isUpdating ? 'Please wait . . .' : 'Apply Settings'}
              </span>
              {isUpdating && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </TabBody>
    </div>
  )
}

export default BrowserCache
