import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {
  getDatabaseOptimizationList,
  updateApplicationDatabaseOptimizations,
} from '../../../../applications-list/core/_requests'
import {ApplicationDatabaseOptimizations} from '../../../../applications-list/core/_models'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const Database: React.FC = () => {
  const {applicationId} = useParams<{applicationId: string}>()
  const [loader, setLoader] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [optimizations, setOptimizations] = useState<ApplicationDatabaseOptimizations | undefined>()
  const [selectedOptimizations, setSelectedOptimizations] = useState<string[]>([])

  useEffect(() => {
    getDatabaseOptimizationList(applicationId ?? '').then((resp) => {
      setOptimizations(resp)
      setLoader(false)
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      types: [],
    },
    onSubmit: async (values) => {
      setIsUpdating(true)
      updateApplicationDatabaseOptimizations(applicationId ?? '', values.types).then((response) => {
        if (response) {
          notifySuccess('Database Optimizations updated')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notifyError('Something went wrong')
        }
        setIsUpdating(false)
      })
    },
  })

  const handleCheckboxChange = (event: any) => {
    let array

    if (event.target.checked) {
      array = [...selectedOptimizations, event.target.value]
    } else {
      array = selectedOptimizations.filter((e) => e !== event.target.value)
    }

    setSelectedOptimizations(array)
    formik.setFieldValue('types', array)
  }

  return (
    <div className='container-fluid' id='database_optimization_tab'>
      <TabHeader heading='Database Cleanup' />
      <TabBody>
        <div className='alert alert-danger d-flex align-items-center p-4'>
          <div className='d-flex flex-column'>
            <span>* Please take backup before database operations!</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <table
            id='kt_table_applications'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            role='table'
          >
            <tbody className='text-gray-600 fw-bold' role='rowgroup'>
              {optimizations &&
                !loader &&
                Object.entries(optimizations).map(([type, label]) => (
                  <tr key={type} role='row'>
                    <td role='cell' className='p-0' style={{height: '50px'}}>
                      <div className='d-flex align-items-center'>
                        <div style={{marginRight: '10px'}} className='d-flex flex-column'>
                          {label['text']}
                        </div>
                        <span className='badge badge-warning'>{label['count']}</span>
                      </div>
                    </td>

                    <td role='cell' className='p-0' style={{width: '34px'}}>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='types'
                          value={type}
                          onChange={(event) => handleCheckboxChange(event)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              {loader && (
                <div style={{textAlign: 'center', padding: '10px'}}>
                  Fetching details...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              )}
            </tbody>
          </table>

          <div className='d-flex align-items-center my-5'>
            {!loader && (
              <button
                type='submit'
                className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10 p-3`}
                disabled={selectedOptimizations.length === 0 || isUpdating}
              >
                <span className='indicator-label'>
                  {isUpdating ? 'Please wait . . .' : 'Run Optimizations'}
                </span>
                {isUpdating && (
                  <span className='indicator-progress'>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div>
        </form>
      </TabBody>
    </div>
  )
}

export default Database
