import React from 'react'

type LogFormProps = {
  numLines: number
  setNumLines: (x: number) => void
  getLogs: () => void
}

const LogForm = ({numLines, setNumLines, getLogs}: LogFormProps) => {
  return (
    <div className='d-flex flex-column fv-row mb-4 rounded-3 border px-4 me-4 pt-4'>
      <div className='d-flex flex-row gap-2 mb-3 justify-content-around'>
        <input
          type='number'
          className='form-control form-control-solid w-75'
          placeholder='N Line'
          name='endLine'
          defaultValue={numLines}
          onChange={(e) => setNumLines(+e.target.value)}
        />
        <button type='button' className='btn btn-primary w-25' onClick={() => getLogs()}>
          Get Last {numLines} Lines
        </button>
      </div>
      <p className='text-muted'>
        <b>Note:</b> Maximum of 1000 logs can only be fetched
      </p>
    </div>
  )
}

export default LogForm
