import {initialDomain, Domain} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {DomainsListLoading} from '../DomainsListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {createDomain} from '../../core/_requests'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../utils/NotifyUtils'
// import {createDomain} from '../../core/_requests'

type Props = {
  isDomainLoading: boolean
  domain: Domain
}

const editDomainSchema = Yup.object().shape({
  domain_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .required('Domain name is required'),
})

const DomainEditModalForm: FC<Props> = ({domain, isDomainLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [domainForEdit] = useState<Domain>({
    domain_name: domain.domain_name || initialDomain.domain_name,
    id: domain.id || initialDomain.id,
    domain_status: domain.domain_status || initialDomain.domain_status,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: domainForEdit,
    validationSchema: editDomainSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const toastId = notifyProgress('Domain addition in progess...!')
      createDomain(values.domain_name)
        .then((data) => {
          notifySuccess('Domain addition successful', toastId)
        })
        .catch((error) => {
          notifyError(`Domain addition failed: ${(error.message, toastId)}`)
          console.log(error.message)
        })
        .finally(() => {
          setSubmitting(false)
          cancel(true)
        })
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_domain_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_domain_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_domain_header'
          data-kt-scroll-wrappers='#kt_modal_add_domain_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Domain Name</label>
            <input
              placeholder='Domain Name'
              {...formik.getFieldProps('domain_name')}
              type='text'
              name='domain_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.domain_name && formik.errors.domain_name},
                {
                  'is-valid': formik.touched.domain_name && !formik.errors.domain_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isDomainLoading}
            />
            {formik.touched.domain_name && formik.errors.domain_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.domain_name}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-domains-modal-action='cancel'
            disabled={formik.isSubmitting || isDomainLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-domains-modal-action='submit'
            disabled={isDomainLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isDomainLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isDomainLoading) && <DomainsListLoading />}
    </>
  )
}

export {DomainEditModalForm}
