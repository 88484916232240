import React from 'react'

const Backups = () => {
  return (
    <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
      <div className='d-flex overflow-auto'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Backup</h3>
          </div>
        </div>
      </div>
      <div className='px-9 py-5 row'>
        <div className='col-6'>
          <div className='my-4'>
            <label className='required fw-bold fs-6 mb-2'>Scheduled Time</label>
            <div className='d-flex'>
              <select
                className='form-select form-select-solid form-select-lg me-3'
                name='instance_option_id'
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
              </select>
              <select
                className='form-select form-select-solid form-select-lg me-3'
                name='instance_option_id'
              >
                <option value='0'>00</option>
                <option value='15'>15</option>
                <option value='30'>30</option>
                <option value='45'>45</option>
              </select>
              <select
                className='form-select form-select-solid form-select-lg'
                name='instance_option_id'
              >
                <option value='AM'>AM</option>
                <option value='PM'>PM</option>
              </select>
            </div>
          </div>
          <div className='my-4'>
            <label className='required fw-bold fs-6 mb-2'>Backup Frequency</label>
            <select
              className='form-select form-select-solid form-select-lg'
              name='instance_option_id'
            >
              <option value='1 Day'>1 Day</option>
            </select>
          </div>
          <div className='my-4'>
            <label className='required fw-bold fs-6 mb-2'>Retention Time</label>
            <select
              className='form-select form-select-solid form-select-lg'
              name='instance_option_id'
            >
              <option value='1 Day'>1 Week</option>
            </select>
          </div>
          <a href='#' className='btn btn-primary'>
            Save
          </a>
        </div>
        <div className='col-5 mx-4 my-4'>
          <div>
            <h4 className='fw-bolder'>Backup Size</h4>
            <p className='fs-6 text-gray-600'>Backup Size: 20MB</p>
          </div>
          <div>
            <h4 className='fw-bolder'>On Demand Backup</h4>
            <p className='fs-6 text-gray-600'>
              You can backup manually. This can be helpful to backup your site before any
              update/upgrade
            </p>
            <a href='#' className='btn btn-primary fw-bold'>
              Backup Now
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Backups
