import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {ApplicationStaging, StagingStatus} from '../../../applications-list/core/_models'
import {
  getStagingStatusByApplication,
  updateApplicationStagingStatus,
  updateApplicationSyncStatus,
} from '../../../applications-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../utils/ConfirmSuccessModal'

const Staging: React.FC = () => {
  const {applicationId} = useParams()

  const [stagingInfo, setStagingInfo] = useState<ApplicationStaging | undefined>({
    staging_state: false,
    staging_status: StagingStatus.SYNCING_COMPLETED,
  })
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)
  const [isSyncStagingButtonActive, setIsSyncStagingButtonActive] = useState(false)
  const [isSyncStagingConfirmationVisible, setIsSyncStagingConfirmationVisible] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    getStagingStatusByApplication(applicationId ?? '').then((resp) => {
      setStagingInfo(resp)
      setIsSyncStagingButtonActive(resp!.staging_state)
    })
  }, [])

  useEffect(() => {
    if (stagingInfo!.staging_status?.includes('_IN_PROGRESS')) {
      const pollInterval = setInterval(() => {
        getStagingStatusByApplication(applicationId ?? '').then((response) => {
          setStagingInfo(response)
          setIsSyncStagingButtonActive(response!.staging_state)

          if (!response!.staging_status.includes('_IN_PROGRESS')) {
            clearInterval(pollInterval)
          }
        })
      }, 5000)

      return () => clearInterval(pollInterval)
    }
  }, [stagingInfo!.staging_status])

  const handleToggleChange = () => {
    setStagingInfo({
      ...stagingInfo!,
      staging_state: !stagingInfo!.staging_state,
    })
    setIsSaveButtonActive(!isSaveButtonActive)
  }

  const handleSaveButtonClick = () => {
    setIsUpdating(true)

    updateApplicationStagingStatus(applicationId ?? '', stagingInfo!.staging_state)
      .then((response) => {
        if (response) {
          notifySuccess('Staging update has started')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notifyError('Something went wrong')
        }
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      })
      .catch((error) => {
        notifyError('Operation ' + error)

        setTimeout(() => {
          window.location.reload()
        }, 3000)
      })
  }

  const handleConfirmSyncStaging = () => {
    setIsSyncStagingConfirmationVisible(false)
    setIsUpdating(true)

    updateApplicationSyncStatus(applicationId ?? '')
      .then((response) => {
        if (response) {
          notifySuccess('Syncing Process has started')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notifyError('Something went wrong')
        }
        setIsUpdating(false)
      })
      .catch((error) => {
        notifyError('Operation ' + error)

        setTimeout(() => {
          window.location.reload()
        }, 3000)
      })
  }

  return (
    <div className='card mb-3 mb-xl-10' id='staging_tab'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Staging</h3>
        </div>
      </div>
      <div className='px-9 py-4'>
        {stagingInfo!.staging_status?.includes('_IN_PROGRESS') ? (
          <div className='alert alert-warning'>
            {stagingInfo!.staging_status?.includes('UPDATION')
              ? `* Staging status update is in progress...`
              : `* Staging sync is in progress...`}
          </div>
        ) : (
          <div className='row mb-10'>
            <div className='col-lg-12 mt-3'>
              <div className='row align-items-center'>
                <div className='col-lg-2'>Staging Status:</div>
                <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={stagingInfo!.staging_state}
                    onChange={handleToggleChange}
                    style={{
                      backgroundColor: stagingInfo!.staging_state ? 'green' : 'grey',
                      borderColor: stagingInfo!.staging_state ? 'green' : 'grey',
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <button
                    type='button'
                    className={`btn ${isUpdating ? 'btn-light' : 'btn-primary'} me-10`}
                    onClick={handleSaveButtonClick}
                    disabled={!isSaveButtonActive || isUpdating}
                  >
                    <span className='indicator-label'>
                      {isUpdating ? 'Please wait . . .' : 'Save'}
                    </span>
                    {isUpdating && (
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className='separator border-secondary my-6'></div>
            <div className='mt-2'>
              <button
                type='button'
                className={`btn ${isUpdating ? 'btn-light' : 'btn-success'} me-10`}
                onClick={() => setIsSyncStagingConfirmationVisible(true)}
                disabled={!isSyncStagingButtonActive || isUpdating}
              >
                <span className='indicator-label'>
                  {isUpdating ? 'Please wait . . .' : 'Sync Staging'}
                </span>
                {isUpdating && (
                  <span className='indicator-progress'>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <div className='alert alert-primary d-flex align-items-center px-4 py-4 my-8'>
                <div className='d-flex flex-column'>
                  <span>
                    Sync staging will sync live website to staging website i.e after this your
                    staging will be exactly like the live website.
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isSyncStagingConfirmationVisible && (
        <ConfirmSuccessModal
          setIsOpen={setIsSyncStagingConfirmationVisible}
          title='Are you sure?'
          btnTitle='Sync'
          content='Staging will be synced with the live website, and your changes will be erased.'
          successFunction={handleConfirmSyncStaging}
        />
      )}
    </div>
  )
}

export default Staging
