import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Tabs from '../../../../../utils/Tabs'
import Uptime from './components/Uptime'
import Newrelic from './components/Newrelic'

const Monitoring = () => {
  const {applicationId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='dc_monitoring_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Monitoring</h3>
                  </div>
                </div>
              </div>
              <div className='d-flex pt-3'>
                <Tabs
                  tabs={[
                    {name: 'Uptime', path: `/application/${applicationId}/monitoring/uptime`},
                    {name: 'Newrelic', path: `/application/${applicationId}/monitoring/newrelic`},
                  ]}
                />
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route path='uptime' element={<Uptime />} />
        <Route path='newrelic' element={<Newrelic />} />
        <Route index element={<Navigate to='uptime' />} />
      </Route>
    </Routes>
  )
}

export default Monitoring
