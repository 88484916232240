import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../core/QueryResponseProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'

const ServersListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  return (
    <div className='row mt-5'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_servers_paginate'>
          <ul className='pagination'>
            {pagination.links?.map((link) => (
              <li
                key={link.label}
                className={clsx('page-item', {
                  active: pagination.page === link.page,
                  disabled:
                    isLoading ||
                    (link.label === 'Previous' && pagination.page === 1) ||
                    (link.label === 'Next' && pagination.page + 2 === pagination.links?.length),
                  previous: link.label === 'Previous',
                  next: link.label === 'Next',
                })}
              >
                <a
                  className={clsx('page-link', {
                    'page-text': link.label === 'Previous' || link.label === 'Next',
                  })}
                  onClick={() => updatePage(link.page)}
                  style={{cursor: 'pointer'}}
                >
                  {link.label === 'Previous' ? (
                    <i className='previous' />
                  ) : link.label === 'Next' ? (
                    <i className='next' />
                  ) : (
                    link.label
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ServersListPagination}
