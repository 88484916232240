/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG} from '../../../helpers'

const HelpDrawer = () => {
  const location = useLocation()

  const getPageGuide = () => {
    return {
      title: '',
      body: '',
    }
    // if (location.pathname.includes('/servers/list')) {
    //   return {
    //     title: 'Server List Page',
    //     body: `The server list page provides the following details
    //     <ol>
    //       <li><b>Server Names</b>: The server names assigned to the server by the user</li>
    //       <li><b>Server IPs</b>: The static server IPs</li>
    //       <li><b>Server States</b>: The current server state (STARTED, STOPPED, STARTING, STOPPING, RESTARTING)</li>
    //       <li><b>Server Actions</b>: The available server actions includes Restart, Start/Stop, Edit. (Note that these actions are not not available for a custom server)</li>
    //     </ol>
    //     <br>
    //     <br>
    //     The <mark className="bg-primary"><b>Launch Server</b></mark> button is to be used to launch a new server
    //     `,
    //   }
    // }
  }

  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold text-gray-600'>Learn & Get Inspired</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
          </div>
        </div>

        <div className='card-body' id='kt_help_body'>
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
            <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-2'>
              <h2 className='fw-bolder mb-5'>{getPageGuide()?.title}</h2>
              <div
                className='accordion-body'
                dangerouslySetInnerHTML={{__html: getPageGuide()?.body || ''}}
              />
            </div>
            <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              <h2 className='fw-bolder mb-5'>
                Talk To{' '}
                <a
                  href='https://cloudphant.com'
                  className=''
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  CloudPhant
                </a>
              </h2>
              <div className='fs-5 mb-5'>
                <span className='text-muted'>
                  Join our developers community to find answer to your question and help others.
                </span>
              </div>
              <a
                href='https://calendly.com/cloudphant/30min?month=2024-04'
                className='btn btn-lg explore-btn-primary w-100'
                target='_blank'
                rel='noopener noreferrer'
              >
                Get Support
              </a>
            </div>

            {/* <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning'>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs027.svg'
                  className='svg-icon-warning svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <a
                    href='https://preview.keenthemes.com/metronic8/react/docs/docs/quick-start'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Documentation &amp; Videos
                  </a>
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    From guides and video tutorials, to live demos and code examples to get started.
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div>
            <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary'>
                <KTSVG
                  path='/media/icons/duotune/ecommerce/ecm007.svg'
                  className='svg-icon-primary svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <a
                    href='https://preview.keenthemes.com/metronic8/react/docs/docs/utilities'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Plugins &amp; Components
                  </a>
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Check out our 300+ in-house components and customized 3rd-party plugins.
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div>
            <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-info'>
                <KTSVG
                  path='/media/icons/duotune/art/art006.svg'
                  className='svg-icon-info svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <Link
                    to='/builder'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Layout Builder
                  </Link>
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Dynamically modify and preview layout
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div>
            <div className='d-flex align-items-center mb-7'>
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-danger'>
                <KTSVG
                  path='/media/icons/duotune/electronics/elc009.svg'
                  className='svg-icon-danger svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                <div className='d-flex flex-column me-2 me-lg-5'>
                  <a
                    href='https://preview.keenthemes.com/metronic8/react/docs/docs/changelog'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    What's New
                  </a>

                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Latest features and improvements added with our users feedback in mind.
                  </div>
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HelpDrawer}
