import React from 'react'
import {Id, toast} from 'react-toastify'

const notifySuccess = (message: string, id?: Id) => {
  if (id) toast.dismiss(id)
  return toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
  })
}

const notifyProgress = (message: string) => {
  return toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 15000,
  })
}

const notifyError = (message: string, id?: Id) => {
  if (id) toast.dismiss(id)
  return toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
  })
}

export type toastifyId = Id

export {notifyProgress, notifySuccess, notifyError}
