import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {AccessDetailsDto} from '../../../../applications-list/core/_models'
import {
  addDomainToApplication,
  createUptime,
  deleteUptime,
  getDomainByApplication,
  getUptime,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import {uptime} from 'process'

const Uptime = () => {
  const {applicationId} = useParams()

  const [url, setUrl] = useState<string | undefined>(undefined)
  const [uptimeEnabled, setUptimeEnabled] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  useEffect(() => {
    getUptime(applicationId ?? '').then((resp) => {
      if (resp?.url) {
        setUrl(resp.url)
        if (resp.url.length > 0) setUptimeEnabled(true)
      }
    })
  }, [])

  const addDomainHandler = () => {
    if (!url) return
    const notifyId = notifyProgress('Enabling uptime monitoring')
    createUptime(applicationId ?? '', url)
      .then(() => {
        getUptime(applicationId ?? '').then((resp) => {
          if (resp?.url) {
            setUrl(resp.url)
            if (resp.url.length > 0) setUptimeEnabled(true)
          }
        })
        notifySuccess('Uptime monitoring enabled', notifyId)
      })
      .catch((error) => {
        notifyError('Uptime monitoring failed: ' + error.message, notifyId)
      })
  }

  return (
    <>
      <div className='card mb-3 mb-xl-10 w-100' id='dc_access_details_view'>
        <TabHeader heading='Uptime' />
        <TabBody>
          <>
            <div className='row align-items-center mb-8'>
              <div className='col-lg-3 fs-5 text-gray-600'>Uptime Monitoring Status:</div>
              <div className='col-lg-3 form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  checked={uptimeEnabled}
                  onChange={async (e) => {
                    console.log(e)
                    if (uptimeEnabled) {
                      if (url) {
                        const id = notifyProgress('Uptime monitoring being disabled')
                        deleteUptime(applicationId ?? '').then((resp) =>
                          notifySuccess('Uptime monitoring disabled', id)
                        )
                      }
                      setUptimeEnabled(false)
                      setUrl(undefined)
                      setIsButtonDisabled(false)
                    } else {
                      setUptimeEnabled(true)
                    }
                  }}
                  style={{
                    backgroundColor: uptimeEnabled ? 'green' : 'grey',
                    borderColor: uptimeEnabled ? 'green' : 'grey',
                  }}
                />
              </div>
            </div>
            {uptimeEnabled && (
              <div>
                <p className='fs-6 text-gray-600'>
                  Please give the url which you want to monitor in the input below. (Please give the
                  full url with http/https as well)
                </p>
                <div className='input-group mb-4'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Url to monitor (eg: https://example.com)'
                    aria-label='Url'
                    aria-describedby='basic-addon2'
                    value={url}
                    onChange={(e) => {
                      let regex = new RegExp(
                        /^https?:\/\/(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/
                      )
                      if (regex.test(e.target.value) == true) {
                        setIsButtonDisabled(false)
                      } else {
                        setIsButtonDisabled(true)
                      }
                      setUrl(e.target.value.trim())
                    }}
                  />
                  <button
                    className='btn btn-primary'
                    id='basic-addon2'
                    onClick={addDomainHandler}
                    disabled={isButtonDisabled || !url}
                  >
                    Enable Uptime Monitoring
                  </button>
                </div>
              </div>
            )}
            {isButtonDisabled == true && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <b>Please enter a valid url.</b> (You should give a full url with http/https as
                    well)
                  </span>
                </div>
              </div>
            )}
          </>
        </TabBody>
      </div>
    </>
  )
}

export default Uptime
