import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'
import {CloudEditModalForm} from './CloudEditModalForm'
import {getCloudById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {initialCloud} from '../../core/_models'

const CloudEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: cloud,
    error,
  } = useQuery(
    `${'clouds-list'}-cloud-${itemIdForUpdate}`,
    () => {
      return getCloudById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <CloudEditModalForm isCloudLoading={isLoading} cloud={initialCloud} />
  }

  if (!isLoading && !error && cloud) {
    return <CloudEditModalForm isCloudLoading={isLoading} cloud={cloud} />
  }

  return null
}

export {CloudEditModalFormWrapper}
