import React, {useState} from 'react'
import LogForm from './LogForm'
import {useParams} from 'react-router-dom'
import TabHeader from '../../../../../../../utils/TabHeader'
import TabBody from '../../../../../../../utils/TabBody'

type LogComponentProps = {
  logTitle: string
  getLogs: (applicationId: string, numLines: number) => Promise<string[] | undefined>
}

const LogComponent = ({getLogs, logTitle}: LogComponentProps) => {
  const {applicationId} = useParams()

  const [inputText, setInputText] = useState('')
  const [shouldHighlight, setShouldHighlight] = useState(false)

  const [logs, setLogs] = useState<string[]>([''])
  const [logArray, setLogArray] = useState<string[]>([''])

  const [numLines, setNumLines] = useState(100)
  const [error, setError] = useState<any>()

  const [loading, setLoading] = useState(false)
  const [showLogs, setShowLogs] = useState(false)

  const getLogArray = async () => {
    setShowLogs(true)
    setError(undefined)
    if (numLines > 1000) {
      setError('Only last 1000 logs can be fetched')
      return
    }
    setLoading(true)
    getLogs(applicationId ?? '', numLines)
      .then((logs) => {
        setLogArray(logs || [])
        setLogs(logs || [])
      })
      .catch((e) => {
        setError('Failed to fetch access logs: ' + e.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const filterLogs = () => {
    let filteredLogs = logs.filter((log) => {
      if (log.includes(inputText)) return true
      return false
    })
    if (filteredLogs.length <= 0) setLogArray(['** No matching entries found **'])
    else setLogArray(filteredLogs as string[])
  }

  const getHighlightedText = (text: string, highlight: string) => {
    // Split text on highlight term, include term itself into parts, ignore case
    try {
      const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
      return (
        <p>
          {parts.map((part) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span className='bg-warning text-dark'>{part}</span>
            ) : (
              part
            )
          )}
        </p>
      )
    } catch (error) {
      console.log('Invalid Input String')
    }
    return <p>{text}</p>
  }

  const handleInputTextChange = (text: string) => {
    setInputText(text)
  }

  return (
    <div className='w-100'>
      <TabHeader heading={logTitle} />
      <TabBody>
        <LogForm numLines={numLines} setNumLines={setNumLines} getLogs={getLogArray} />
        {showLogs && (
          <div>
            {error ? (
              <div className='alert alert-danger d-flex align-items-center p-5 '>
                <div className='d-flex flex-column'>
                  <span>{error}</span>
                </div>
              </div>
            ) : loading ? (
              <div className='w-100 text-center my-10'>
                <span className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            ) : logs.length <= 0 ? (
              <div className='alert alert-primary d-flex align-items-center p-5 w-100'>
                <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>

                <div className='d-flex flex-column'>
                  <span>No Logs</span>
                </div>
              </div>
            ) : (
              <div className='d-flex flex-column-fluid pe-4'>
                <div className='d-flex flex-column flex-row-fluid'>
                  <div className='d-flex justify-content-around align-items-center h-70px py-4'>
                    <input
                      type='text'
                      data-kt-project-table-filter='search'
                      className='form-control form-control-solid ps-6 border bg-gray-700 me-1 w-50'
                      placeholder='Search Logs'
                      value={inputText}
                      onChange={(e) => handleInputTextChange(e.target.value)}
                    />
                    <button className='btn btn-primary mx-1 w-25' onClick={filterLogs}>
                      Filter
                    </button>
                    <button
                      className='btn btn-primary mx-1 w-25'
                      onClick={() => setShouldHighlight((state) => !state)}
                    >
                      Highlight
                    </button>
                  </div>

                  <div className='pb-4'>
                    <div className='bg-dark rounded-1 py-6 px-4'>
                      <div
                        className='w-100'
                        style={{
                          maxHeight: '600px',
                          overflowY: 'scroll',
                          scrollbarColor: '#fff',
                          scrollbarWidth: 'auto',
                          scrollbarGutter: 'stable',
                        }}
                      >
                        {logArray.map((log) => {
                          return (
                            <div>
                              <p className='text-white text-break'>
                                {shouldHighlight ? getHighlightedText(log, inputText) : log}
                              </p>
                              <div className='separator separator-dotted border-light-subtle my-4'></div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </TabBody>
    </div>
  )
}

export default LogComponent
