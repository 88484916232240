import axios, {AxiosResponse} from 'axios'
import {IUpdatePassword, IUserDetails, OrganizationCredit} from './_models'
import {Response} from '../../../../../../_metronic/helpers'
import {RazorpayOrder} from '../../../../servers/servers-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/v1/user`
const ORGANIZATION_URL = `${API_URL}/v1/organization`

const updateUser = async (updatedUser: IUserDetails) => {
  const response = await axios.patch(USER_URL, updatedUser)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const updateUserPassword = async (passwords: IUpdatePassword) => {
  const response = await axios.post(`${USER_URL}/password-update`, {
    current_password: passwords.currentPassword,
    new_password: passwords.newPassword,
    password_confirmation: passwords.passwordConfirmation,
  })
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const getOrganizationCredit = async (): Promise<OrganizationCredit | undefined> => {
  return axios
    .get(`${ORGANIZATION_URL}/credit`)
    .then((response: AxiosResponse<Response<OrganizationCredit>>) => response.data)
    .then((response: Response<OrganizationCredit>) => response.data)
}

const addOrganizationCredit = async (
  amount: number
): Promise<{order: RazorpayOrder; checkoutId: string}> => {
  const response = await axios.post(`${ORGANIZATION_URL}/credit/init`, {amount})

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

export {updateUser, updateUserPassword, getOrganizationCredit, addOrganizationCredit}
