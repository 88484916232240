import {Response} from '../../../../../../_metronic/helpers'
import {CloudProvider} from '../../../../servers/servers-list/core/_models'

interface keyval {
  [key: string]: any
}

export type Cloud = {
  id: string
  name: string
  description: string
  platform: string // TODO: add enum
  api_key?: keyval
  api_key_val?: string
  access_key_id?: string
  secret_access_key?: string
  version: number
}

export type CloudsQueryResponse = Response<Array<Cloud>>

export const initialCloud: Cloud = {
  id: '',
  name: '',
  description: '',
  platform: '',
  version: 1,
}

export const availablePlatforms = [
  CloudProvider.AWS,
  CloudProvider.DIGITALOCEAN,
  CloudProvider.LINODE,
  CloudProvider.VULTR,
]
