import React from 'react'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import KnowledgeBaseAccordion from '../../common/knowledgebase/KnowledgeBaseAccordion'
import {KnowledgeBaseAccordionProps} from '../../common/knowledgebase/KnowledgeBaseAccordionProps'

function ServerPageTips() {
  const location = useLocation()

  const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBaseAccordionProps>()
  const [hasKnowledgeBase, setHasKnowledgeBase] = useState<boolean>(false)

  const setKnowledgeBaseData = (knowledgeBase: KnowledgeBaseAccordionProps) => {
    setKnowledgeBase(knowledgeBase)
    setHasKnowledgeBase(true)
  }

  useEffect(() => {
    if (location.pathname.includes('/firewall')) {
      setKnowledgeBaseData({
        body: 'Users can add whitelisted or blacklisted IPs for SSH port <code>22</code>.',
      })
    } else if (location.pathname.includes('/services')) {
      setKnowledgeBaseData({
        body: 'Users can see running services, their version and status on this page. Users can also restart the services as per their requirement.',
      })
    } else if (location.pathname.includes('/scale')) {
      setKnowledgeBaseData({
        body: 'Users can scale servers up or down based on the cloud provider.',
      })
    } else if (location.pathname.includes('/delete')) {
      setKnowledgeBaseData({
        body: 'You can delete or restart your subscription if you are using our managed servers. But if you are using your own servers, you can detach servers from here.',
      })
    } else if (location.pathname.includes('/ssh')) {
      setKnowledgeBaseData({
        body: 'Users can get the <b>server root ssh access</b> credentials on this page. Users will find the IP, username and password using which you can login to the server using any ssh client. You can use our integrated <mark><b>web shell</b></mark> as well linked in the above section.',
      })
    } else if (location.pathname.includes('/settings')) {
      setKnowledgeBaseData({
        body: 'Users can edit <b>settings for all the services</b> available on this server here. Users can click on the blue pen icon, edit the settings and save them',
      })
    } else if (location.pathname.includes('/monitoring/basic-data')) {
      setKnowledgeBaseData({
        body: 'Users can monitor the <b>core vitals of the server</b> here. The percentage usage of CPU, RAM and Disk can be seen here',
      })
    } else if (location.pathname.includes('/monitoring/monit')) {
      setKnowledgeBaseData({
        body: 'Monit is a free open source and very useful tool that automatically monitors and manages processes, files, directories, checksums, permissions, filesystems, and services like <b>Nginx, MariaDB, PHP, Redis</b>, and so on in a UNIX/Linux based systems and provides an excellent and helpful monitoring functionality to system administrators.<br />It also has the ability to start a process if it is not running, restart a process if not responding, and stop a process if uses high resources.',
      })
    } else if (location.pathname.includes('/fail2ban')) {
      setKnowledgeBaseData({
        body: `Users can enable or disable fail2ban feature from here.
        <br/>
        <br/>
        <b>What is Fail2Ban?</b>
        <br/>
        Fail2Ban is an <mark>intrusion prevention system</mark>. Users can safeguard their server from brute force attacks through IP address banning ( Fail2Ban ). Fail2Ban <b>utilizes regular expressions for monitoring log files and spotting patterns</b> that may correspond to authentication failures, looking for exploits, and additional entries that may appear to be suspicious.
        `,
      })
    } else if (location.pathname.includes('/swap-space')) {
      setKnowledgeBaseData({
        body: `Users can enable or disable swap space on the server from here. Enabling swap space will create a <b>4GB swap space</b>.
        <br/>
        <br/>
        <b>What is Swap Space?</b>
        <br/>
        Swap space in Linux is an extension of physical RAM, offering virtual memory that <mark><b>helps maintain system stability and performance</b></mark>. It improves performance and allows processes to continue running when RAM is fully used and prevents memory errors.
        `,
      })
    } else if (location.pathname.includes('/networking')) {
      setKnowledgeBaseData({
        body: `Users can enable or disable networking accelaration from here. Enabling this accelaration will change the TCP algorithm and <mark><b>improve network throughput and reduce latency</b></mark>. The server would use <b>BBR algorithm by Google</b> if this optimization is enabled
        <br/>
        <br/>
        <b>What is Googles BBR Algorithm?</b>
        <br/>
        Bottleneck Bandwidth and Round-trip propagation time (BBR) is a TCP congestion control algorithm developed at Google in 2016. According to Google's tests, BBR's throughput can reach as much as 2,700x higher than today's best loss-based congestion control; queueing delays can be 25x lower.`,
      })
    } else {
      setHasKnowledgeBase(false)
    }
  }, [location.pathname])

  return (
    <>
      {hasKnowledgeBase && (
        <div>
          <KnowledgeBaseAccordion body={String(knowledgeBase?.body)} />
        </div>
      )}
    </>
  )
}

export default ServerPageTips
