import {FC} from 'react'
import {Server, ServerIntermediateState} from '../../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  server: Server
}

const ServerNameCell: FC<Props> = ({server}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      {Object.values(ServerIntermediateState).includes(server.server_state as any) ? (
        <span className='text-blue-600 mb-1 fw-bold'>{server.name}</span>
      ) : (
        <Link
          to={`/server/${server.server_id}/ssh`}
          className='text-blue-500 hover:text-blue-700 hover:bg-blue-100 mb-1'
          style={{textDecoration: 'none'}}
        >
          {server.name}
        </Link>
      )}
    </div>
  </div>
)

export {ServerNameCell}
