import {useState} from 'react'

type PasswordFieldProps = {
  password: string
}

const PasswordField = ({password}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShow = () => {
    setShowPassword(!showPassword)
  }

  const obscuredPassword = '•'.repeat(12)

  return (
    <div className='field has-addons' style={{display: 'flex', alignItems: 'center'}}>
      <div className='control is-expanded'>
        <span className='fw-bold fs-6'>{showPassword ? password : obscuredPassword}</span>
      </div>
      <button
        className='button'
        onClick={toggleShow}
        style={{border: 'none', background: 'none', marginLeft: 8}}
      >
        <span className='icon is-small is-right'>
          <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
        </span>
      </button>
      <button
        className='button'
        onClick={() => navigator.clipboard.writeText(password)}
        style={{border: 'none', background: 'none', marginLeft: 2}}
      >
        <span className='icon'>
          <i className='fa-solid fa-copy'></i>
        </span>
      </button>
    </div>
  )
}

export default PasswordField
