import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {ApplicationEditModalForm} from './ApplicationEditModalForm'
import {getApplicationById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'

const ApplicationEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: application,
    error,
  } = useQuery(
    `${'applications-list'}-application-${itemIdForUpdate}`,
    () => {
      return getApplicationById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <ApplicationEditModalForm
        isApplicationLoading={isLoading}
        application={{application_id: '', name: ''}}
      />
    )
  }

  if (!isLoading && !error && application) {
    return <ApplicationEditModalForm isApplicationLoading={isLoading} application={application} />
  }

  return null
}

export {ApplicationEditModalFormWrapper}
