import React, {useState, useEffect} from 'react'
import ConfirmDeleteModal from '../../../../../../utils/ConfirmDeleteModal'
import {useNavigate, useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmRestartModal from '../../../../../../utils/ConfirmRestartModal'
import {
  getSubscriptionByServerId,
  updateSubscriptionStatusByServerId,
} from '../../../../../payments/subscriptions/subscriptions-list/core/_requests'
import {CloudAccountType} from '../../../../servers-list/core/_models'
import {deleteServer, getServerById} from '../../../../servers-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const Delete = () => {
  const {serverId} = useParams()
  const navigate = useNavigate()

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
  const [serverStatus, setServerStatus] = useState<string>('')
  const [isExternalDeployment, setIsExternalDeployment] = useState<boolean>(false)

  const [instanceOption, setInstanceOption] = useState<string>('')

  const handleSubscriptionStatusUpdate = async (toggleState: string) => {
    let data = await updateSubscriptionStatusByServerId(serverId, toggleState)
    if (data) {
      notifySuccess('Subscription status updated!')
      setServerStatus(toggleState)
    } else {
      notifyError('Subscription status update failed')
    }
  }

  const handleDelete = () => {
    deleteServer(serverId)
      .then(() => {
        notifySuccess('Server deletion successful!')
        setTimeout(() => {
          navigate('/servers')
        }, 3000)
      })
      .catch((error) => {
        notifyError('Server deletion failed with error: ' + error.message)
      })
  }

  const getStatus = async (serverId: string | undefined) => {
    let subscription = await getSubscriptionByServerId(serverId)
    setServerStatus(subscription?.status!)
  }

  useEffect(() => {
    getStatus(serverId)

    getServerById(serverId).then((resp) => {
      setInstanceOption(resp?.instance_option!)

      if (resp?.cloud_account_type === CloudAccountType.EXTERNAL) {
        setIsExternalDeployment(true)
      } else {
        setIsExternalDeployment(false)
      }
    })
  }, [])

  return (
    <>
      {isExternalDeployment ? (
        <div className='mb-4'>
          <TabHeader heading='Delete Server' />
          <TabBody>
            <p>
              Note: Deleting the server will permanently delete the server and all the related data.
              Please make sure that you don't have any dependency on the server
            </p>
            <button onClick={() => setIsConfirmDeleteModalOpen(true)} className='btn btn-danger'>
              {instanceOption ? 'Delete Server' : 'Detach Server'}
            </button>
            {isConfirmDeleteModalOpen && (
              <ConfirmDeleteModal
                setIsOpen={setIsConfirmDeleteModalOpen}
                title='Are you sure?'
                content='This will delete/ detach the server completely and related resources. This action is not reversible.'
                deleteFunction={handleDelete}
              />
            )}
          </TabBody>
        </div>
      ) : (
        <div className='mb-4'>
          <TabHeader
            heading={serverStatus === 'ACTIVE' ? 'Delete Subscription' : 'Restart Subscription'}
          />
          <TabBody>
            <p>
              {serverStatus === 'ACTIVE'
                ? "Note: Deleting the subscription will suspend the server and all the related data. Please make sure that you don't have any dependency on the server"
                : 'Note: Restarting the subscription will revive your existing server with all its data. Please follow the next steps to get the server up and running'}
            </p>
            <button
              onClick={() => setIsConfirmModalOpen(true)}
              className={serverStatus === 'ACTIVE' ? 'btn btn-danger' : 'btn btn-success'}
            >
              {serverStatus === 'ACTIVE' ? 'Delete Subscription' : 'Restart Subscription'}
            </button>
            {isConfirmModalOpen && serverStatus === 'ACTIVE' && (
              <ConfirmDeleteModal
                setIsOpen={setIsConfirmModalOpen}
                title='Are you sure?'
                content='This will delete your subscription and suspend the server temporarily. You can restart your subscription anytime in the future.'
                deleteFunction={() => handleSubscriptionStatusUpdate('SCHEDULED_FOR_CANCELLATION')}
              />
            )}
            {isConfirmModalOpen && serverStatus === 'SCHEDULED_FOR_CANCELLATION' && (
              <ConfirmRestartModal
                setIsOpen={setIsConfirmModalOpen}
                title='Are you sure?'
                content='This will restart your subscription and revive the server. You can delete your subscription anytime in the future.'
                restartFunction={() => handleSubscriptionStatusUpdate('ACTIVE')}
              />
            )}
          </TabBody>
        </div>
      )}
    </>
  )
}

export default Delete
