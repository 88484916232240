import React, {useEffect, useState} from 'react'
import {getFirewallSetting, updateFirewallSetting} from '../../../../servers-list/core/_requests'
import {useParams} from 'react-router-dom'
import {FirewallType, Port} from '../../../../servers-list/core/_models'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const Firewall = () => {
  const {serverId} = useParams()
  const [type, setType] = useState<FirewallType>(FirewallType.BLACKLIST)
  const [ips, setIps] = useState<string[]>([])

  const getSetting = async () => {
    const setting = await getFirewallSetting(serverId, Port.SSH)
    if (setting) {
      setType(setting.type)
      setIps(setting.ips)
    }
  }

  const submitHandler = async () => {
    const setting = {
      ips: ips,
      type: type,
      port: Port.SSH,
    }

    const notifyId = notifyProgress('Updating firewall settings')
    updateFirewallSetting(serverId, setting)
      .then(() => {
        notifySuccess('Firewall settings updated', notifyId)
      })
      .catch((error) => {
        notifyError('Firewall setting updation failed: ' + error.message, notifyId)
      })
  }

  useEffect(() => {
    getSetting()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
      <TabHeader heading='Firewall' />
      <TabBody>
        <div className='form-check form-check-custom form-check-solid my-4'>
          <input
            className='form-check-input'
            type='radio'
            checked={type == FirewallType.BLACKLIST}
            id='flexRadioDefault'
            name='ip'
            onClick={() => {
              setType(FirewallType.BLACKLIST)
            }}
          />
          <label className='form-check-label' htmlFor='flexRadioDefault'>
            Allow all the IPs except the blocked ones.
          </label>
        </div>
        <div className='form-check form-check-custom form-check-solid mb-4'>
          <input
            className='form-check-input'
            type='radio'
            checked={type == FirewallType.WHITELIST}
            id='flexRadioDefault'
            name='ip'
            onClick={() => {
              setType(FirewallType.WHITELIST)
            }}
          />
          <label className='form-check-label' htmlFor='flexRadioDefault'>
            Block all the IPs, except the whitelisted ones.
          </label>
        </div>
        <div className='input-group input-group-solid'>
          <span className='input-group-text'>
            {type == FirewallType.WHITELIST ? 'Whiltelisted' : 'Blacklisted'} IPs
          </span>
          <textarea
            className='form-control'
            aria-label='With textarea'
            value={ips.join(',')}
            onChange={(e) => {
              if (e.target.value === '') setIps([])
              else setIps(e.target.value.trim().split(','))
            }}
          ></textarea>
        </div>
        <a onClick={submitHandler} className='btn btn-primary my-5'>
          Save Changes
        </a>
      </TabBody>
    </div>
  )
}

export default Firewall
