import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'

const DomainsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddDomainModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-domain-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={openAddDomainModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Domain
      </button>
    </div>
  )
}

export {DomainsListToolbar}
