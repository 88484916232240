/* eslint-disable */
import React from 'react'
import {Link, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import AccessLogs from './components/AccessLogs'
import ErrorLogs from './components/ErrorLogs'
import Tabs from '../../../../../utils/Tabs'

const Logs = () => {
  const {applicationId} = useParams()
  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Logs</h3>
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <Tabs
                  tabs={[
                    {name: 'Access Logs', path: `/application/${applicationId}/logs/access`},
                    {name: 'Error Logs', path: `/application/${applicationId}/logs/error`},
                  ]}
                />
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route
          path='access'
          element={
            <>
              <AccessLogs />
            </>
          }
        />
        <Route
          path='error'
          element={
            <>
              <ErrorLogs />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='access' />} />
    </Routes>
  )
}

export default Logs
