import {initialCloud, Cloud, availablePlatforms} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {CloudsListLoading} from '../CloudsListLoading'
import {createCloud, updateCloud} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {CloudProvider} from '../../../../../servers/servers-list/core/_models'

type Props = {
  isCloudLoading: boolean
  cloud: Cloud
}

const editCloudSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Name is required'),
  description: Yup.string().max(2000, 'Maximum 2000 symbols'),
  access_key_id: Yup.string().min(3, 'Minimum 3 symbols').max(200, 'Maximum 200 symbols'),
  secret_access_key: Yup.string().min(3, 'Minimum 3 symbols').max(200, 'Maximum 200 symbols'),
  api_key_val: Yup.string().min(3, 'Minimum 3 symbols').max(200, 'Maximum 200 symbols'),
})

const CloudEditModalForm: FC<Props> = ({cloud, isCloudLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [selectedCloudProvider, setSelectedCloudProvider] = useState<string>()
  const {refetch} = useQueryResponse()

  const [cloudForEdit] = useState<Cloud>({
    ...cloud,
    name: cloud.name || initialCloud.name,
    description: cloud.description || initialCloud.description,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleCloudProviderChange = (
    event: React.MouseEvent<HTMLImageElement>,
    provider: string
  ) => {
    formik.setFieldValue('platform', provider)
    setSelectedCloudProvider(provider)
  }

  const formik = useFormik({
    initialValues: cloudForEdit,
    validationSchema: editCloudSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      if (values.api_key_val) {
        values.api_key = {api_key: values.api_key_val}
      } else if (values.platform === CloudProvider.AWS) {
        values.api_key = {
          access_key_id: values.access_key_id,
          secret_access_key: values.secret_access_key,
        }
      }

      if (isNotEmpty(values.id)) {
        updateCloud(values)
          .then((data) => {
            notifySuccess('Cloud updation successful')
          })
          .catch((error) => {
            notifyError(`Cloud updation failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      } else {
        await createCloud(values)
          .then((data) => {
            notifySuccess('Cloud addition successful')
          })
          .catch((error) => {
            notifyError(`Cloud addition failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_cloud_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_cloud_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_cloud_header'
          data-kt-scroll-wrappers='#kt_modal_add_cloud_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Cloud Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCloudLoading}
              style={{marginLeft: 14 + 'px'}}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Description</label>
            {/* end::Label */}

            {/* begin::Text */}
            <textarea
              placeholder='Write something about the cloud'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isCloudLoading}
              style={{marginLeft: 14 + 'px'}}
            ></textarea>
            {/* end::Text */}
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {!itemIdForUpdate && (
            <div
              className='row w-100 mb-5'
              data-kt-buttons='true'
              data-kt-buttons-target='.form-check-image, .form-check-input'
            >
              <label className='required fw-bold fs-6 mb-2'>Cloud Provider</label>
              {availablePlatforms?.map((option: any) => (
                <div className='col'>
                  <label
                    className={`form-check-image ${selectedCloudProvider === option && 'active'}`}
                  >
                    <div className='form-check-wrapper'>
                      <img
                        src={`/media/cloudproviders/${option}.jpg`}
                        onClick={(e) => handleCloudProviderChange(e, option)}
                      />
                    </div>
                  </label>
                </div>
              ))}
            </div>
          )}

          {cloud.platform === CloudProvider.AWS || selectedCloudProvider === CloudProvider.AWS ? (
            <>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Access Key ID</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Access Key ID'
                  {...formik.getFieldProps('access_key_id')}
                  type='text'
                  name='access_key_id'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.access_key_id && formik.errors.access_key_id},
                    {
                      'is-valid': formik.touched.access_key_id && !formik.errors.access_key_id,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCloudLoading}
                  style={{marginLeft: 14 + 'px'}}
                />
                {formik.touched.access_key_id && formik.errors.access_key_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.access_key_id}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Secret Access Key</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Secret Access Key'
                  {...formik.getFieldProps('secret_access_key')}
                  type='text'
                  name='secret_access_key'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid':
                        formik.touched.secret_access_key && formik.errors.secret_access_key,
                    },
                    {
                      'is-valid':
                        formik.touched.secret_access_key && !formik.errors.secret_access_key,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCloudLoading}
                  style={{marginLeft: 14 + 'px'}}
                />
                {formik.touched.secret_access_key && formik.errors.secret_access_key && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.secret_access_key}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </>
          ) : (
            <>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>API Key</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='API Key'
                  {...formik.getFieldProps('api_key_val')}
                  type='text'
                  name='api_key_val'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.api_key_val && formik.errors.api_key_val},
                    {
                      'is-valid': formik.touched.api_key_val && !formik.errors.api_key_val,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isCloudLoading}
                  style={{marginLeft: 14 + 'px'}}
                />
                {formik.touched.api_key_val && formik.errors.api_key_val && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.api_key_val}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </>
          )}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-clouds-modal-action='cancel'
            disabled={formik.isSubmitting || isCloudLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-clouds-modal-action='submit'
            disabled={
              isCloudLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCloudLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCloudLoading) && <CloudsListLoading />}
    </>
  )
}

export {CloudEditModalForm}
