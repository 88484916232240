import {FC} from 'react'
import {Record} from '../../../core/_models'

type Props = {
  record: Record
}

const RecordIsProxiedCell: FC<Props> = ({record}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>{record.proxied ? 'Proxied' : 'Not Proxied'}</div>
  </div>
)

export {RecordIsProxiedCell}
