import React, {useEffect, useState} from 'react'
import {CloudflareSettings} from '../../../domains-list/core/_models'
import {getCloudflareSetting, setCloudflareSetting} from '../../../domains-list/core/_requests'
import {notifyError} from '../../../../../../utils/NotifyUtils'

type CloudflareConfigSettingProps = {
  title: string
  description: string
  setting: CloudflareSettings
  options: [option: string, key: string][]
  cloudflareDomainId: string | undefined
  isPro?: boolean
}

const CloudflareConfigSetting = ({
  title,
  description,
  setting,
  cloudflareDomainId,
  options,
  isPro,
}: CloudflareConfigSettingProps) => {
  const defaultState: {[setting: string]: string} = {}
  options.map((option) => {
    defaultState[option[1]] = 'off'
  })
  const [config, setConfig] = useState<{[setting: string]: string}>(defaultState)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getCloudflareSetting(cloudflareDomainId ?? '', setting).then((resp) => {
      setConfig(resp != undefined ? resp : {})
      setLoading(false)
    })
  }, [])

  const handleSettingChange = (changedValue: {[setting: string]: string}) => {
    setLoading(true)
    setCloudflareSetting(cloudflareDomainId ?? '', setting, changedValue)
      .then((resp) => {
        setConfig(resp != undefined ? resp : false)
      })
      .catch((error) => {
        console.log(error.message)
        notifyError(error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className='rounded-3 border p-4 mb-4 d-flex'>
      <div className='me-4 flex-grow-1' style={{flex: '1'}}>
        <h5 className='fw-bolder d-flex align-items-center'>
          {title}
          {isPro && <span className='badge badge-warning ms-2'>REQUIRES PRO</span>}
        </h5>
        <p className='text-muted'>{description}</p>
      </div>
      <div
        className='d-flex border-start flex-row justify-content-center align-items-center'
        style={{flex: '0 0 20%'}}
      >
        <div className='form-check mt-2'>
          {options.map((option) => (
            <div className='form-check-label mb-2'>
              <input
                type='checkbox'
                className='form-check-input'
                checked={config && config[option[1]] == 'on'}
                onChange={() => {
                  const currentConfig = config
                  currentConfig[option[1]] = currentConfig[option[1]] == 'on' ? 'off' : 'on'
                  handleSettingChange(currentConfig)
                }}
              />
              {option[0]}
            </div>
          ))}
        </div>
        <div className='ms-3' style={{width: '20px'}}>
          {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>
    </div>
  )
}

export default CloudflareConfigSetting
