import {FC} from 'react'
import {CloudflareDomain} from '../../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  cloudflareDomain: CloudflareDomain
}

const CloudflareDomainNameCell: FC<Props> = ({cloudflareDomain}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link
        to={
          cloudflareDomain.status === 'active'
            ? `/integrations/cloudflare/domain/${cloudflareDomain.id}/records`
            : cloudflareDomain.status == 'pending'
            ? `/integrations/cloudflare/domain/${cloudflareDomain.id}/ns` // show Nameservers using URL /domain/${cloudflareDomain.id}/ns
            : '#'
        }
        className='text-blue-500 hover:text-blue-700 hover:bg-blue-100 mb-1'
        style={{textDecoration: 'none'}}
      >
        {cloudflareDomain.domain}
      </Link>
    </div>
  </div>
)

export {CloudflareDomainNameCell}
