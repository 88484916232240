import axios, {AxiosResponse} from 'axios'
import {Git, GitsQueryResponse} from './_models'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {ApiResponse} from '../../../../auth'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GIT_INTEGRATION_URL = `${API_URL}/v1/integration/git/api-key`

const createGit = async (git: Git) => {
  const response = await axios.post<ApiResponse>(GIT_INTEGRATION_URL, git)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getGits = async (query: string): Promise<GitsQueryResponse> => {
  return axios
    .get(`${GIT_INTEGRATION_URL}/all?${query}`)
    .then((d: AxiosResponse<GitsQueryResponse>) => d.data)
}

const updateGit = async (git: Git): Promise<Git> => {
  const response = await axios.patch(`${GIT_INTEGRATION_URL}/${git.id}`, git)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteGit = async (gitId: ID): Promise<void> => {
  const response = await axios.delete(`${GIT_INTEGRATION_URL}/${gitId}`)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getGitById = async (id: ID): Promise<Git | undefined> => {
  return axios
    .get(`${GIT_INTEGRATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Git>>) => response.data)
    .then((response: Response<Git>) => response.data)
}

export {getGits, createGit, updateGit, deleteGit, getGitById}
