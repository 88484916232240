import {ProjectsListSearchComponent} from './ProjectsListSearchComponent'
import {ProjectsListToolbar} from './ProjectsListToolbar'

const ProjectsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ProjectsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <ProjectsListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ProjectsListHeader}
