import {Column} from 'react-table'
import {DomainActionsCell} from './DomainActionCell'
import {DomainCustomHeader} from './DomainCustomHeader'
import {DomainNameCell} from './DomainNameCell'
import {DomainStatusCell} from './DomainStatusCell'
import {Domain} from '../../../core/_models'

const domainsColumns: ReadonlyArray<Column<Domain>> = [
  /*
  {
    Header: (props) => <DomainSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <DomainSelectionCell id={props.data[props.row.index].id} />,
  }, */
  {
    Header: (props) => (
      <DomainCustomHeader tableProps={props} title='Domain Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <DomainNameCell domain={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <DomainCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <DomainStatusCell domain={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <DomainCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <DomainActionsCell id={props.data[props.row.index].id} />,
  },
]

export {domainsColumns}
