import React, {useEffect, useState} from 'react'
import {CloudflareSettings} from '../../../domains-list/core/_models'
import {getCloudflareSetting, setCloudflareSetting} from '../../../domains-list/core/_requests'
import {notifyError} from '../../../../../../utils/NotifyUtils'

type CloudflareBinarySettingProps = {
  title: string
  description: string
  setting: CloudflareSettings
  cloudflareDomainId: string | undefined
  isPro?: boolean
}

const CloudflareBinarySetting = ({
  title,
  description,
  setting,
  cloudflareDomainId,
  isPro,
}: CloudflareBinarySettingProps) => {
  const [enabled, setEnabled] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getCloudflareSetting(cloudflareDomainId ?? '', setting).then((resp) => {
      setEnabled(resp != undefined ? (resp == 'on' || resp == 'open' ? true : false) : false)
      setLoading(false)
    })
  }, [])

  const handleSettingChanged = () => {
    setLoading(true)
    setCloudflareSetting(cloudflareDomainId ?? '', setting, !enabled ? 'on' : 'off')
      .then((resp) => {
        setEnabled(resp != undefined ? (resp == 'on' || resp == 'open' ? true : false) : false)
      })
      .catch((error) => {
        console.log(error.message)
        notifyError(error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className='rounded-3 border p-4 mb-4 d-flex'>
      <div className='me-4 flex-grow-1' style={{flex: '1'}}>
        <h5 className='fw-bolder d-flex align-items-center'>
          {title}
          {isPro && <span className='badge badge-warning ms-2'>REQUIRES PRO</span>}
        </h5>
        <p className='text-muted'>{description}</p>
      </div>
      <div
        className='d-flex border-start flex-row justify-content-center align-items-center'
        style={{flex: '0 0 20%'}}
      >
        <label className='form-check form-switch mt-2'>
          <input
            type='checkbox'
            className='form-check-input'
            checked={enabled}
            onChange={handleSettingChanged}
          />
        </label>
        <div style={{width: '20px'}}>
          {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>
    </div>
  )
}

export default CloudflareBinarySetting
