import {useEffect, useState} from 'react'
import TabBody from '../../../../../../utils/TabBody'
import TabHeader from '../../../../../../utils/TabHeader'
import {Link, useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {
  getNewrelicService,
  updateNewrelicService,
} from '../../../../applications-list/core/_requests'
import {ErrorResponse} from '../../../../../common/models/ErrorResponse'

const Newrelic = () => {
  const [newrelicState, setNewrelicState] = useState<boolean>(false)

  const {applicationId} = useParams()
  const [error, setError] = useState<ErrorResponse>({code: 0, message: ''})

  const [isUpdating, setIsUpdating] = useState(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  const getNewrelic = () => {
    getNewrelicService(applicationId)
      .then((data) => {
        if (data.code) {
          setError({code: data.code, message: data.message})
        } else {
          setNewrelicState(data.data)
          setError({code: 0, message: ''})
        }

        setIsUpdating(false)
      })
      .catch((e) => {
        setError({code: 0, message: 'Failed to retrieve newrelic state: ' + e.message})
      })
  }

  useEffect(() => {
    getNewrelic()
  }, [])

  const handleSaveButtonClick = () => {
    setIsUpdating(true)
    updateNewrelicService(applicationId ?? '', newrelicState)
      .then((response) => {
        if (response) {
          notifySuccess('Newrelic state changed successfully...')
        } else {
          notifyError('Newrelic state change failed...')
          setNewrelicState(!newrelicState)
        }
        getNewrelic()
      })
      .catch((error) => {
        notifyError(error.message)
        setNewrelicState(!newrelicState)
      })
      .finally(() => {
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      })
  }

  const handleToggleChange = () => {
    setNewrelicState(!newrelicState)

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='newrelic_tab'>
      <TabHeader heading='Newrelic' />
      <TabBody>
        {error.code !== 0 && error.message !== '' ? (
          <div className='alert alert-danger d-flex align-items-center'>
            {[32610, 32611, 71504].includes(Number(error.code)) ? (
              <span className='fs-6'>
                {error.code === 32610 ? (
                  <>
                    Please add Account API Key in Newrelic Integration for application monitoring to
                    work. <Link to='/integrations/newrelic/key/'>Setup Account Key.</Link>
                  </>
                ) : error.code === 32611 ? (
                  'Please enable Newrelic on the Server: Server -> Monitoring -> Newrelic.'
                ) : (
                  <>
                    Please integrate Newrelic with Cloudphant by adding API Key. Account API Key is
                    required for application monitoring.{' '}
                    <Link to='/integrations/newrelic/key/'>Integrate Newrelic.</Link>
                  </>
                )}
              </span>
            ) : (
              <span className='fs-6'>Newrelic Error: {error.message}</span>
            )}
          </div>
        ) : (
          <div className='row mb-10'>
            <div className='col-lg-12 mt-3'>
              <div className='row align-items-center'>
                <div className='col-lg-2'>Newrelic State:</div>
                <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={newrelicState}
                    onChange={handleToggleChange}
                    style={{
                      backgroundColor: newrelicState ? 'green' : 'grey',
                      borderColor: newrelicState ? 'green' : 'grey',
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <button
                    type='button'
                    className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                    onClick={handleSaveButtonClick}
                    disabled={!isSaveButtonActive || isUpdating}
                  >
                    <span className='indicator-label'>
                      {isUpdating ? 'Please wait . . .' : 'Save'}
                    </span>
                    {isUpdating && (
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </TabBody>
    </div>
  )
}

export default Newrelic
