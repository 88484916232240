/* eslint-disable */
import React from 'react'
import {Link, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Firewall from './components/Firewall'
import Fail2Ban from './components/Fail2Ban'
import Tabs from '../../../../../utils/Tabs'

const Security = () => {
  const {serverId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <div className='card mb-5 mb-xl-10' id='dc_security_view'>
            <div className='d-flex overflow-auto'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Security</h3>
                </div>
              </div>
            </div>
            <div className='d-flex pt-3'>
              <Tabs
                tabs={[
                  {name: 'Firewall', path: `/server/${serverId}/security/firewall`},
                  {name: 'Fail2Ban', path: `/server/${serverId}/security/fail2ban`},
                ]}
              />
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path='firewall' element={<Firewall />} />
        <Route path='fail2ban' element={<Fail2Ban />} />
      </Route>

      <Route index element={<Navigate to='firewall' />} />
    </Routes>
  )
}

export default Security
