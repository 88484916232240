import {KTCard} from '../../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {DomainEditModal} from './components/domain-add-edit-model/DomainEditModal'
import {DomainsListHeader} from './components/table/header/DomainsListHeader'
import {DomainsTable} from './components/table/DomainsTable'

const DomainsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <DomainsListHeader />
        <DomainsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <DomainEditModal />}
    </>
  )
}

const DomainsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DomainsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DomainsListWrapper}
