import {useState} from 'react'

type DeleteModalProps = {
  title: string
  content: string
  deleteFunction: (deleteFromCloudflare: boolean) => void
  setIsOpen: (isOpen: boolean) => void
}

const DomainDeleteModal = ({title, content, deleteFunction, setIsOpen}: DeleteModalProps) => {
  const [deleteCloudflare, setDeleteCloudflare] = useState<boolean>(false)

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_subscription_delete'
        role='dialog'
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{title}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>

            <div className='modal-body text-start'>
              <div className='mb-5'>
                <p className='text-muted'>{content}</p>
              </div>
              <div style={{minHeight: '2vh'}}></div>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input me-2'
                  type='checkbox'
                  value=''
                  id='flexCheckChecked'
                  checked={deleteCloudflare}
                  onChange={() => setDeleteCloudflare((v) => !v)}
                />
                <label className='text-muted'>Delete from cloudflare as well?</label>
              </div>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={() => setIsOpen(false)}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  deleteFunction(deleteCloudflare)
                  setIsOpen(false)
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default DomainDeleteModal
