import {KTCard} from '../../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {StorageEditModal} from './components/storage-add-edit-modal/StorageEditModal'
import {StoragesListHeader} from './components/table/header/StoragesListHeader'
import {StoragesTable} from './components/table/StoragesTable'
import {QueryResponseProvider} from './core/QueryResponseProvider'

const StoragesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <StoragesListHeader />
        <StoragesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <StorageEditModal />}
    </>
  )
}

const StoragesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StoragesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StoragesListWrapper}
