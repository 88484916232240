import {initialProject, Project} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {ProjectsListLoading} from '../ProjectsListLoading'
import {createProject, updateProject} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {notifyError, notifySuccess} from '../../../../../utils/NotifyUtils'

type Props = {
  isProjectLoading: boolean
  project: Project
}

const editProjectSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Name is required'),
  description: Yup.string().max(2000, 'Maximum 2000 symbols'),
})

const ProjectEditModalForm: FC<Props> = ({project, isProjectLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [projectForEdit] = useState<Project>({
    ...project,
    name: project.name || initialProject.name,
    description: project.description || initialProject.description,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: projectForEdit,
    validationSchema: editProjectSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (isNotEmpty(values.project_id)) {
        updateProject(values)
          .then((data) => {
            notifySuccess('Project updation successful')
          })
          .catch((error) => {
            notifyError(`Project updation failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      } else {
        await createProject(values)
          .then((data) => {
            notifySuccess('Project addition successful')
          })
          .catch((error) => {
            notifyError(`Project addition failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_project_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_project_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_project_header'
          data-kt-scroll-wrappers='#kt_modal_add_project_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Project Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isProjectLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Description</label>
            {/* end::Label */}

            {/* begin::Text */}
            <textarea
              placeholder='Write something about the project'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isProjectLoading}
            ></textarea>
            {/* end::Text */}
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-projects-modal-action='cancel'
            disabled={formik.isSubmitting || isProjectLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-projects-modal-action='submit'
            disabled={
              isProjectLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isProjectLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isProjectLoading) && <ProjectsListLoading />}
    </>
  )
}

export {ProjectEditModalForm}
