/* eslint-disable */
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import BasicData from './components/BasicData'
import Monit from './components/Monit'
import Tabs from '../../../../../utils/Tabs'
import Newrelic from './components/Newrelic'
import Alerting from './components/Alerts'

const Monitoring = () => {
  const {serverId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='dc_monitoring_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Monitoring</h3>
                  </div>
                </div>
              </div>
              <div className='d-flex pt-3'>
                <Tabs
                  tabs={[
                    {name: 'Basic Data', path: `/server/${serverId}/monitoring/basic-data`},
                    {name: 'Monit', path: `/server/${serverId}/monitoring/monit`},
                    {name: 'Alerts', path: `/server/${serverId}/monitoring/alerts`},
                    {name: 'Newrelic', path: `/server/${serverId}/monitoring/newrelic`},
                  ]}
                />
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route path='basic-data' element={<BasicData />} />
        <Route path='monit' element={<Monit />} />
        <Route path='alerts' element={<Alerting />} />
        <Route path='newrelic' element={<Newrelic />} />
        <Route index element={<Navigate to='basic-data' />} />
      </Route>
    </Routes>
  )
}

export default Monitoring
