import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {RecordEditModalForm} from './RecordEditModalForm'
import {getRecordById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {initialRecord} from '../../core/_models'

const RecordEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: record,
    error,
  } = useQuery(
    `${'records-list'}-record-${itemIdForUpdate}`,
    () => {
      return getRecordById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <RecordEditModalForm isRecordLoading={isLoading} record={initialRecord} />
  }

  if (!isLoading && !error && record) {
    return <RecordEditModalForm isRecordLoading={isLoading} record={record} />
  }

  return null
}

export {RecordEditModalFormWrapper}
