import axios, {AxiosResponse} from 'axios'
import {Record, RecordsQueryResponse} from './_models'
import {ID, Response} from '../../../../../../../../../_metronic/helpers'
import {ApiResponse} from '../../../../../../../auth'
const API_URL = process.env.REACT_APP_THEME_API_URL
const CLOUDFLARE_INTEGRATION_URL = `${API_URL}/v1/integration/cloudflare`

const getRecords = (domainId?: string): Promise<RecordsQueryResponse> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/dns_records`)
    .then((d: AxiosResponse<RecordsQueryResponse>) => d.data)
}

const getRecordById = (domainId: ID, recordId: ID): Promise<Record | undefined> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/dns_records/${recordId}`)
    .then((d: AxiosResponse<Response<Record>>) => d.data)
    .then((d: Response<Record>) => d.data)
}

const createRecord = async (record: Record, domainId?: ID) => {
  const response = await axios.post<ApiResponse>(
    `${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/dns_records`,
    record
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const deleteRecord = async (domainId: ID, recordId: ID) => {
  const response = await axios.delete<ApiResponse>(
    `${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/dns_records/${recordId}`
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const updateRecord = async (domainId: ID, recordId: ID, record: Record) => {
  const response = await axios.put<ApiResponse>(
    `${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/dns_records/${recordId}`,
    record
  )

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

export {getRecords, getRecordById, createRecord, updateRecord, deleteRecord}
