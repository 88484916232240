import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation, useParams} from 'react-router'
import {capitalize} from 'lodash'

import {getCloudflareDomainById} from '../domains-list/core/_requests'
import {CloudflareDomain} from '../domains-list/core/_models'

const DomainHeader: React.FC = () => {
  const location = useLocation()
  const {cloudflareDomainId} = useParams()

  const [cloudflareDomainMetadata, setCloudflareDomainMetadata] = useState<CloudflareDomain>()

  useEffect(() => {
    getCloudflareDomainById(cloudflareDomainId ?? '').then((resp) => {
      setCloudflareDomainMetadata(resp)
    })
  }, [])

  return (
    <div className='card mb-2 mb-xl-4'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-60px symbol-lg-80px symbol-fixed position-relative'>
              <img
                src='https://www.vectorlogo.zone/logos/cloudflare/cloudflare-icon.svg'
                alt='Metronic'
              />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-column align-items-start'>
                  <p className='text-gray-800 fs-2 fw-bolder'>{cloudflareDomainMetadata?.domain}</p>
                  <p className='text-gray-400 fw-bolder'>
                    Status: {capitalize(cloudflareDomainMetadata?.status)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                  '/integrations/cloudflare/domain/' + cloudflareDomainId + '/records'
                    ? 'active'
                    : '')
                }
                to={`/integrations/cloudflare/domain/${cloudflareDomainId}/records`}
              >
                Records
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname ===
                    '/integrations/cloudflare/domain/' + cloudflareDomainId + '/ssl' && 'active')
                }
                to={`/integrations/cloudflare/domain/${cloudflareDomainId}/ssl`}
              >
                SSL
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/\/cloudflare\/domain\/[\w-]+\/speed\//.test(location.pathname) ? 'active' : '')
                }
                to={`/integrations/cloudflare/domain/${cloudflareDomainId}/speed`}
              >
                Speed
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/\/cloudflare\/domain\/[\w-]+\/caching\//.test(location.pathname)
                    ? 'active'
                    : '')
                }
                to={`/integrations/cloudflare/domain/${cloudflareDomainId}/caching`}
              >
                Caching
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {DomainHeader}
