import {DomainsListSearchComponent} from './DomainsListSearchComponent'
import {DomainsListToolbar} from './DomainsListToolbar'

const DomainsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <DomainsListSearchComponent />
      <div className='card-toolbar'>
        <DomainsListToolbar />
      </div>
    </div>
  )
}

export {DomainsListHeader}
