import React from 'react'

const TabHeader = ({heading}: {heading: string}) => {
  return (
    <div>
      <div className='cursor-pointer'>
        <div className='m-0'>
          <h3 className='fw-bolder m-0'>{heading}</h3>
        </div>
      </div>
      <div className='separator border-light-subtle border-2 mt-8'></div>
    </div>
  )
}

export default TabHeader
