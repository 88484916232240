import axios, {AxiosResponse} from 'axios'
import {Domain, DomainsQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ApiResponse} from '../../../auth'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DOMAIN_URL = `${API_URL}/v1/dns/domain`

const getDomains = (query: string): Promise<DomainsQueryResponse> => {
  return axios
    .get(`${DOMAIN_URL}?${query}`)
    .then((d: AxiosResponse<DomainsQueryResponse>) => d.data)
}

const createDomain = async (domain_name: string) => {
  const response = await axios.post<ApiResponse>(DOMAIN_URL, {domain_name})

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const getDomainById = (id: ID): Promise<Domain | undefined> => {
  return axios
    .get(`${DOMAIN_URL}/${id}`)
    .then((d: AxiosResponse<Response<Domain>>) => d.data)
    .then((d: Response<Domain>) => d.data)
}

const getNameserversByDomain = (domainId: ID): Promise<string[] | undefined> => {
  return axios
    .get(`${DOMAIN_URL}/nameservers?domainId=${domainId}`)
    .then((d: AxiosResponse<Response<string[]>>) => d.data)
    .then((d: Response<string[]>) => d.data)
}

const deleteDomain = (id: ID): Promise<{data: boolean}> => {
  return axios.delete(`${DOMAIN_URL}/${id}`).then((d: AxiosResponse<{data: boolean}>) => d.data)
}

export {getDomains, getDomainById, getNameserversByDomain, createDomain, deleteDomain}
