/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../core/_requests'

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .required('Password is required'),
})

const initialValues = {
  password: '',
  confirmPassword: '',
}

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [resetSuccessful, setResetSuccessful] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const email = searchParams.get('email')
      const resetToken = searchParams.get('token')
      if (!email || !resetToken) {
        setStatus('Wrong reset URL.')
        setSubmitting(false)
        setLoading(false)
        return
      }
      resetPassword(email, resetToken, values.password, values.confirmPassword)
        .then((data) => {
          setResetSuccessful(true)
        })
        .catch((error) => {
          setStatus(`Password Reset Failed: ${error.message}`)
        })
        .finally(() => {
          setSubmitting(false)
          setLoading(false)
        })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
      </div>
      <div className='row g-3 mb-9'></div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {resetSuccessful && (
        <div className='alert alert-success d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column'>
            <span>
              Password Reset Successful!{' '}
              <Link to='/auth' className='link-primary'>
                Sign in
              </Link>
            </span>
          </div>
        </div>
      )}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Password</label>
        <input
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          type='password'
          name='password'
          autoComplete='off'
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.password}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          placeholder='Re-enter Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'></div>
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Reset</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Remember your password?{' '}
        <Link to='/auth' className='link-primary'>
          Sign in
        </Link>
      </div>
    </form>
  )
}
