import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {ProjectEditModal} from './components/project-add-edit-modal/ProjectEditModal'
import {ProjectsListHeader} from './components/table/header/ProjectsListHeader'
import {ProjectsTable} from './components/table/ProjectsTable'
import {QueryResponseProvider} from './core/QueryResponseProvider'

const ProjectsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ProjectsListHeader />
        <ProjectsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ProjectEditModal />}
    </>
  )
}

const ProjectsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProjectsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProjectsListWrapper}
