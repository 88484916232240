import {useListView} from '../../../core/ListViewProvider'
import {GitsListSearchComponent} from './GitsListSearchComponent'
import {GitsListToolbar} from './GitsListToolbar'

const GitsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <GitsListSearchComponent />
      <div className='card-toolbar'>
        <GitsListToolbar />
      </div>
    </div>
  )
}

export {GitsListHeader}
