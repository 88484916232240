import React from 'react'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import KnowledgeBaseAccordion from '../../common/knowledgebase/KnowledgeBaseAccordion'
import {KnowledgeBaseAccordionProps} from '../../common/knowledgebase/KnowledgeBaseAccordionProps'

function ApplicationPageTips() {
  const location = useLocation()

  const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBaseAccordionProps>()
  const [hasKnowledgeBase, setHasKnowledgeBase] = useState<boolean>(false)

  const setKnowledgeBaseData = (knowledgeBase: KnowledgeBaseAccordionProps) => {
    setKnowledgeBase(knowledgeBase)
    setHasKnowledgeBase(true)
  }

  useEffect(() => {
    if (location.pathname.includes('/access-details')) {
      setKnowledgeBaseData({
        body: `Users can see their database credentials (MariaDB in our case) and SFTP credentials.
          <br/>
          <br/>
          <ol>
            <li><b>MariaDB Credentials</b>: These should be used during wordpress installation on the step where database credentials are asked for</li>
            <li><b>SFTP Credentials</b>: These credentials can be used for file upload using any SFTP client</li>
          </ol>
        `,
      })
    } else if (location.pathname.includes('/domain-manager/domain')) {
      setKnowledgeBaseData({
        body: `Users can add a custom domain to there website on this page.
        <br/>
        <br/>
        <b>Steps:</b>
        <ol>
          <li><b>Add Domain</b>: Add your custom domain in the input field and click on add domain</li>
          <li><b>A Record Addition</b>: After adding your domain, you would see a A record (IP address) appear below the input field. Add this IP as the A record on your domains DNS provider</li>
          <li><b>A Record Verification</b>: Wait for the A record to be propogated and our system to verify it.</li>
          <li><b>Success Message</b>: Once your A record is verified by our system, you would see a success message instead of A record message.</li>
        </ol>
      `,
      })
    } else if (location.pathname.includes('/domain-manager/ssl')) {
      setKnowledgeBaseData({
        body: `Users can add ssl certificates to the website from this page.
        <br/>
        <br/>
        <b>Prerequisites:</b>
        <ol>
          <li><b>Custom Domain</b>: User should have already added a custom domain and its A record should have been verified, on the domain tab</li>
        </ol>
        <br/> Once the domain is added and verified, you would see an <b>Activate SSL</b> button. Click that button and the ssl certificates would be installed for your custom domain.
      `,
      })
    } else if (location.pathname.includes('/staging')) {
      setKnowledgeBaseData({
        body: `Users can create a staging website here.
        <br/>
        <br/>
        <b>What is a Staging website?</b>
        <br/>
        A staging website is a clone of your live website. Staging website can be used to test changes and updates first. Once confirmed, the changes can be moved to live website to avoid breakage and downtime.
        `,
      })
    } else if (location.pathname.includes('/backup-restore/backup')) {
      setKnowledgeBaseData({
        body: 'Users can create a <b>full file and database backup</b> of the website from here',
      })
    } else if (location.pathname.includes('/backup-restore/restore')) {
      setKnowledgeBaseData({
        body: 'Users can see the list of backups with timestamps here. Users can also restore or delete a backup from the server. Also, the file and database backup files can be downloaded',
      })
    } else if (location.pathname.includes('/page-cache')) {
      setKnowledgeBaseData({
        body: `1. Users can enable or disable page cache from here.
        <br/>
        <br/>
        <b>What is Page Cache?</b>
        <br/>
        When the server accesses data from a storage device (like a hard drive or SSD), it temporarily stores frequently accessed data in the page cache. So, if a request needs that data/page again, it can quickly retrieve it from the cache instead of having to go all the way back to the slower storage device. 
        In simple terms, the <mark>page cache helps speed up your computer by keeping important or frequently used data close at hand, making it quicker to access when needed</mark>.
        <br/>
        <br/>
        2. Users can also purge the page cache from here. User can enter the urls in the input field and purge those selective urls or he/she can purge the complete page cache as well
        <br/>
        <br/>
        <b>What is the need to purge?</b>
        <br/>
        As mentioned above, enabling this would store a copy of the data/page in cache. So, if you make a change to any of the page, it might not reflect immediately as the request would temporarily come from page cache. So, in case you want the change to reflect immediately, you would need to purge the url on which the change has been done.
        `,
      })
    } else if (location.pathname.includes('/object-cache')) {
      setKnowledgeBaseData({
        body: `1. Users can enable or disable object cache from here.
        <br/>
        <br/>
        <b>What is Object Cache?</b>
        <br/>
        Each time someone visits a webpage, the web server needs to fetch various pieces of data to display that page, such as images, text, or database information.
        When a webpage is requested, instead of fetching all the necessary data from its original sources (like a database or storage), it first checks its object cache. If the required data is already in the cache, it can be quickly retrieved and sent to the visitor's browser. This saves time and resources compared to fetching the data from its original source every time.        <br/>
        <br/>
        <b>What is the difference between Object and Page Cache?</b>
        <br/>
        Page cache is the entire rendered html output for a page. It's useful for serving static content like a WordPress post. Object cache is often the resource-heavy pieces that make up a page. For example, When you use WP_Query each result would be stored in object cache.
        <br/>
        <br/>
        2. Users can also purge the whole object cache
        <br/>
        <br/>
        <b>What is the need to purge?</b>
        <br/>
        As mentioned above, enabling this would store a copy of the data/objects in cache. So, if you make a change to any of data stored in object cache, it might not reflect immediately as the request would temporarily come from the cache. So, in case you want the change to reflect immediately, you would need to purge.
        `,
      })
    } else if (location.pathname.includes('/browser-cache')) {
      setKnowledgeBaseData({
        body: `1. Users can enable or disable browser cache from here.
        <br/>
        <br/>
        <b>What is Browser Cache?</b>
        <br/>
        Browser cache is a feature that temporarily stores web resources like HTML pages, images, and scripts on your computer, allowing faster loading times for frequently visited websites by retrieving content locally rather than downloading it repeatedly from the internet. While it enhances browsing speed and reduces server load, it's important to occasionally clear the cache to ensure you're viewing the most up-to-date versions of web pages.        <br/>
        <br/>
        2. Users can set Browser Cache TTL.
        <br/>
        <br/>
        <b>What is Browser Cache TTL?</b>
        <br/>
        Browser Cache TTL, or Time-To-Live, is a setting determining how long web content remains in a user's browser cache before it's considered outdated and refreshed from the server. It's set by developers to balance performance and freshness, controlling how often browsers retrieve updated content. Shorter TTLs ensure users see recent updates but may increase server load, while longer TTLs reduce server requests but risk displaying outdated content. Adjusting TTL involves finding the right balance between responsiveness and efficiency for optimal user experience.
        `,
      })
    } else if (location.pathname.includes('/opcache')) {
      setKnowledgeBaseData({
        body: `Users can purge the opcache from this page
        <br/>
        <br/>
        <b>What is opcache?</b>
        <br/>
        When you run a PHP script (a popular programming language used for websites and web applications), the server needs to read the script, translate it into a language that the computer understands (compilation), and then execute it. This process takes time, especially if the script is large or complex.
        <br/>
        OPCache stores pre-compiled versions of PHP scripts in memory after they've been translated into a format that the computer can execute directly. So, the next time the server needs to run that script, instead of re-translating and executing it from scratch, it can simply use the pre-compiled version stored in OPCache. This makes the execution of PHP scripts much faster because it skips the translation step and goes straight to execution.
        <br/>
        <br/>
        <b>What is the need to purge?</b>
        <br/>
        As mentioned above, enabling this would store compiled versions of php scripts. So, if you want any php change to take affect immediately, you will have to purge this cache so that the scripts are compiled again and the newer version is executed.
        `,
      })
    } else if (location.pathname.includes('/edge-cache')) {
      setKnowledgeBaseData({
        body: `Users can enable or disable edge cache for the website from here.
        <br/>
        <br/>
        <b>Prerequisites</b><br/>
        1. Your domain should be added in cloudflare.<br/>
        2. You should have integrated your cloudflare account with the required permissions with us.
        <br/>
        <br/>
        <b>What is edge cache?</b>
        <br/>
        Edge caching is like distributing copies of frequently accessed web content to servers that are closer to the users. These servers are located at the edges of the network, closer to where the users are accessing the internet. So, when someone requests a webpage or any other content, instead of fetching it from a distant server, the request can be quickly fulfilled by the nearby edge cache server significantly reducing the load time.
        `,
      })
    } else if (location.pathname.includes('/database/cleanup')) {
      setKnowledgeBaseData({
        body: `Users can do a database cleanup from this page.
        <br/>
        <br/>
        This page would list down all the <b>unnecessary database contents</b> which are currently present in your wordpress database. It would also give an option to choose and clean up these unwanted contents.
        <br/>
        <br/>
        <mark><b>Note</b></mark>: Do take a backup before doing any kind of cleanup so that you can prevent data loss by mistake. 
        `,
      })
    } else if (location.pathname.includes('/database/repair')) {
      setKnowledgeBaseData({
        body: `Users can do a database optimization and repair from this page.
        <br/>
        <br/>
        <b>What is database optimization?</b>
        <br/>
        Database Optimization copies the table to remove unused space, analyzes the table, stores the key distribution for a table, and defragments the data file. If the table is MyISAM, ANALYZE TABLE is also performed to update index statistics for the Query Optimizer. Optimizing tables can reduce resource consumption, lower costs, and improve server performance.
        <br/>
        <br/>
        <b>What is database repair?</b>
        <br/>
        Database Repair fixes table corruption issues, such as open file handle counts and resolution of rows with variable length data. It only supports selected storage engines and requires privileges like SELECT and INSERT. Repairing tables can prevent data loss and ensure database stability.
        `,
      })
    } else if (location.pathname.includes('optimizations/webp')) {
      setKnowledgeBaseData({
        body: `Users can enable webp image optimization from this page. Users can also control what quality level should be used for optimizations and whether metadata should be removed or not.
        <br/>
        <br/>
        Enabling this optimization would <b>convert the website images to webp format</b> and would serve these webp images on the websites instead of other formats which can increase the performance manifold.
        <br/>
        <br/>
        <b>What is webp?</b>
        <br/>
        WebP is a type of image format, just like JPEG, PNG, or GIF. However, what makes WebP special is that it's <b>designed specifically for use on the web</b>. It was created by Google to make images load faster and take up less space on websites.
        `,
      })
    } else if (location.pathname.includes('optimizations/html-minify')) {
      setKnowledgeBaseData({
        body: `Users can enable or disable html minification from this page.
        <br/>
        <br/>
        <b>What is html minification?</b>
        <br/>
        HTML minification is the process of <mark>removing unnecessary characters from HTML code without altering its functionality</mark>. These unnecessary characters typically include white spaces, comments, and newline characters. Minifying HTML reduces its file size, making it load faster in web browsers.
        `,
      })
    } else if (location.pathname.includes('/logs')) {
      setKnowledgeBaseData({
        body: `Users can view <b>Nginx access and error logs</b> from here. Note that one can only view last 1000 lines of these logs.
        <br/>
        <br/>
        One can also filter and highlight the logs fetched.
        <ol>
        <li><b>Filter</b>: Filter functionality would filter the lines containining the search pattern.</li>
        <li><b>Highlight</b>: Highlight functionality would highlight the search pattern in the logs.</li>
        `,
      })
    } else if (location.pathname.includes('/crons')) {
      setKnowledgeBaseData({
        body: `Users can view and add <b>crons</b> from here
        <br/>
        <br/>
        To add a cron
        <ol>
        <li>Click on the <b>Create Cron</b> button.</li>
        <li>Add cron name and description.</li>
        <li>Under Cron When dropdown, you can select any of the predefined schedules for your cron or select custom.</li>
        <li>For custom, you can use this <a href="https://crontab.cronhub.io/" target=”_blank”>tool</a> to create the schedule.</li>
        <li> Finally add the command you want to execute in Command input and submit.</li>
        </ol>
        `,
      })
    } else if (location.pathname.includes('/security')) {
      setKnowledgeBaseData({
        body: `Users can scan and view <b>potential malwares</b> from here.`,
      })
    } else {
      setHasKnowledgeBase(false)
    }
  }, [location.pathname])

  return (
    <>
      {hasKnowledgeBase && (
        <div>
          <KnowledgeBaseAccordion body={String(knowledgeBase?.body)} />
        </div>
      )}
    </>
  )
}

export default ApplicationPageTips
