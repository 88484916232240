import {initialGit, Git, availablePlatforms} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {capitalize} from 'lodash'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {GitsListLoading} from '../GitsListLoading'
import {createGit, updateGit} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {GitProvider} from '../../core/_models'

type Props = {
  isGitLoading: boolean
  git: Git
}

const editGitSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Name is required'),
  description: Yup.string().max(2000, 'Maximum 2000 symbols'),
  platform: Yup.string().required('Git provider is required'),
  api_key_val: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(200, 'Maximum 200 symbols')
    .required('Api key is required'),
  username: Yup.string(),
})

const GitEditModalForm: FC<Props> = ({git, isGitLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [selectedGitProvider, setSelectedGitProvider] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {refetch} = useQueryResponse()

  const [gitForEdit] = useState<Git>({
    ...git,
    name: git.name || initialGit.name,
    description: git.description || initialGit.description,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleGitProviderChange = (event: React.MouseEvent<HTMLImageElement>, provider: string) => {
    formik.setFieldValue('platform', provider)
    setSelectedGitProvider(provider)
  }

  const formik = useFormik({
    initialValues: gitForEdit,
    validationSchema: editGitSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setIsSubmitting(true)
      if (values.api_key_val) {
        values.api_key = {api_key: values.api_key_val}
        if (values.username) {
          values.api_key['username'] = values.username
        }
      }
      if (isNotEmpty(values.id)) {
        updateGit(values)
          .then((data) => {
            notifySuccess('Git updation successful')
          })
          .catch((error) => {
            notifyError(`Git updation failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setIsSubmitting(false)
            cancel(true)
          })
      } else {
        createGit(values)
          .then((data) => {
            notifySuccess('Git addition successful')
          })
          .catch((error) => {
            notifyError(`Git addition failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setIsSubmitting(false)
            cancel(true)
          })
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_git_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_git_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_git_header'
          data-kt-scroll-wrappers='#kt_modal_add_git_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            <input
              placeholder='Git Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {'is-valid': formik.touched.name && !formik.errors.name}
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isGitLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Description</label>
            <textarea
              placeholder='Write something about the git'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {
                  'is-invalid': formik.touched.description && formik.errors.description,
                },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isGitLoading}
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>

          {!itemIdForUpdate && (
            <div
              className='row w-100 mb-5'
              data-kt-buttons='true'
              data-kt-buttons-target='.form-check-image, .form-check-input'
            >
              <label className='required fw-bold fs-6 mb-2'>Git Provider</label>
              {availablePlatforms?.map((option: any) => (
                <div className='col' key={option}>
                  <label
                    className={`form-check-image border m-2 rounded-2 shadow-sm
${selectedGitProvider === option && 'active'}`}
                  >
                    <div className='form-check-wrapper d-flex flex-column align-items-center mb-0'>
                      <img
                        src={`/media/gitproviders/${option}.png`}
                        alt={option}
                        className='p-6'
                        onClick={(e) => handleGitProviderChange(e, option)}
                      />
                      <p className='fs-6'>{capitalize(option)}</p>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          )}

          {(git.platform === GitProvider.GITHUB ||
            selectedGitProvider === GitProvider.GITHUB ||
            git.platform === GitProvider.GITLAB ||
            selectedGitProvider === GitProvider.GITLAB) && (
            <>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Access Token</label>
                <input
                  placeholder='Access Token'
                  {...formik.getFieldProps('api_key_val')}
                  type='text'
                  name='api_key_val'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid': formik.touched.api_key_val && formik.errors.api_key_val,
                    },
                    {
                      'is-valid': formik.touched.api_key_val && !formik.errors.api_key_val,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isGitLoading}
                />
                {formik.touched.api_key_val && formik.errors.api_key_val && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.api_key_val}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {(git.platform === GitProvider.BITBUCKET ||
            selectedGitProvider === GitProvider.BITBUCKET) && (
            <>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>Username</label>
                <input
                  placeholder='BitBucket Username'
                  {...formik.getFieldProps('username')}
                  type='text'
                  name='username'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid': formik.touched.username && formik.errors.username,
                    },
                    {
                      'is-valid': formik.touched.username && !formik.errors.username,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isGitLoading}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.username}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>App Password</label>
                <input
                  placeholder='App Password'
                  {...formik.getFieldProps('api_key_val')}
                  type='text'
                  name='api_key_val'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid': formik.touched.api_key_val && formik.errors.api_key_val,
                    },
                    {
                      'is-valid': formik.touched.api_key_val && !formik.errors.api_key_val,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isGitLoading}
                />
                {formik.touched.api_key_val && formik.errors.api_key_val && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.api_key_val}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-gits-modal-action='cancel'
            disabled={formik.isSubmitting || isGitLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-gits-modal-action='submit'
            disabled={
              isGitLoading || isSubmitting || !formik.isValid || !formik.touched || !formik.dirty
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isGitLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isGitLoading) && <GitsListLoading />}
    </>
  )
}

export {GitEditModalForm}
