/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUser, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {AuthModel} from '../core/_models'
import Recaptcha from '../../../utils/Recaptcha'
import {getDefaultOrganization} from '../../organizations/organizations-list/core/_requests'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()

  const setOrganization = (organizationId: string) => {
    localStorage.setItem('cp-current-organization', organizationId)
  }

  useEffect(() => {
    const urlAuthToken = searchParams.get('access_token')

    if (urlAuthToken) {
      const authObj: AuthModel = {
        access_token: urlAuthToken,
      }

      saveAuth(authObj)

      getUser(authObj.access_token)
        .then((user) => {
          setCurrentUser(user)

          getDefaultOrganization().then((organization) => {
            setOrganization(organization?.id ?? '')
          })
        })
        .catch((error) => {
          console.log('invalid access token!')
        })
    }
  }, [])

  const loginHandler = async (
    values: {email: string; password: string},
    setStatus: (x: string) => void,
    setSubmitting: (x: boolean) => void,
    recaptchaToken: string
  ) => {
    try {
      const authObj = await login(values.email, values.password, recaptchaToken)
      saveAuth(authObj)

      const user = await getUser(authObj.access_token)
      setCurrentUser(user)

      const organization = await getDefaultOrganization()
      setOrganization(organization?.id ?? '')
    } catch (error) {
      if (error instanceof Error) {
        saveAuth(undefined)
        setStatus(error?.message)

        setSubmitting(false)
        setLoading(false)
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      await (window as any).grecaptcha.ready(async () => {
        const token = await (window as any).grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_KEY,
          {action: 'submit'}
        )
        loginHandler(values, setStatus, setSubmitting, token)
      })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* TODO: move to common component with registration page */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Your Social Accounts</div>
      </div>
      <div className='row g-3 mb-9'>
        <div className='col-md-12'>
          <a
            href={process.env['REACT_APP_API_URL'] + '/v1/auth/google'}
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
        </div>
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
      </div>
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <Recaptcha />

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/register' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )
}
