import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {getDatabaseRepairList, repairDatabase} from '../../../../applications-list/core/_requests'
import {notifySuccess, notifyError} from '../../../../../../utils/NotifyUtils'

const DatabaseRepair: React.FC = () => {
  const {applicationId} = useParams<{applicationId: string}>()
  const [tableData, setTableData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedTables, setSelectedTables] = useState<string[]>([])
  const [isOptimizing, setIsOptimizing] = useState(false)
  const [isRepairing, setIsRepairing] = useState(false)

  useEffect(() => {
    fetchDatabaseRepairList()
  }, [])

  const fetchDatabaseRepairList = async () => {
    try {
      const response = await getDatabaseRepairList(applicationId ?? '')
      if (response?.data && Array.isArray(response.data)) {
        setTableData(response.data)
      } else {
        throw new Error('Invalid data format received from server')
      }
    } catch (error) {
      notifyError('Failed to fetch database repair list')
    } finally {
      setIsLoading(false)
    }
  }

  const handleRepair = async () => {
    setIsRepairing(true)
    setIsOptimizing(false)
    try {
      const response = await repairDatabase(applicationId ?? '', 'REPAIR', selectedTables)
      handleApiResponse(response, 'Database repaired successfully')
    } catch (error) {
      notifyError('Failed to repair database')
    }
  }

  const handleOptimize = async () => {
    setIsOptimizing(true)
    setIsRepairing(false)
    try {
      const response = await repairDatabase(applicationId ?? '', 'OPTIMIZE', selectedTables)
      handleApiResponse(response, 'Database optimized successfully')
    } catch (error) {
      notifyError('Failed to optimize database')
    }
  }

  const handleApiResponse = (response: any, successMessage: string) => {
    if (response.statusCode === 201 && response.data) {
      notifySuccess(successMessage)
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } else {
      notifyError('Failed to perform operation')
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, tableName: string) => {
    const isChecked = event.target.checked
    if (isChecked) {
      setSelectedTables([...selectedTables, tableName])
    } else {
      setSelectedTables(selectedTables.filter((table) => table !== tableName))
    }
  }

  const formatSize = (size: number): string => {
    if (size / 1024 >= 1) {
      size /= 1024
      if (size / 1024 >= 1) {
        size /= 1024
        if (size / 1024 >= 1) {
          size /= 1024
          return size.toFixed(2) + ' GB'
        }
        return size.toFixed(2) + ' MB'
      }
      return size.toFixed(2) + ' KB'
    }
    return size.toFixed(2) + ' Bytes'
  }

  return (
    <div className='container-fluid'>
      <div className='py-5'>
        <table className='table table-hover table-rounded table-striped border gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
              <th>Name</th>
              <th>Rows</th>
              <th>Size</th>
              <th>Status</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              tableData.map(
                (table, index) =>
                  table &&
                  table.Name &&
                  table.Rows &&
                  table.Data_length &&
                  table.Index_length &&
                  table.Msg_text && (
                    <tr key={index}>
                      <td>{table.Name}</td>
                      <td>{table.Rows}</td>
                      <td>
                        {formatSize(parseInt(table.Data_length) + parseInt(table.Index_length))}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            table.Msg_text === 'OK' ? 'badge-success' : 'badge-danger'
                          }`}
                        >
                          {table.Msg_text}
                        </span>
                      </td>
                      <td>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={table.Name}
                            checked={selectedTables.includes(table.Name)}
                            onChange={(event) => handleCheckboxChange(event, table.Name)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
              )}
          </tbody>
        </table>
        {isLoading && (
          <div style={{textAlign: 'center', padding: '10px'}}>
            <span style={{color: '#6c757d', fontWeight: 'bold'}}>Fetching details...</span>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </div>
        )}
      </div>
      <div className='mt-0 d-flex'>
        <button
          className={`btn ${isOptimizing ? 'btn-warning' : 'btn-primary'} me-10`}
          onClick={handleOptimize}
          disabled={selectedTables.length === 0 || isLoading || isRepairing || isOptimizing}
        >
          {isOptimizing ? 'Please wait . . .' : 'Optimize'}
        </button>
        <button
          className={`btn ${isRepairing ? 'btn-warning' : 'btn-primary'} me-10`}
          onClick={handleRepair}
          disabled={selectedTables.length === 0 || isLoading || isRepairing || isOptimizing}
        >
          {isRepairing ? 'Please wait . . .' : 'Repair'}
        </button>
      </div>
      <div className='my-5'></div>
    </div>
  )
}

export default DatabaseRepair
