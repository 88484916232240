import {Column} from 'react-table'
import {ApplicationActionsCell} from './ApplicationActionsCell'
import {ApplicationCustomHeader} from './ApplicationCustomHeader'
import {ApplicationNameCell} from './ApplicationNameCell'
import {Application} from '../../../core/_models'
import {ApplicationServerCell} from './ApplicationServerCell'
import {ApplicationProjectCell} from './ApplicationProjectCell'

const applicationsColumns: ReadonlyArray<Column<Application>> = [
  /*
  {
    Header: (props) => <ApplicationSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => (
      <ApplicationSelectionCell id={props.data[props.row.index].application_id} />
    ),
  }, */
  {
    Header: (props) => (
      <ApplicationCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <ApplicationNameCell application={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ApplicationCustomHeader tableProps={props} title='Server' className='min-w-125px' />
    ),
    id: 'server',
    Cell: ({...props}) => <ApplicationServerCell application={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ApplicationCustomHeader tableProps={props} title='Project' className='min-w-125px' />
    ),
    id: 'project',
    Cell: ({...props}) => <ApplicationProjectCell application={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ApplicationCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ApplicationActionsCell id={props.data[props.row.index].application_id} />
    ),
  },
]

export {applicationsColumns}
