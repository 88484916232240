import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ApplicationsListWrapper} from './applications-list/ApplicationsList'
import KnowledgeBaseAccordion from '../common/knowledgebase/KnowledgeBaseAccordion'

const applicationsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Website Management',
    path: '/applications/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ApplicationsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={applicationsBreadcrumbs}>Website List</PageTitle>
              <KnowledgeBaseAccordion
                body={`The websites list page provides the following details
                <ol>
                  <li><b>Website Name</b>: The Website names assigned to the Website by the user</li>
                  <li><b>Website Server</b>: The server on which the website is hosted</li>
                  <li><b>Website Project</b>: The project to which the website is linked to</li>
                  <li><b>Website Actions</b>: The available Website actions include edit and delete</li>
                </ol>
                The <mark className="bg-primary"><b>Launch Website</b></mark> button is to be used to launch a new website on any of the available servers.`}
              />
              <ApplicationsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/applications/list' />} />
    </Routes>
  )
}

export default ApplicationsPage
