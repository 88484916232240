import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {serverAction} from '../../../core/_requests'
import {ServerActionType, ServerIntermediateState, ServerState} from '../../../core/_models'
import {notifyError} from '../../../../../../utils/NotifyUtils'

type Props = {
  id: ID
  serverState: ServerState
  identifier: string | undefined // TODO: use instanceOption instead, this is temporary and incorrect approach
}

const ServerActionsCell: FC<Props> = ({id, serverState, identifier}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const handleServerRestart = useMutation(() => serverAction(id, ServerActionType.RESTART), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${'servers-list'}-${query}`])
    },
    onError: (error: Error) => {
      notifyError('Server action failed: ' + error.message)
    },
  })
  const handleServerStop = useMutation(() => serverAction(id, ServerActionType.STOP), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${'servers-list'}-${query}`])
    },
    onError: (error: Error) => {
      notifyError('Server action failed: ' + error.message)
    },
  })
  const handleServerStart = useMutation(() => serverAction(id, ServerActionType.START), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${'servers-list'}-${query}`])
    },
    onError: (error: Error) => {
      notifyError('Server action failed: ' + error.message)
    },
  })

  return (
    <>
      <button
        disabled={serverState in ServerIntermediateState}
        onClick={async () => await handleServerRestart.mutateAsync()}
        className='btn btn-sm btn-icon btn-warning mx-1'
      >
        <i className='fa-solid fa-rotate-right'></i>
      </button>
      {identifier != null && (
        <>
          {serverState === ServerState.STARTED ? (
            <button
              disabled={serverState in ServerIntermediateState}
              onClick={async () => await handleServerStop.mutateAsync()}
              className='btn btn-sm btn-icon btn-danger mx-1'
            >
              <i className='fa-sharp fa-solid fa-stop'></i>
            </button>
          ) : (
            <button
              disabled={serverState in ServerIntermediateState}
              onClick={async () => await handleServerStart.mutateAsync()}
              className='btn btn-sm btn-icon btn-success mx-1'
            >
              <i className='fa-solid fa-play'></i>
            </button>
          )}
        </>
      )}
      <button onClick={openEditModal} className='btn btn-sm btn-icon btn-secondary mx-1'>
        <i className='fa-solid fa-pen-to-square'></i>
      </button>
    </>
  )
}

export {ServerActionsCell}
