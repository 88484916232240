import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RecordEditModal} from './components/record-add-edit-model/RecordEditModal'
import {RecordsListHeader} from './components/table/header/RecordsListHeader'
import {RecordsTable} from './components/table/RecordsTable'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {getDomainById} from '../domains-list/core/_requests'
import {Domain, initialDomain} from '../domains-list/core/_models'
import NsRecords from './components/ns-records/NsRecords'

const DomainRecords = () => {
  const [domain, setDomain] = useState<Domain>()
  const {itemIdForUpdate} = useListView()
  const {domainId} = useParams()

  useEffect(() => {
    getDomainById(domainId).then((d) => {
      if (d) setDomain(d)
    })
  }, [])

  if (!domain) {
    return <></>
  }

  return (
    <>
      {domain.domain_status === 'VALIDATION_SUCCESSFUL' ? (
        <KTCard>
          <RecordsListHeader />
          <RecordsTable />
        </KTCard>
      ) : (
        <NsRecords domain={domain} />
      )}
      {itemIdForUpdate !== undefined && <RecordEditModal />}
    </>
  )
}

const DomainRecordsWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DomainRecords />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DomainRecordsWrapper}
