import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation, useParams} from 'react-router'
import {KTSVG} from '../../../../_metronic/helpers'
import {getSSHDetails, getServerWithMetadata} from '../servers-list/core/_requests'
import {
  SSHDetails,
  Server,
  ServerDeploymentJobStatus,
  ServerState,
  ServerUserType,
} from '../servers-list/core/_models'

const ServerHeader: React.FC = () => {
  const location = useLocation()
  const {serverId} = useParams()

  const [sshDetails, setSSHDetails] = useState<SSHDetails>()
  const [serverMetadata, setServerMetadata] = useState<{
    server: Server
    logo: string
    description: string
  }>()

  useEffect(() => {
    getServerWithMetadata(serverId ?? '').then((resp) => {
      setServerMetadata(resp)
    })

    getSSHDetails(serverId ?? '', ServerUserType.ROOT).then((resp) => {
      setSSHDetails(resp)
    })
  }, [])

  return (
    <div className='card mb-2 mb-xl-4'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-60px symbol-lg-80px symbol-fixed position-relative'>
              <img src={serverMetadata?.logo} alt='Metronic' />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1'>{serverMetadata?.server.name}</p>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map007.svg'
                      className='svg-icon-4 me-1'
                    />
                    {serverMetadata?.server.ip}
                  </p>
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    {serverMetadata?.server.region}
                  </p>
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <i
                      className='fa-solid fa-circle me-1'
                      style={{
                        color:
                          serverMetadata?.server.server_state == ServerState.STARTED
                            ? ' #00cc00'
                            : '#fd0909',
                      }}
                    ></i>
                    <span>{serverMetadata?.server.server_state}</span>
                  </p>
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/coding/cod004.svg'
                      className='svg-icon-4 me-2'
                    />
                    <a
                      target='_blank'
                      href={`https://shell.cloudphant.com/?username=${sshDetails?.username}&port=22&hostname=${sshDetails?.server.ip}`}
                    >
                      Web Shell
                    </a>
                  </p>
                  {serverMetadata?.server.deployment_job?.status !==
                    ServerDeploymentJobStatus.POST_SERVER_DEPLOYMENT_OPERATIONS_SUCCESSFUL && (
                    <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                      <span className='spinner-border spinner-border-sm ms-1'></span>
                      &nbsp;Server setup in progress...
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/server/' + serverId + '/ssh' ? 'active' : '')
                }
                to={`/server/${serverId}/ssh`}
              >
                SSH
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/server/' + serverId + '/settings' && 'active')
                }
                to={`/server/${serverId}/settings`}
              >
                Settings
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/server\/[a-fA-F0-9-]+\/monitoring\/.*/.test(location.pathname) && 'active')
                }
                to={`/server/${serverId}/monitoring`}
              >
                Monitoring
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/server\/[a-fA-F0-9-]+\/manage\/.*/.test(location.pathname) && 'active')
                }
                to={`/server/${serverId}/manage`}
              >
                Manage
              </Link>
            </li>
            {/*
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/server/' + serverId + '/backups' && 'active')
                }
                to={`#`}
              >
                Backups <span className='badge badge-light-success mx-1'>Soon</span>
              </Link>
            </li>
              */}
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/server/' + serverId + '/services' && 'active')
                }
                to={`/server/${serverId}/services`}
              >
                Services
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/server\/[a-fA-F0-9-]+\/security\/.*/.test(location.pathname) && 'active')
                }
                to={`/server/${serverId}/security`}
              >
                Security
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/server\/[a-fA-F0-9-]+\/optimizations\/.*/.test(location.pathname) &&
                    'active')
                }
                to={`/server/${serverId}/optimizations`}
              >
                Optimizations
              </Link>
            </li>
            {/*
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/server/' + serverId + '/smtp' && 'active')
                }
                to={`#`}
              >
                SMTP <span className='badge badge-light-success mx-1'>Soon</span>
              </Link>
            </li>
            */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ServerHeader}
