import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {notifyError, notifySuccess} from '../../../../utils/NotifyUtils'
import {INewrelicAPIKeys, NewrelicRegion} from './_models'
import {getNewrelicAPIKey, updateNewrelicAPIKey} from './_requests'

const newrelicKeysSchema = Yup.object().shape({
  region: Yup.string().required('Region is required'),
  account_id: Yup.string().required('Account ID is required'),
  user_api_key: Yup.string().required('User key is required'),
  account_api_key: Yup.string(),
})

const initialValues: INewrelicAPIKeys = {
  region: NewrelicRegion.EU,
  account_id: '',
  user_api_key: '',
}

const APIKeyPage: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState('')
  const [showAccountAPIKey, setShowAccountAPIKey] = useState(false)
  const [showUserAPIKey, setShowUserAPIKey] = useState(false)

  const toggleShowAccountAPIKey = () => {
    setShowAccountAPIKey(!showAccountAPIKey)
  }

  const toggleShowUserAPIKey = () => {
    setShowUserAPIKey(!showUserAPIKey)
  }

  useEffect(() => {
    getAPIKey()
  }, [])

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue('region', event.target.value)
    setSelectedRegion(event.target.value)
  }

  const getAPIKey = () => {
    getNewrelicAPIKey().then((resp) => {
      setSelectedRegion(resp?.api_key?.region)

      formik.setValues({
        region: resp?.api_key?.region || initialValues.region,
        account_id: resp?.api_key?.account_id || initialValues.account_id,
        user_api_key: resp?.api_key?.user_api_key || initialValues.user_api_key,
        account_api_key: resp?.api_key?.account_api_key || initialValues.account_api_key,
      })
    })
  }

  const formik = useFormik<INewrelicAPIKeys>({
    initialValues,
    enableReinitialize: true,
    validationSchema: newrelicKeysSchema,
    onSubmit: async (values) => {
      setLoading(true)
      updateNewrelicAPIKey(values)
        .then((data: any) => {
          if (data) {
            formik.setValues({
              region: data?.api_key?.region || initialValues.region,
              account_id: data?.api_key?.account_id || initialValues.account_id,
              user_api_key: data?.api_key?.user_api_key || initialValues.user_api_key,
              account_api_key: data?.api_key?.account_api_key || initialValues.account_api_key,
            })
          }
          notifySuccess('Newrelic keys updated successfully!')
        })
        .catch((error: any) => {
          notifyError('Newrelic keys update failed: ' + error.message)
        })
        .finally(() => {
          setLoading(false)
          getAPIKey()
        })
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        data-bs-target='#kt_newrelic_api_key'
        aria-expanded='true'
        aria-controls='kt_newrelic_api_key'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Newrelic API Details</h3>
        </div>
      </div>

      <div id='kt_newrelic_api_key' className='collapse show'>
        <form autoComplete='off' onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top pb-0 p-9'>
            <div className='row mb-6'>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Data Region</label>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('region')}
                      name='region'
                      value={selectedRegion ?? ''}
                      onChange={handleRegionChange}
                      style={{maxWidth: '450px'}}
                    >
                      <option value=''>Select an option</option>
                      <option key={NewrelicRegion.EU} value={NewrelicRegion.EU}>
                        {NewrelicRegion.EU}
                      </option>
                      <option key={NewrelicRegion.US} value={NewrelicRegion.US}>
                        {NewrelicRegion.US}
                      </option>
                    </select>
                    {formik.touched.region && formik.errors.region && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.region}</div>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2' style={{marginLeft: '15px'}}>
                      Account ID
                    </label>
                    <input
                      autoComplete='false'
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Account ID'
                      {...formik.getFieldProps('account_id')}
                      style={{maxWidth: '450px'}}
                    />
                    {formik.touched.account_id && formik.errors.account_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.account_id}</div>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2' style={{marginLeft: '15px'}}>
                      User API Key
                    </label>
                    <div className='d-flex align-items-center'>
                      <input
                        autoComplete='false'
                        type={showUserAPIKey ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='User API Key'
                        {...formik.getFieldProps('user_api_key')}
                        style={{maxWidth: '450px'}}
                      />
                      <button
                        type='button'
                        className='button'
                        onClick={toggleShowUserAPIKey}
                        style={{border: 'none', background: 'none', marginLeft: 8}}
                      >
                        <span className='password-toggle-icon ms-2'>
                          <i className={`fas ${showUserAPIKey ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                        </span>
                      </button>
                    </div>
                    {formik.touched.user_api_key && formik.errors.user_api_key && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.user_api_key}</div>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fw-bold fs-6 mb-2' style={{marginLeft: '15px'}}>
                      Account API Key (Optional)
                    </label>
                    <div className='d-flex align-items-center'>
                      <input
                        autoComplete='false'
                        type={showAccountAPIKey ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Account API Key'
                        {...formik.getFieldProps('account_api_key')}
                        style={{maxWidth: '450px'}}
                      />
                      <button
                        type='button'
                        className='button'
                        onClick={toggleShowAccountAPIKey}
                        style={{border: 'none', background: 'none', marginLeft: 8}}
                      >
                        <span className='password-toggle-icon ms-2'>
                          <i className={`fas ${showAccountAPIKey ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                        </span>
                      </button>
                    </div>
                    {formik.touched.account_api_key && formik.errors.account_api_key && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.account_api_key}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-start pt-0 py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default APIKeyPage
