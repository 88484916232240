import {ApplicationsListSearchComponent} from './ApplicationsListSearchComponent'
import {ApplicationsListToolbar} from './ApplicationsListToolbar'

const ApplicationsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ApplicationsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <ApplicationsListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ApplicationsListHeader}
