import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'

const RecordsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddRecordModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-record-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={openAddRecordModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Record
      </button>
    </div>
  )
}

export {RecordsListToolbar}
