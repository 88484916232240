import {useEffect, useState} from 'react'
import {getServerMonitoringData} from '../../../../servers-list/core/_requests'
import {useParams} from 'react-router-dom'
import {ProgressBar} from 'react-bootstrap'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const BasicData = () => {
  const {serverId} = useParams()
  const [monitoringData, setMonitoringData] = useState<any>()
  const [error, setError] = useState<any>()

  const getMonitoringData = () => {
    setMonitoringData(null)
    getServerMonitoringData(serverId)
      .then((data) => {
        setMonitoringData(data)
      })
      .catch((e) => {
        setError('Failed to retrieve monitoring data: ' + e.message)
      })
  }

  useEffect(() => {
    getMonitoringData()
  }, [])

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='basic_data_tab'>
      <TabHeader heading='Server Health' />
      <TabBody>
        {error ? (
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column'>
              <span>{error}</span>
            </div>
          </div>
        ) : !monitoringData ? (
          <div className='w-100 text-center my-10'>
            <span className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </span>
          </div>
        ) : (
          <>
            <div className='d-flex justify-content-end align-content-center mb-2'>
              <p className='fs-6 w-50'>CPU Usage ({monitoringData.cpu}%)</p>
              <ProgressBar className='flex-grow-1' now={monitoringData.cpu} />
            </div>
            <div className='d-flex justify-content-end align-content-center mb-2'>
              <p className='fs-6 w-50'>RAM Usage ({monitoringData.memory}%)</p>
              <ProgressBar className='flex-grow-1' now={monitoringData.memory} />
            </div>
            <div className='d-flex justify-content-end align-content-center mb-4'>
              <p className='fs-6 w-50'>Disk Usage ({monitoringData.disk}%)</p>
              <ProgressBar className='flex-grow-1' now={monitoringData.disk} />
            </div>
            <button type='button' className={`btn btn-primary`} onClick={getMonitoringData}>
              Refresh Data
            </button>
          </>
        )}
      </TabBody>
    </div>
  )
}

export default BasicData
