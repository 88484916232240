import {useParams} from 'react-router-dom'
import {CloudflareSettings} from '../../../../domains-list/core/_models'
import CloudflareBinarySetting from '../../common/CloudflareBinarySetting'
import CloudflareMultipleSetting from '../../common/CloudflareMultipleSetting'
import TabHeader from '../../../../../../../utils/TabHeader'
import TabBody from '../../../../../../../utils/TabBody'

export function ImageOptimization() {
  const {cloudflareDomainId} = useParams()

  return (
    <>
      <div className='card mb-5 mb-xl-10 w-100' id='speed_image_view'>
        <TabHeader heading='Image Optimization' />
        <TabBody>
          <div className='d-flex flex-column'>
            {/* Image Resize */}
            <CloudflareBinarySetting
              title='Image Resizing'
              description="You can resize, adjust quality, and convert images to WebP format, on demand. We
            cache every derived image at the edge, so you store only the original image. This
            allows you to adapt images to your site's layout and your visitors' screen sizes,
            quickly and easily, without maintaining a server-side image processing pipeline."
              setting={CloudflareSettings.IMAGE_RESIZING}
              cloudflareDomainId={cloudflareDomainId}
              isPro={true}
            />

            {/* Polish */}
            <CloudflareMultipleSetting
              title='Polish'
              description='Improve image load time by optimizing images hosted on your domain. Optionally, the
            WebP image codec can be used with supported clients for additional performance
            benefits.'
              setting={CloudflareSettings.POLISH}
              options={[
                ['OFF', 'off'],
                ['Lossless', 'lossless'],
                ['Lossy', 'lossy'],
              ]}
              cloudflareDomainId={cloudflareDomainId}
              isPro={true}
            />
          </div>
        </TabBody>
      </div>
    </>
  )
}
