import React, {useEffect, useState} from 'react'
import CronModal from './CronModal'
import {ApplicationCron} from '../../../applications-list/core/_models'
// import {deleteCron, getCrons} from '../../../../servers-list/core/_requests'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../utils/NotifyUtils'
import ConfirmDeleteModal from '../../../../../utils/ConfirmDeleteModal'
import {deleteApplicationCron, getApplicationCrons} from '../../../applications-list/core/_requests'

const Crons = () => {
  const {applicationId} = useParams()
  const [showCronModal, setShowCronModal] = useState(false)
  const [cronForEdit, setCronForEdit] = useState<ApplicationCron | undefined>(undefined)

  const handleCreateCronClick = () => {
    setCronForEdit(undefined)
    setShowCronModal(true)
  }

  const handleEditCronClick = (a: ApplicationCron) => {
    setCronForEdit(a)
    setShowCronModal(true)
  }

  const [cronData, setCronData] = useState<ApplicationCron[]>([])
  const [error, setError] = useState<any>()
  const [cronId, setCronId] = useState('')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleDelete = async (id: string) => {
    setCronId(id)
    setIsDeleteModalOpen(true)
  }

  const getCronData = () => {
    setCronData([])
    getApplicationCrons(applicationId || '')
      .then((data) => {
        setCronData(data || [])
        console.log(data)
      })
      .catch((e) => {
        setError('Failed to retrieve crons data: ' + e.message)
      })
  }

  const handleDeleteCron = () => {
    deleteApplicationCron(applicationId || '', cronId)
      .then((data) => {
        if (data) notifySuccess('Cron deleted succesfully')
        else notifyError('Cron deletion failed')
      })
      .catch((e) => {
        notifyError('Cron deletion failed, please try again')
      })
      .finally(() => {
        getCronData()
      })
  }

  useEffect(() => {
    getCronData()
  }, [showCronModal])

  return (
    <div className='card mb-3 mb-xl-10' id='staging_tab'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Crons</h3>
        </div>
      </div>
      <div className='px-9 py-4'>
        <div className='w-100 mt-4'>
          <button
            onClick={handleCreateCronClick}
            className='btn btn-primary justify-content-end mb-8'
          >
            Create Cron
          </button>
        </div>
        <div>
          <table className='table table-rounded table-striped border gy-7 gs-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th>Name</th>
                <th>Description</th>
                <th>Schedule</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cronData.map((cron) => {
                return (
                  <tr>
                    <td className=' align-self-center'>{cron.name}</td>
                    <td>{cron.description}</td>
                    <td>{cron.created_at && cron.created_at.slice(0, 10)}</td>
                    <td>
                      <button
                        onClick={() => handleEditCronClick(cron)}
                        className='btn btn-icon btn-primary btn-sm me-2'
                      >
                        <i className='las la-pen fs-2'></i>
                      </button>
                      <button
                        onClick={() => handleDelete(cron.id)}
                        className='btn btn-icon btn-danger btn-sm'
                      >
                        <i className='las la-trash fs-2'></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {showCronModal && (
          <CronModal title='Add/Edit Cron' setIsOpen={setShowCronModal} cron={cronForEdit} />
        )}
        {isDeleteModalOpen && (
          <ConfirmDeleteModal
            title='Are you sure?'
            deleteFunction={handleDeleteCron}
            content='This action is irreversible. Are you sure you want to delete this cron?'
            setIsOpen={setIsDeleteModalOpen}
          />
        )}
      </div>
    </div>
  )
}

export default Crons
