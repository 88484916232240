import {useListView} from '../../../core/ListViewProvider'
import {CloudsListSearchComponent} from './CloudsListSearchComponent'
import {CloudsListToolbar} from './CloudsListToolbar'

const CloudsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CloudsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <CloudsListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CloudsListHeader}
