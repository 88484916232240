import {useQuery} from 'react-query'
import {DomainEditModalForm} from './DomainEditModalForm'
import {getCloudflareDomainById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {initialCloudflareDomain} from '../../core/_models'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'

const DomainEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: cloudflareDomain,
    error,
  } = useQuery(
    `${'cloudflareDomains-list'}-cloudflareDomain-${itemIdForUpdate}`,
    () => {
      return getCloudflareDomainById(itemIdForUpdate as string)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <DomainEditModalForm
        isCloudflareDomainLoading={isLoading}
        cloudflareDomain={initialCloudflareDomain}
      />
    )
  }

  if (!isLoading && !error && cloudflareDomain) {
    return (
      <DomainEditModalForm
        isCloudflareDomainLoading={isLoading}
        cloudflareDomain={cloudflareDomain}
      />
    )
  }

  return null
}

export {DomainEditModalFormWrapper}
