import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Settings} from './server-settings/components/settings/Settings'
import {SSH} from './server-settings/components/ssh/SSH'
import {ServerHeader} from './server-settings/ServerHeader'
import Monitoring from './server-settings/components/monitoring/Monitoring'
import Manage from './server-settings/components/manage/Manage'
import Backups from './server-settings/components/backups/Backups'
import Services from './server-settings/components/services/Services'
import Security from './server-settings/components/security/Security'
import SMTP from './server-settings/components/smtp/SMTP'
import Optimizations from './server-settings/components/optimizations/Optimizations'
import ServerPageTips from './knowledge-base/ServerPageTips'

const serverBreadcrumbs: Array<PageLink> = [
  {
    title: 'Server Settings',
    path: '/server/:serverId/ssh',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ServerPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <ServerHeader />
            <ServerPageTips />
            <Outlet />
          </>
        }
      >
        <Route
          path=':serverId/ssh'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Server SSH</PageTitle>
              <SSH />
            </>
          }
        />

        <Route
          path=':serverId/settings'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Settings</PageTitle>
              <Settings />
            </>
          }
        />
        <Route
          path=':serverId/monitoring/*'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Monitoring</PageTitle>
              <Monitoring />
            </>
          }
        />
        <Route
          path=':serverId/backups'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Backups</PageTitle>
              <Backups />
            </>
          }
        />
        <Route
          path=':serverId/services'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Services</PageTitle>
              <Services />
            </>
          }
        />
        <Route
          path=':serverId/security/*'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Security</PageTitle>
              <Security />
            </>
          }
        />
        <Route
          path=':serverId/optimizations/*'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Optimizations</PageTitle>
              <Optimizations />
            </>
          }
        />
        <Route
          path=':serverId/manage/*'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>Manage</PageTitle>
              <Manage />
            </>
          }
        />
        <Route
          path=':serverId/smtp'
          element={
            <>
              <PageTitle breadcrumbs={serverBreadcrumbs}>SMTP</PageTitle>
              <SMTP />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/server/:serverId/ssh' />} />
    </Routes>
  )
}

export default ServerPage
