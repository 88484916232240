import axios, {AxiosResponse} from 'axios'
import {Record, RecordsQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ApiResponse} from '../../../auth'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DOMAIN_URL = `${API_URL}/v1/dns/domain-record`

const getRecords = (domainId?: string): Promise<RecordsQueryResponse> => {
  return axios
    .get(`${DOMAIN_URL}/domain/${domainId}`)
    .then((d: AxiosResponse<RecordsQueryResponse>) => d.data)
}

const getRecordById = (id: ID): Promise<Record | undefined> => {
  return axios
    .get(`${DOMAIN_URL}/${id}`)
    .then((d: AxiosResponse<Response<Record>>) => d.data)
    .then((d: Response<Record>) => d.data)
}

const createRecord = async (record: Record, domainId?: string) => {
  const response = await axios.post<ApiResponse>(`${DOMAIN_URL}/domain/${domainId}`, record)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const deleteRecord = async (id: ID) => {
  const response = await axios.delete<ApiResponse>(`${DOMAIN_URL}/${id}`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const updateRecord = async (id: ID, record: Record) => {
  const response = await axios.patch<ApiResponse>(`${DOMAIN_URL}/${id}`, {
    record_data: record.record_data,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

export {getRecords, getRecordById, createRecord, updateRecord, deleteRecord}
