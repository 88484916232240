import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'

const ProjectsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddProjectModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-project-table-toolbar='base'>
      {/* begin::Add project */}
      <button type='button' className='btn btn-primary' onClick={openAddProjectModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Project
      </button>
      {/* end::Add project */}
    </div>
  )
}

export {ProjectsListToolbar}
