import {FC} from 'react'
import {CloudflareDomain} from '../../../core/_models'
import {capitalize} from 'lodash'

type Props = {
  cloudflareDomain: CloudflareDomain
}

const CloudflareDomainStatusCell: FC<Props> = ({cloudflareDomain}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>{capitalize(cloudflareDomain.status)}</div>
  </div>
)

export {CloudflareDomainStatusCell}
