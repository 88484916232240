import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {DomainEditModalForm} from './DomainEditModalForm'
import {getDomainById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {initialDomain} from '../../core/_models'

const DomainEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: domain,
    error,
  } = useQuery(
    `${'domains-list'}-domain-${itemIdForUpdate}`,
    () => {
      return getDomainById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <DomainEditModalForm isDomainLoading={isLoading} domain={initialDomain} />
  }

  if (!isLoading && !error && domain) {
    return <DomainEditModalForm isDomainLoading={isLoading} domain={domain} />
  }

  return null
}

export {DomainEditModalFormWrapper}
