import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Project, ProjectsQueryResponse} from './_models'
import {ApiResponse} from '../../../auth'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROJECT_URL = `${API_URL}/v1/project`

// TODO: add query filter for search bar on BE
// query filter for asc desc columns
const getProjects = (query: string): Promise<ProjectsQueryResponse> => {
  return axios
    .get(`${PROJECT_URL}?${query}`)
    .then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getAllProjects = (): Promise<ProjectsQueryResponse> => {
  return axios.get(`${PROJECT_URL}/all`).then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getProjectById = (id: ID): Promise<Project | undefined> => {
  return axios
    .get(`${PROJECT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

const createProject = async (project: Project) => {
  const response = await axios.post<ApiResponse>(PROJECT_URL, project)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const updateProject = async (project: Project) => {
  const response = await axios.patch(`${PROJECT_URL}/${project.project_id}`, project)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteProject = async (projectId: ID) => {
  const response = await axios.delete(`${PROJECT_URL}/${projectId}`)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteSelectedProjects = (projectIds: Array<ID>): Promise<void> => {
  const requests = projectIds.map((id) => axios.delete(`${PROJECT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getProjects,
  getAllProjects,
  getProjectById,
  createProject,
  updateProject,
  deleteProject,
  deleteSelectedProjects,
}
