import {Column} from 'react-table'
import {RecordActionsCell} from './RecordActionCell'
import {RecordCustomHeader} from './RecordCustomHeader'
import {RecordNameCell} from './RecordNameCell'
import {RecordValueCell} from './RecordValueCell'
import {Record} from '../../../core/_models'
import {RecordTypeCell} from './RecordTypeCell'
import {RecordTTLCell} from './RecordTTLCell'
import {RecordIsProxiedCell} from './RecordIsProxiedCell'

const recordsColumns: ReadonlyArray<Column<Record>> = [
  /*
  {
    Header: (props) => <RecordSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <RecordSelectionCell id={props.data[props.row.index].id} />,
  }, */
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Type' className='min-w-80px' />
    ),
    id: 'type',
    Cell: ({...props}) => <RecordTypeCell record={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Name' className='min-w-80px' />
    ),
    id: 'name',
    Cell: ({...props}) => <RecordNameCell record={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Value' className='min-w-100px' />
    ),
    id: 'value',
    Cell: ({...props}) => <RecordValueCell record={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Proxied' className='min-w-50px' />
    ),
    id: 'proxied',
    Cell: ({...props}) => <RecordIsProxiedCell record={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <RecordCustomHeader tableProps={props} title='TTL' className='min-w-50px' />,
    id: 'ttl',
    Cell: ({...props}) => <RecordTTLCell record={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <RecordActionsCell id={props.data[props.row.index].id} />,
  },
]

export {recordsColumns}
