import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'

import {
  getEdgeCacheStateByApplication,
  updateApplicationEdgeCacheState,
} from '../../../../applications-list/core/_requests'
import {ApplicationEdgeCache} from '../../../../applications-list/core/_models'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

export function EdgeCache() {
  const {applicationId} = useParams<{applicationId: string}>()

  const [edgeCacheInfo, setEdgeCacheInfo] = useState<ApplicationEdgeCache | undefined>({
    state: false,
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  const emptyError = {
    code: 0,
    message: '',
  }
  const [error, setError] = useState(emptyError)

  useEffect(() => {
    setIsUpdating(true)
    getEdgeCacheStateByApplication(applicationId ?? '')
      .then((resp) => {
        setError(emptyError)
        setEdgeCacheInfo(resp)
        setIsUpdating(false)
      })
      .catch((error) => {
        const errObj = JSON.parse(error.message)
        setError(errObj)
        setIsUpdating(false)
      })
  }, [])

  const handleSaveButtonClick = () => {
    setIsUpdating(true)
    updateApplicationEdgeCacheState(applicationId ?? '', edgeCacheInfo!.state)
      .then((response) => {
        if (response) {
          notifySuccess('Edge cache is updated')
        } else {
          notifyError('Something went wrong')
          setEdgeCacheInfo({
            ...edgeCacheInfo!,
            state: !edgeCacheInfo!.state,
          })
        }
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      })
      .catch((error) => {
        notifyError(error.message)
        setEdgeCacheInfo({
          ...edgeCacheInfo!,
          state: !edgeCacheInfo!.state,
        })
      })
      .finally(() => {
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      })
  }

  const handleToggleChange = () => {
    setEdgeCacheInfo({
      ...edgeCacheInfo!,
      state: !edgeCacheInfo!.state,
    })

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  return (
    <>
      <div className='container-fluid' id='edge_cache_tab'>
        <TabHeader heading='Edge Cache' />
        <TabBody>
          {error.code !== 0 && error.message !== '' && (
            <div className='alert alert-danger d-flex align-items-center'>
              {error.code === 71504 ? (
                <span className='fs-6'>
                  Please integrate cloudflare with cloudphant by adding API Key.{' '}
                  <Link to='/integrations/cloudflare/key/'>Cloudflare Integration</Link>
                </span>
              ) : (
                <span className='fs-6'>Edge Cache Error: {error.message}</span>
              )}
            </div>
          )}
          <div className='row mb-10'>
            <div className='col-lg-12 mt-3'>
              <div className='row align-items-center'>
                <div className='col-lg-2'>Edge Cache Status:</div>
                <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-25px'
                    type='checkbox'
                    checked={edgeCacheInfo!.state}
                    onChange={handleToggleChange}
                    contentEditable={isUpdating}
                    style={{
                      backgroundColor: edgeCacheInfo!.state ? 'green' : 'grey',
                      borderColor: edgeCacheInfo!.state ? 'green' : 'grey',
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <button
                    type='button'
                    className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                    onClick={handleSaveButtonClick}
                    disabled={!isSaveButtonActive || isUpdating}
                  >
                    <span className='indicator-label'>
                      {isUpdating ? 'Please wait . . .' : 'Save'}
                    </span>
                    {isUpdating && (
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                <div className='rounded-3 border py-2 px-4 mb-4 mt-4 d-flex'>
                  <div className='mt-2'>
                    <p className='text-muted'>
                      This enables free edge cache for your website on Cloudflare and makes your
                      website blazingly fast. Your website will then be served from edge to any
                      location. It can improve your websites latency by considerable number.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabBody>
      </div>
    </>
  )
}
