import {Response} from '../../../../../_metronic/helpers'

export type Project = {
  project_id?: string
  name: string
  description?: string
}

export type ProjectsQueryResponse = Response<Array<Project>>

export const initialProject: Project = {
  name: '',
  description: '',
}
