import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'
import {StorageEditModalForm} from './StorageEditModalForm'
import {getStorageById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {initialStorage} from '../../core/_models'

const StorageEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: storage,
    error,
  } = useQuery(
    `${'storages-list'}-storage-${itemIdForUpdate}`,
    () => {
      return getStorageById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <StorageEditModalForm isStorageLoading={isLoading} storage={initialStorage} />
  }

  if (!isLoading && !error && storage) {
    return <StorageEditModalForm isStorageLoading={isLoading} storage={storage} />
  }

  return null
}

export {StorageEditModalFormWrapper}
