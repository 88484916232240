import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Organization} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ORGANIZATION_URL = `${API_URL}/v1/organization`

const getDefaultOrganization = (): Promise<Organization | undefined> => {
  return axios
    .get(`${ORGANIZATION_URL}/default`)
    .then((response: AxiosResponse<Response<Organization>>) => response.data)
    .then((response: Response<Organization>) => response.data)
}

export {getDefaultOrganization}
