import axios, {AxiosResponse} from 'axios'
import {
  CloudflareDomain,
  CloudflareDomainsQueryResponse,
  CloudflareSettings,
  ICloudflareAPIKeys,
} from './_models'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {ApiResponse} from '../../../../auth'
import {Cloud} from '../../../clouds/clouds-list/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CLOUDFLARE_INTEGRATION_URL = `${API_URL}/v1/integration/cloudflare`

const getCloudflareAPIKey = (): Promise<Cloud | undefined> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/api-key`)
    .then((response: AxiosResponse<Response<Cloud>>) => response.data)
    .then((response: Response<Cloud>) => response.data)
}

const updateCloudflareAPIKey = async (data: ICloudflareAPIKeys): Promise<boolean> => {
  const response = await axios.post(`${CLOUDFLARE_INTEGRATION_URL}/api-key`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const getCloudflareDomains = (query: string): Promise<CloudflareDomainsQueryResponse> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/domains?${query}`)
    .then((d: AxiosResponse<CloudflareDomainsQueryResponse>) => d.data)
}

const createCloudflareDomain = async (domainName: string) => {
  const response = await axios.post<ApiResponse>(`${CLOUDFLARE_INTEGRATION_URL}/domains`, {
    domainName,
  })

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data
}

const getCloudflareDomainById = (domainId: string): Promise<CloudflareDomain | undefined> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}`)
    .then((d: AxiosResponse<Response<CloudflareDomain>>) => d.data)
    .then((d: Response<CloudflareDomain>) => d.data)
}

const getNameserversByCloudflareDomain = (domainId: string): Promise<string[] | undefined> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/nameservers?cloudflareDomainId=${domainId}`)
    .then((d: AxiosResponse<Response<string[]>>) => d.data)
    .then((d: Response<string[]>) => d.data)
}

const getCloudflareSetting = (
  domainId: string,
  setting: CloudflareSettings
): Promise<any | undefined> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/setting/${setting}`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
    .then((d: Response<any>) => d.data)
}

const getCloudflareCertificates = (domainId: string): Promise<any | undefined> => {
  return axios
    .get(`${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/ssl/certificate_packs`)
    .then((d: AxiosResponse<Response<any>>) => d.data)
    .then((d: Response<any>) => d.data)
}

const setCloudflareSetting = async (domainId: string, setting: CloudflareSettings, value: any) => {
  const response = await axios.patch(
    `${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/setting/${setting}`,
    {value}
  )
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const purgeSelectedURLs = async (domainId: string, urls: string[]) => {
  const response = await axios.post(
    `${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/purge_cache`,
    {files: urls}
  )
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const purgeEverything = async (domainId: string) => {
  const response = await axios.post(
    `${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}/purge_cache`,
    {purge_everything: true}
  )
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteCloudflareDomain = (
  domainId: ID,
  deleteFromCloudflare: boolean
): Promise<{data: boolean}> => {
  return axios
    .delete(
      `${CLOUDFLARE_INTEGRATION_URL}/domains/${domainId}?deleteFromCloudflare=${deleteFromCloudflare}`
    )
    .then((d: AxiosResponse<{data: boolean}>) => d.data)
}

export {
  getCloudflareAPIKey,
  updateCloudflareAPIKey,
  getCloudflareDomains,
  getCloudflareDomainById,
  getCloudflareCertificates,
  getNameserversByCloudflareDomain,
  getCloudflareSetting,
  createCloudflareDomain,
  setCloudflareSetting,
  purgeSelectedURLs,
  purgeEverything,
  deleteCloudflareDomain,
}
