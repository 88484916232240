import {useFormik} from 'formik'
import * as Yup from 'yup'

import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {createAlert, getServerServices, updateAlert} from '../../../../servers-list/core/_requests'
import {useParams} from 'react-router-dom'
import {
  AlertLevel,
  AlertOperator,
  AlertPeriod,
  AlertType,
  CreateAlert,
} from '../../../../servers-list/core/_models'
import {AlertResource} from '../../../../servers-list/core/_models'
import {Alert} from '../../../../servers-list/core/_models'

type AlertModalProps = {
  title: string
  setIsOpen: (isOpen: boolean) => void
  alert?: Alert
}

const alertSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 letters')
    .max(500, 'Maximum 5000 letters')
    .required('Name is required'),
  description: Yup.string(),
  type: Yup.mixed().oneOf(Object.values(AlertType)),
  consecutive_period: Yup.number(),
  period: Yup.number(),
  resource: Yup.string(),
  operator: Yup.string(),
  percentage: Yup.number().min(0).max(100),
})

const AlertModal = ({title, setIsOpen, alert}: AlertModalProps) => {
  const {serverId} = useParams()
  const [alertData, setAlertData] = useState<CreateAlert>({
    name: alert ? alert.name : '',
    description: alert ? alert.description : '',
    type: alert ? alert.type : AlertType.RESOURCE,
    consecutive_period: alert ? alert.consecutive_period : 1,
    period: alert ? alert.period : 1,
    resource: alert ? alert.data.resource : AlertResource.CPU,
    operator: alert ? alert?.data.operator : '>',
    percentage: alert ? alert.data.percentage : 0,
    level: alert ? alert.level : AlertLevel.HIGH,
  })
  const [alertServices, setAlertServices] = useState([])

  const getServerServicesList = () => {
    setAlertServices([])
    getServerServices(serverId || '')
      .then((data) => {
        setAlertServices(data || [])
      })
      .catch((e) => {
        //Fail silently for now
      })
  }

  useEffect(() => {
    getServerServicesList()
  }, [])

  const formik = useFormik({
    initialValues: alertData,
    validationSchema: alertSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (alert) {
        updateAlert(serverId || '', alert.id, values)
          .then((data) => {
            notifySuccess('Alert updation successful')
          })
          .catch((error) => {
            notifyError(`Alert updation failed: ${error.message}`)
          })
          .finally(() => {
            setSubmitting(false)
            setIsOpen(false)
          })
      } else {
        createAlert(serverId || '', values)
          .then((data) => {
            if (data) notifySuccess('Alert creation successful')
            else notifyError('Alert creation failed')
          })
          .catch((error) => {
            notifyError(`Alert creation failed: ${error.message}`)
          })
          .finally(() => {
            setSubmitting(false)
            setIsOpen(false)
          })
      }
    },
  })
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_subscription_delete'
        role='dialog'
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{title}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>

            <div className='modal-body d-flex flex-column'>
              <form id='kt_modal_add_domain_form' className='form' onSubmit={formik.handleSubmit}>
                <div className='row w-100 mb-7'>
                  <div className='col-12'>
                    <label className='required fw-bold fs-6 mb-2'>Alert Name</label>
                    <input
                      placeholder='Alert Name'
                      {...formik.getFieldProps('name')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row w-100 mb-7'>
                  <div className='col-12'>
                    <label className='fw-bold fs-6 mb-2'>Alert Description</label>
                    <input
                      placeholder='Alert Description'
                      {...formik.getFieldProps('description')}
                      type='text'
                      name='description'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row w-100 mb-7'>
                  <div className='col-6'>
                    <label className='required fw-bold fs-6 mb-2'>AlertType</label>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('type')}
                      name='type'
                      value={formik.values.type}
                      onChange={(e) => {
                        if (e.target.value == AlertType.RESOURCE) {
                          formik.setFieldValue('resource', AlertResource.CPU)
                        } else if (e.target.value == AlertType.SERVICE) {
                          formik.setFieldValue('resource', alertServices ? alertServices[0] : '')
                        }
                        formik.setFieldValue('type', e.target.value)
                      }}
                    >
                      {Object.values(AlertType).map((type) => {
                        return <option value={type}>{type}</option>
                      })}
                    </select>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                  </div>
                  {formik.values.type == AlertType.RESOURCE ? (
                    <div className='col-6'>
                      <label className='required fw-bold fs-6 mb-2'>Resource</label>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('resource')}
                        name='resource'
                        value={formik.values.resource}
                        onChange={(e) => formik.setFieldValue('resource', e.target.value)}
                      >
                        {Object.values(AlertResource).map((resource) => {
                          return <option value={resource}>{resource}</option>
                        })}
                      </select>
                      {formik.touched.resource && formik.errors.resource && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.resource}</div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='col-6'>
                      <label className='required fw-bold fs-6 mb-2'>Service</label>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('resource')}
                        name='resource'
                        value={formik.values.resource}
                        onChange={(e) => formik.setFieldValue('resource', e.target.value)}
                      >
                        {Object.values(alertServices).map((resource) => {
                          return <option value={resource}>{resource}</option>
                        })}
                      </select>
                      {formik.touched.resource && formik.errors.resource && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.resource}</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {formik.values.type == AlertType.RESOURCE && (
                  <div className='row w-100 mb-7'>
                    <div className='col-6'>
                      <label className='required fw-bold fs-6 mb-2'>Alert When</label>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('operator')}
                        name='operator'
                        value={formik.values.operator}
                        onChange={(e) => formik.setFieldValue('operator', e.target.value)}
                      >
                        {Object.values(AlertOperator).map((operator) => {
                          return <option value={operator}>{operator}</option>
                        })}
                      </select>
                      {formik.touched.operator && formik.errors.operator && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.operator}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-6'>
                      <label className='required fw-bold fs-6 mb-2'>Percentage</label>
                      <input
                        placeholder='Percentage'
                        {...formik.getFieldProps('percentage')}
                        type='number'
                        name='percentage'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.percentage && formik.errors.percentage},
                          {
                            'is-valid': formik.touched.percentage && !formik.errors.percentage,
                          }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                      />
                      {formik.touched.percentage && formik.errors.percentage && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.percentage}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className='row w-100 mb-7'>
                  <div className='col-6'>
                    <label className='required fw-bold fs-6 mb-2'>Consecutive Periods</label>
                    <input
                      placeholder='Consecutive Periods'
                      {...formik.getFieldProps('consecutive_period')}
                      type='number'
                      name='consecutive_period'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.consecutive_period && formik.errors.consecutive_period,
                        },
                        {
                          'is-valid':
                            formik.touched.consecutive_period && !formik.errors.consecutive_period,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.consecutive_period && formik.errors.consecutive_period && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.consecutive_period}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-6'>
                    <label className='required fw-bold fs-6 mb-2'>Period</label>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('period')}
                      name='period'
                      value={formik.values.period}
                      onChange={(e) => formik.setFieldValue('period', +e.target.value)}
                    >
                      {Object.entries(AlertPeriod).map((k, v) => {
                        return <option value={k[1]}>{k[0]}</option>
                      })}
                    </select>
                    {formik.touched.period && formik.errors.period && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.period}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='text-center pt-5'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-domains-modal-action='cancel'
                    onClick={() => setIsOpen(false)}
                    disabled={formik.isSubmitting}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-domains-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>Submit</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default AlertModal
