import axios, {AxiosResponse} from 'axios'
import {Cloud, CloudsQueryResponse} from './_models'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {ApiResponse} from '../../../../auth'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CLOUD_INTEGRATION_URL = `${API_URL}/v1/integration/cloud/api-key`

// TODO: add query filter for search bar on BE
// query filter for asc desc columns
const getClouds = (query: string): Promise<CloudsQueryResponse> => {
  return axios
    .get(`${CLOUD_INTEGRATION_URL}/all`)
    .then((d: AxiosResponse<CloudsQueryResponse>) => d.data)
}

const createCloud = async (cloud: Cloud) => {
  const response = await axios.post<ApiResponse>(CLOUD_INTEGRATION_URL, cloud)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const updateCloud = async (cloud: Cloud) => {
  const response = await axios.patch(`${CLOUD_INTEGRATION_URL}/${cloud.id}`, cloud)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteCloud = async (cloudId: ID) => {
  const response = await axios.delete(`${CLOUD_INTEGRATION_URL}/${cloudId}`)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getCloudById = (id: ID): Promise<Cloud | undefined> => {
  return axios
    .get(`${CLOUD_INTEGRATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Cloud>>) => response.data)
    .then((response: Response<Cloud>) => response.data)
}

const deleteSelectedClouds = (cloudIds: Array<ID>): Promise<void> => {
  const requests = cloudIds.map((id) => axios.delete(`${CLOUD_INTEGRATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getClouds, createCloud, updateCloud, deleteCloud, getCloudById, deleteSelectedClouds}
