import axios, {AxiosResponse} from 'axios'
import {Cloud} from '../../clouds/clouds-list/core/_models'
import {Response} from '../../../../../_metronic/helpers'
import {INewrelicAPIKeys} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const NEWRELIC_INTEGRATION_URL = `${API_URL}/v1/integration/newrelic`

const getNewrelicAPIKey = (): Promise<Cloud | undefined> => {
  return axios
    .get(`${NEWRELIC_INTEGRATION_URL}/api-key`)
    .then((response: AxiosResponse<Response<Cloud>>) => response.data)
    .then((response: Response<Cloud>) => response.data)
}

const updateNewrelicAPIKey = async (data: INewrelicAPIKeys): Promise<boolean> => {
  const response = await axios.post(`${NEWRELIC_INTEGRATION_URL}/api-key`, data)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

export {getNewrelicAPIKey, updateNewrelicAPIKey}
