import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {CloudsListWrapper} from './clouds-list/CloudsList'
import KnowledgeBaseAccordion from '../../common/knowledgebase/KnowledgeBaseAccordion'

const cloudsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Cloud Management',
    path: '/integrations/cloud/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CloudsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={cloudsBreadcrumbs}>Cloud List</PageTitle>
              <KnowledgeBaseAccordion
                body={`This page is to integrate users cloud accounts with Cloudphant account. We currently support AWS, Digital Ocean and Linode integrations.
                <br>
                <br>
                The project list page provides the following details
                <ol>
                  <li><b>Name</b>: The name assigned to the cloud integration by the user</li>
                  <li><b>Description</b>: The description given by the user</li>
                  <li><b>Platform</b>: The cloud platform to which the integration belong</li>
                  <li><b>Version</b>: The version would increment if you make any changes to the integration details</li>
                  <li><b>Actions</b>: The actions include edit and delete.</li>
                </ol>
                The <mark className="bg-primary"><b>Add Cloud</b></mark> button is to be used to create a new cloud integration.`}
              />
              <CloudsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/integrations/cloud/list' />} />
    </Routes>
  )
}

export default CloudsPage
