/* eslint-disable */
import React from 'react'
import {Link, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import PageCache from './components/PageCache'
import Database from './components/Database'
import WebP from './components/WebP'
import ObjectCache from './components/ObjectCache'
import OPCache from './components/OPCache'
import BrowserCache from './components/BrowserCache'
import HTMLMinify from './components/HTMLMinify'
import {EdgeCache} from './components/EdgeCache'
import DatabaseRepair from './components/DatabaseRepair'

const Optimizations = () => {
  const {applicationId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
            <div className='d-flex overflow-auto'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Optimizations</h3>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='py-5 px-9'>
                <div className='card-title m-0'>
                  <h5 className='fw-bolder mb-3'>Cache</h5>
                </div>
                <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6'>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/page-cache`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/page-cache` && 'active')
                      }
                    >
                      Page Cache
                    </Link>
                  </li>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/object-cache`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/object-cache` && 'active')
                      }
                    >
                      Object Cache
                    </Link>
                  </li>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/browser-cache`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/browser-cache` && 'active')
                      }
                    >
                      Browser Cache
                    </Link>
                  </li>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/opcache`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/opcache` && 'active')
                      }
                    >
                      OPCache
                    </Link>
                  </li>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/edge-cache`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/edge-cache` && 'active')
                      }
                    >
                      Edge Cache
                    </Link>
                  </li>
                  <div className='separator border-secondary my-4'></div>
                  <div className='card-title m-0'>
                    <h5 className='fw-bolder mb-3'>Database</h5>
                  </div>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/database/cleanup`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/database/cleanup` &&
                          'active')
                      }
                    >
                      Cleanup
                    </Link>
                  </li>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/database/repair`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/database/repair` && 'active')
                      }
                    >
                      Optimize
                    </Link>
                  </li>
                  <div className='separator border-secondary my-4'></div>
                  <div className='card-title m-0'>
                    <h5 className='fw-bolder mb-3'>Image</h5>
                  </div>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/webp`}
                      className={
                        `nav-link ` +
                        (location.pathname === `/application/${applicationId}/optimizations/webp` &&
                          'active')
                      }
                    >
                      WebP & Compression
                    </Link>
                  </li>
                  <div className='separator border-secondary my-4'></div>
                  <div className='card-title m-0'>
                    <h5 className='fw-bolder mb-3'>Content</h5>
                  </div>
                  <li className='nav-item w-md-200px me-0'>
                    <Link
                      to={`/application/${applicationId}/optimizations/html-minify`}
                      className={
                        `nav-link ` +
                        (location.pathname ===
                          `/application/${applicationId}/optimizations/html-minify` && 'active')
                      }
                    >
                      HTML Minify
                    </Link>
                  </li>
                </ul>
              </div>
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path='page-cache' element={<PageCache />} />

        <Route path='browser-cache' element={<BrowserCache />} />

        <Route path='object-cache' element={<ObjectCache />} />

        <Route path='edge-cache' element={<EdgeCache />} />

        <Route path='database/cleanup' element={<Database />} />

        <Route path='database/repair' element={<DatabaseRepair />} />

        <Route path='opcache' element={<OPCache />} />

        <Route path='webp' element={<WebP />} />

        <Route path='html-minify' element={<HTMLMinify />} />
      </Route>

      <Route index element={<Navigate to='page-cache' />} />
    </Routes>
  )
}
export default Optimizations
