import {KTCard} from '../../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {CloudEditModal} from './components/cloud-add-edit-modal/CloudEditModal'
import {CloudsListHeader} from './components/table/header/CloudsListHeader'
import {CloudsTable} from './components/table/CloudsTable'
import {QueryResponseProvider} from './core/QueryResponseProvider'

const CloudsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CloudsListHeader />
        <CloudsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CloudEditModal />}
    </>
  )
}

const CloudsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CloudsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CloudsListWrapper}
