import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ServerEditModal} from './components/server-add-edit-modal/ServerEditModal'
import {ServersListHeader} from './components/table/header/ServersListHeader'
import {ServersTable} from './components/table/ServersTable'

const ServersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ServersListHeader />
        <ServersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ServerEditModal />}
    </>
  )
}

const ServersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ServersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ServersListWrapper}
