import {KTCard} from '../../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {GitEditModal} from './components/git-add-edit-modal/GitEditModal'
import {GitsListHeader} from './components/table/header/GitsListHeader'
import {GitsTable} from './components/table/GitsTable'
import {QueryResponseProvider} from './core/QueryResponseProvider'

const GitsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <GitsListHeader />
        <GitsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <GitEditModal />}
    </>
  )
}

const GitsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <GitsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {GitsListWrapper}
