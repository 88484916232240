import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {ICloudflareAPIKeys} from './domains-list/core/_models'
import * as Yup from 'yup'
import {getCloudflareAPIKey, updateCloudflareAPIKey} from './domains-list/core/_requests'
import {notifyError, notifySuccess} from '../../../utils/NotifyUtils'

const cloudflareKeysSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  api_key: Yup.string().required('API key is required'),
})

const initialValues: ICloudflareAPIKeys = {
  email: '',
  api_key: '',
}

const APIKeyPage: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [showAPIKey, setShowAPIKey] = useState(false)

  const toggleShow = () => {
    setShowAPIKey(!showAPIKey)
  }

  useEffect(() => {
    getAPIKey()
  }, [])

  const getAPIKey = () => {
    getCloudflareAPIKey().then((resp) => {
      formik.setValues({
        email: resp?.api_key?.email || initialValues.email,
        api_key: resp?.api_key?.api_key || initialValues.api_key,
      })
    })
  }

  const formik = useFormik<ICloudflareAPIKeys>({
    initialValues,
    enableReinitialize: true,
    validationSchema: cloudflareKeysSchema,
    onSubmit: async (values) => {
      setLoading(true)
      updateCloudflareAPIKey(values)
        .then((data: any) => {
          if (data) {
            formik.setValues({
              email: data?.api_key?.email || initialValues.email,
              api_key: data?.api_key?.api_key || initialValues.api_key,
            })
          }
          notifySuccess('Cloudflare keys updated successfully!')
        })
        .catch((error: any) => {
          notifyError('Cloudflare keys update failed: ' + error.message)
        })
        .finally(() => {
          setLoading(false)
          getAPIKey()
        })
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        data-bs-target='#kt_cloudflare_api_key'
        aria-expanded='true'
        aria-controls='kt_cloudflare_api_key'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Cloudflare API Details</h3>
        </div>
      </div>

      <div id='kt_cloudflare_api_key' className='collapse show'>
        <form autoComplete='off' onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top pb-0 p-9'>
            <div className='row mb-6'>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2' style={{marginLeft: '15px'}}>
                      Cloudflare Email
                    </label>
                    <input
                      autoComplete='false'
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Cloudflare Email'
                      {...formik.getFieldProps('email')}
                      style={{maxWidth: '450px'}}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2' style={{marginLeft: '15px'}}>
                      API Key
                    </label>
                    <div className='d-flex align-items-center'>
                      <input
                        autoComplete='false'
                        type={showAPIKey ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='API Key'
                        {...formik.getFieldProps('api_key')}
                        style={{maxWidth: '450px'}}
                      />
                      <button
                        type='button'
                        className='button'
                        onClick={toggleShow}
                        style={{border: 'none', background: 'none', marginLeft: 8}}
                      >
                        <span className='password-toggle-icon ms-2'>
                          <i className={`fas ${showAPIKey ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                        </span>
                      </button>
                    </div>
                    {formik.touched.api_key && formik.errors.api_key && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.api_key}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-start pt-0 py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default APIKeyPage
