import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {SSHDetails, ServerUserType} from '../../../servers-list/core/_models'
import {getSSHDetails} from '../../../servers-list/core/_requests'
import PasswordField from '../../../../../utils/PasswordField'

export function SSH() {
  const {serverId} = useParams()

  const [sshDetails, setSSHDetails] = useState<SSHDetails>()

  useEffect(() => {
    getSSHDetails(serverId ?? '', ServerUserType.ROOT).then((resp) => {
      setSSHDetails(resp)
    })
  }, [])

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='dc_ssh_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>SSH Details</h3>
          </div>
        </div>

        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h4 className='fw-bolder m-0'>Root Details</h4>
          </div>
        </div>

        <div className='alert alert-primary d-flex align-items-center p-3 mx-8 my-6'>
          <div className='d-flex flex-column'>
            <span className=''>
              Be careful while doing an SSH into the server, any misuse can crash your website(s)
              and can go into a nonrecoverable state.
            </span>
          </div>
        </div>

        <div className='px-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Server IP</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{sshDetails?.server.ip}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Username</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{sshDetails?.username}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Password</label>

            <div className='col-lg-8 fv-row'>
              <PasswordField password={sshDetails?.password ?? ''} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
