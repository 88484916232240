import {Column} from 'react-table'
import {ServerActionsCell} from './ServerActionsCell'
import {ServerCustomHeader} from './ServerCustomHeader'
import {ServerIPCell} from './ServerIPCell'
import {ServerNameCell} from './ServerNameCell'
import {ServerStatusCell} from './ServerStatusCell'
import {Server, ServerState} from '../../../core/_models'

const serversColumns: ReadonlyArray<Column<Server>> = [
  /*
  {
    Header: (props) => <ServerSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ServerSelectionCell id={props.data[props.row.index].server_id} />,
  }, */
  {
    Header: (props) => (
      <ServerCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <ServerNameCell server={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ServerCustomHeader tableProps={props} title='IP' className='min-w-125px' />,
    id: 'ip',
    Cell: ({...props}) => <ServerIPCell server={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ServerCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'server_state',
    Cell: ({...props}) => <ServerStatusCell server={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ServerCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ServerActionsCell
        id={props.data[props.row.index].server_id}
        serverState={props.data[props.row.index].server_state ?? ServerState.STARTED}
        identifier={props.data[props.row.index].identifier}
      />
    ),
  },
]

export {serversColumns}
