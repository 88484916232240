import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {deleteApplication} from '../../../core/_requests'
import ConfirmDeleteModal from '../../../../../../utils/ConfirmDeleteModal'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {Id} from 'react-toastify'

type Props = {
  id: ID
}

const ApplicationActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  let notifyId: Id
  const deleteItem = useMutation(() => deleteApplication(id), {
    onMutate: () => {
      notifyId = notifyProgress('Application deletion in progress')
    },
    onSuccess: () => {
      notifySuccess('Application deletion successful', notifyId)
      queryClient.invalidateQueries([`${'applications-list'}-${query}`])
    },
    onError: (error: Error) => {
      notifyError('Application deletion failed: ' + error.message, notifyId)
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-applications-table-filter='delete_row'
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </a>
        </div>
      </div>
      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          title='Are you sure?'
          deleteFunction={async () => await deleteItem.mutateAsync()}
          content='This action is irreversible. Are you sure you want to delete this website?'
          setIsOpen={setIsDeleteModalOpen}
        />
      )}
    </>
  )
}

export {ApplicationActionsCell}
