import React, {useEffect, useState} from 'react'
import TabBody from '../../../../../../utils/TabBody'
import TabHeader from '../../../../../../utils/TabHeader'
import AlertModal from './AlertModal'
import {Alert, MonitService, ServerServiceStatus} from '../../../../servers-list/core/_models'
import {deleteAlert, getAlerts, getMonitService} from '../../../../servers-list/core/_requests'
import {Link, useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmDeleteModal from '../../../../../../utils/ConfirmDeleteModal'

const Alerts = () => {
  const {serverId} = useParams()
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [alertForEdit, setAlertForEdit] = useState<Alert | undefined>(undefined)

  const [monitService, setMonitService] = useState<MonitService>({
    username: '',
    password: '',
    url: '',
    state: false,
    status: ServerServiceStatus.STOPPED,
  })

  const getMonit = () => {
    getMonitService(serverId)
      .then((data) => {
        setMonitService(data)
      })
      .catch((e) => {
        setError('Failed to retrieve monit state: ' + e.message)
      })
  }

  useEffect(() => {
    getMonit()
  }, [])

  const handleCreateAlertClick = () => {
    setAlertForEdit(undefined)
    setShowAlertModal(true)
  }

  const handleEditAlertClick = (a: Alert) => {
    setAlertForEdit(a)
    setShowAlertModal(true)
  }

  const [alertData, setAlertData] = useState<Alert[]>([])
  const [error, setError] = useState<any>()
  const [alertId, setAlertId] = useState('')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleDelete = async (id: string) => {
    setAlertId(id)
    setIsDeleteModalOpen(true)
  }

  const getAlertData = () => {
    setAlertData([])
    getAlerts(serverId || '')
      .then((data) => {
        setAlertData(data || [])
      })
      .catch((e) => {
        setError('Failed to retrieve alerts data: ' + e.message)
      })
  }

  const handleDeleteAlert = () => {
    deleteAlert(serverId || '', alertId)
      .then((data) => {
        if (data) notifySuccess('Alert deleted succesfully')
        else notifyError('Alert deletion failed')
      })
      .catch((e) => {
        notifyError('Alert deletion failed, please try again')
      })
      .finally(() => {
        getAlertData()
      })
  }

  useEffect(() => {
    getAlertData()
  }, [showAlertModal])

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='basic_data_tab'>
      <TabHeader heading='Monit' />
      <TabBody>
        {monitService.state && monitService.status === ServerServiceStatus.RUNNING ? (
          <>
            <div className='w-100'>
              <button
                onClick={handleCreateAlertClick}
                className='btn btn-primary justify-content-end mb-8'
              >
                Create Alert
              </button>
            </div>
            <div>
              <table className='table table-rounded table-striped border gy-7 gs-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th>Name</th>
                    <th>Resource</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alertData.map((alert) => {
                    return (
                      <tr>
                        <td className=' align-self-center'>{alert.name}</td>
                        <td>{alert.type}</td>
                        <td>{alert.created_at.slice(0, 10)}</td>
                        <td>
                          <button
                            onClick={() => handleEditAlertClick(alert)}
                            className='btn btn-icon btn-primary btn-sm me-2'
                          >
                            <i className='las la-pen fs-2'></i>
                          </button>
                          <button
                            onClick={() => handleDelete(alert.id)}
                            className='btn btn-icon btn-danger btn-sm'
                          >
                            <i className='las la-trash fs-2'></i>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {showAlertModal && (
              <AlertModal
                title='Add/Edit Alert'
                setIsOpen={setShowAlertModal}
                alert={alertForEdit}
              />
            )}
            {isDeleteModalOpen && (
              <ConfirmDeleteModal
                title='Are you sure?'
                deleteFunction={handleDeleteAlert}
                content='This action is irreversible. Are you sure you want to delete this alert?'
                setIsOpen={setIsDeleteModalOpen}
              />
            )}
          </>
        ) : (
          <div className='alert alert-warning d-flex align-items-center p-4'>
            <div className='d-flex flex-column'>
              <h5 className='mb-1'>Monit installation is required!</h5>
              <span>
                Please enable Monit from{' '}
                <u>
                  <Link to={`/server/${serverId}/monitoring/monit`}>here</Link>
                </u>{' '}
                .
              </span>
            </div>
          </div>
        )}
      </TabBody>
    </div>
  )
}

export default Alerts
