import {useFormik} from 'formik'
import * as Yup from 'yup'

import {notifyError, notifySuccess} from '../../../../../utils/NotifyUtils'
import {useState} from 'react'
import clsx from 'clsx'
import {
  CreateApplicationCron,
  ApplicationCron,
  CronSchedule,
} from '../../../applications-list/core/_models'
import {useParams} from 'react-router-dom'
import {
  createApplicationCron,
  updateApplicationCron,
} from '../../../applications-list/core/_requests'

type CronModalProps = {
  title: string
  setIsOpen: (isOpen: boolean) => void
  cron?: ApplicationCron
}

const cronSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 letters')
    .max(500, 'Maximum 5000 letters')
    .required('Name is required'),
  description: Yup.string(),
  schedule: Yup.string(), // Change to an ENUM
  command: Yup.string(),
})

const CronModal = ({title, setIsOpen, cron}: CronModalProps) => {
  const {applicationId} = useParams()
  const [cronData, setCronData] = useState<CreateApplicationCron>({
    name: cron ? cron.name : '',
    description: cron ? cron.description : '',
    type: cron ? cron.type : CronSchedule['Every Minute'],
    command: cron ? cron.command : '',
    schedule: cron ? cron.schedule : '',
  })

  const [customScheule, setCustomScheule] = useState('')

  const formik = useFormik({
    initialValues: cronData,
    validationSchema: cronSchema,
    onSubmit: (values) => {
      formik.setSubmitting(true)
      if (values.type != CronSchedule.Custom) {
        values.schedule = values.type
      }
      if (cron) {
        updateApplicationCron(applicationId || '', cron.id, values)
          .then((data) => {
            notifySuccess('Cron updation successful')
          })
          .catch((error) => {
            notifyError(`Cron updation failed: ${error.message}`)
          })
          .finally(() => {
            formik.setSubmitting(false)
            setIsOpen(false)
          })
      } else {
        createApplicationCron(applicationId || '', values)
          .then((data) => {
            if (data) notifySuccess('Cron creation successful')
            else notifyError('Cron creation failed')
          })
          .catch((error) => {
            notifyError(`Cron creation failed: ${error.message}`)
          })
          .finally(() => {
            formik.setSubmitting(false)
            setIsOpen(false)
          })
      }
    },
  })
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_subscription_delete'
        role='dialog'
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{title}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>

            <div className='modal-body d-flex flex-column'>
              <form id='kt_modal_add_domain_form' className='form' onSubmit={formik.handleSubmit}>
                <div className='row w-100 mb-7'>
                  <div className='col-12'>
                    <label className='required fw-bold fs-6 mb-2'>Cron Name</label>
                    <input
                      placeholder='Cron Name'
                      {...formik.getFieldProps('name')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='cron'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row w-100 mb-7'>
                  <div className='col-12'>
                    <label className='fw-bold fs-6 mb-2'>Cron Description</label>
                    <input
                      placeholder='Cron Description'
                      {...formik.getFieldProps('description')}
                      type='text'
                      name='description'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='cron'>{formik.errors.description}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row w-100 mb-7'>
                  <div className='col-6'>
                    <label className='required fw-bold fs-6 mb-2'>Cron When</label>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('type')}
                      name='type'
                      value={formik.values.type}
                      onChange={(e) => formik.setFieldValue('type', e.target.value)}
                    >
                      {Object.entries(CronSchedule).map((k, v) => {
                        return <option value={k[1]}>{k[0]}</option>
                      })}
                    </select>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-6'>
                    <label className='fw-bold fs-6 mb-2'>Command</label>
                    <input
                      placeholder='Command'
                      {...formik.getFieldProps('command')}
                      type='text'
                      name='command'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.command && formik.errors.command},
                        {
                          'is-valid': formik.touched.command && !formik.errors.command,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.command && formik.errors.command && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='cron'>{formik.errors.command}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {formik.values.type === CronSchedule.Custom && (
                  <div className='col-12'>
                    <label className='fw-bold fs-6 mb-2'>Custom Schedule</label>
                    <input
                      placeholder='Custom Schedule'
                      {...formik.getFieldProps('schedule')}
                      type='text'
                      name='schedule'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid': formik.touched.schedule && formik.errors.schedule,
                        },
                        {
                          'is-valid': formik.touched.schedule && !formik.errors.schedule,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.schedule && formik.errors.schedule && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='cron'>{formik.errors.schedule}</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className='text-center pt-5'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-domains-modal-action='cancel'
                    onClick={() => setIsOpen(false)}
                    disabled={formik.isSubmitting}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-domains-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    <span className='indicator-label'>Submit</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default CronModal
