import {useState} from 'react'
import {CloudAccountType} from '../../../../servers-list/core/_models'
import {scaleExternalServer, scaleInternalServer} from '../../../../servers-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'

export const ServerScaleButton = ({
  data,
  disabled,
  productId,
  isExternalCloud,
}: {
  data: any
  disabled: boolean
  productId?: string
  isExternalCloud?: boolean
}) => {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)

  async function handleExternalServer() {
    setIsSubmitClicked(true)

    try {
      data['cloud_account_type'] = CloudAccountType.EXTERNAL

      const result = await scaleExternalServer(productId, data)

      if (!result) {
        notifyError('External server scaling failed, please check your integration and retry!')
      } else {
        notifySuccess('External server scaled successfully!')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    } catch (error) {
      notifyError(String(error))
      setIsSubmitClicked(false)
    }
  }

  async function handleInternalServer() {
    setIsSubmitClicked(true)

    try {
      const result = await scaleInternalServer(productId, data)

      if (!result) {
        notifyError('Managed server scaling failed, please retry!')
      } else {
        notifySuccess('Managed server scaled successfully!')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    } catch (error) {
      notifyError(String(error))
      setIsSubmitClicked(false)
    }
  }

  return (
    <>
      <button
        className='btn btn-primary'
        onClick={isExternalCloud ? handleExternalServer : handleInternalServer}
        disabled={disabled}
      >
        Launch{' '}
        {isSubmitClicked && (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        )}
      </button>
      <p />
    </>
  )
}
