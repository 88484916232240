import {useListView} from '../../../core/ListViewProvider'
import {RecordsListGrouping} from './RecordsListGrouping'
import {RecordsListSearchComponent} from './RecordsListSearchComponent'
import {RecordsListToolbar} from './RecordsListToolbar'

const RecordsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <RecordsListSearchComponent />
      <div className='card-toolbar'>
        {selected.length > 0 ? <RecordsListGrouping /> : <RecordsListToolbar />}
      </div>
    </div>
  )
}

export {RecordsListHeader}
