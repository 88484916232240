import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {ProjectEditModalForm} from './ProjectEditModalForm'
import {getProjectById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'

const ProjectEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: project,
    error,
  } = useQuery(
    `${'projects-list'}-project-${itemIdForUpdate}`,
    () => {
      return getProjectById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <ProjectEditModalForm
        isProjectLoading={isLoading}
        project={{project_id: undefined, name: ''}}
      />
    )
  }

  if (!isLoading && !error && project) {
    return <ProjectEditModalForm isProjectLoading={isLoading} project={project} />
  }

  return null
}

export {ProjectEditModalFormWrapper}
