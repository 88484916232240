/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {notifyError, notifyProgress} from '../../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {
  getApplicationBackups,
  initiateApplicationBackup,
} from '../../../../applications-list/core/_requests'
import {
  Backup as BackupModel,
  BackupLocationType,
  BackupStatus,
  RestoreStatus,
} from '../../../../applications-list/core/_models'
import {Col, Row} from 'react-bootstrap'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import {getAvailableStorages} from '../../../../../integrations/storages/storages-list/core/_requests'
import {Storage} from '../../../../../integrations/storages/storages-list/core/_models'

const Backup = () => {
  const {applicationId} = useParams()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [backupLocationType, setBackupLocationType] = useState(BackupLocationType.LOCAL)
  const [storageProvider, setStorageProvider] = useState<Storage | null>(null)
  const [remoteStorages, setRemoteStorages] = useState<Storage[]>([])
  const [backups, setBackups] = useState<BackupModel[]>([])

  useEffect(() => {
    getBackups()
  }, [])

  const getBackups = async () => {
    try {
      const backups = await getApplicationBackups(applicationId ?? '')
      setBackups(backups!)
    } catch (error) {
      console.error('Error fetching backups', error)
    }
  }

  const getStorageProviders = async () => {
    try {
      const providers = await getAvailableStorages()
      if (providers?.length) {
        setRemoteStorages(providers)
      } else {
        setRemoteStorages([])
      }
    } catch (error) {
      setRemoteStorages([])
    }
  }

  const handleTakeBackup = async () => {
    setShowConfirmation(true)
  }

  const handleConfirmBackup = async () => {
    try {
      const success = await initiateApplicationBackup(applicationId ?? '', {
        backupLocationType,
        ...(storageProvider?.id && {
          integrationId: storageProvider.id,
        }),
      })
      if (success === true) {
        notifyProgress('Backup is in progress')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Something went wrong')
      }
    } catch (error) {
      notifyError('Operation ' + error)
    }
  }

  useEffect(() => {
    getStorageProviders()
  }, [applicationId])

  const handleBackupLocationTypeChange = async (type: BackupLocationType) => {
    setBackupLocationType(type)

    if (type === BackupLocationType.LOCAL) {
      setStorageProvider(null)
    }

    if (type === BackupLocationType.REMOTE) {
      setStorageProvider(remoteStorages[0])
    }
  }

  return (
    <div className='container-fluid'>
      <TabHeader heading='Backup' />
      <TabBody>
        <div className='text-primary'>Full file and database backup</div>
        <div className='separator border-secondary my-6'></div>
        <div className='mt-2'></div>

        {backups?.some((backup) => backup.restore_status === RestoreStatus.IN_PROGRESS) ? (
          <div className='alert alert-warning'>
            Application restoration is in progress for backup named{' '}
            {backups.find((backup) => backup.restore_status === RestoreStatus.IN_PROGRESS)?.name}.
          </div>
        ) : (
          backups?.some((backup) => backup.status === BackupStatus.IN_PROGRESS) && (
            <div className='alert alert-warning'>
              Backup in progress. We'll list down all backups once the backup is completed.
            </div>
          )
        )}

        {!remoteStorages.length && (
          <div className='alert alert-warning d-flex align-items-center px-4 py-4 my-8'>
            <div className='d-flex flex-column'>
              <span>
                If you want to use Remote backups, please integrate your platform.{' '}
                <Link to='/integrations/storage/list'>Storage Integration</Link>.
              </span>
            </div>
          </div>
        )}

        <Row className='mb-5 align-items-center'>
          <Col sm='4'>
            <label className='form-label'>Location :</label>
          </Col>
          <Col sm='2'>
            <label className='form-label'>
              <input
                type='radio'
                name='location'
                className='form-check-input'
                value={BackupLocationType.LOCAL}
                checked={backupLocationType === BackupLocationType.LOCAL}
                onChange={() => handleBackupLocationTypeChange(BackupLocationType.LOCAL)}
              />
              {'  '} Local
            </label>
          </Col>
          <Col sm='2'>
            <label className='form-label'>
              <input
                type='radio'
                name='location'
                className='form-check-input'
                value={BackupLocationType.REMOTE}
                disabled={!remoteStorages.length}
                checked={backupLocationType === BackupLocationType.REMOTE}
                onChange={() => handleBackupLocationTypeChange(BackupLocationType.REMOTE)}
              />
              {'  '} Remote
            </label>
          </Col>
        </Row>

        {backupLocationType === BackupLocationType.REMOTE && (
          <Row className='mb-5 align-items-center'>
            <Col sm='4'>
              <label className='form-label'>Storage Provider :</label>
            </Col>
            <Col sm='8'>
              {remoteStorages.length > 0 ? (
                <select
                  name='storage_provider'
                  onChange={(event) => {
                    const selectedProvider = remoteStorages.find(
                      (provider) => provider.id === event.target.value
                    )
                    setStorageProvider(selectedProvider || null)
                  }}
                  className='form-control'
                >
                  {remoteStorages.map((storageProviderElement) => (
                    <option key={storageProviderElement.platform} value={storageProviderElement.id}>
                      {storageProviderElement.platform}
                    </option>
                  ))}
                </select>
              ) : (
                <label className='form-label'>No Storage Added</label>
              )}
            </Col>
          </Row>
        )}

        <button className='btn btn-primary' onClick={handleTakeBackup}>
          Create Backup
        </button>
        <div className='mt-8'></div>
        {showConfirmation && (
          <ConfirmSuccessModal
            setIsOpen={setShowConfirmation}
            title='Are you sure?'
            btnTitle='Backup'
            content='The current state of your application will be stored as a backup.'
            successFunction={handleConfirmBackup}
          />
        )}
      </TabBody>
    </div>
  )
}

export default Backup
