import {Column} from 'react-table'
import {CloudflareDomainActionsCell} from './DomainActionCell'
import {CloudflareDomainCustomHeader} from './DomainCustomHeader'
import {CloudflareDomainNameCell} from './DomainNameCell'
import {CloudflareDomainStatusCell} from './DomainStatusCell'
import {CloudflareDomain} from '../../../core/_models'
import {CloudflareDomainSelectionHeader} from './DomainSelectionHeader'
import {CloudflareDomainSelectionCell} from './DomainSelectionCell'

const cloudflareDomainsColumns: ReadonlyArray<Column<CloudflareDomain>> = [
  {
    Header: (props) => (
      <CloudflareDomainCustomHeader
        tableProps={props}
        title='Domain Name'
        className='min-w-250px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => (
      <CloudflareDomainNameCell cloudflareDomain={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CloudflareDomainCustomHeader tableProps={props} title='Status' className='min-w-250px' />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <CloudflareDomainStatusCell cloudflareDomain={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CloudflareDomainCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <CloudflareDomainActionsCell id={props.data[props.row.index].id} />,
  },
]

export {cloudflareDomainsColumns}
