import {Response} from '../../../../../../_metronic/helpers'

export enum StorageProvider {
  AWS_S3 = 'AWS_S3',
  WASABI = 'WASABI',
}

export type Storage = {
  id: string
  name: string
  description: string
  platform: string // TODO: add enum
  region: string
  bucket_name: string
  api_key?: object
  api_key_val?: string
  access_key_id?: string
  secret_access_key?: string
  version: number
}

export type StoragesQueryResponse = Response<Array<Storage>>

export const initialStorage: Storage = {
  id: '',
  name: '',
  description: '',
  platform: '',
  region: '',
  bucket_name: '',
  version: 1,
}

export const availablePlatforms = [StorageProvider.AWS_S3, StorageProvider.WASABI]
