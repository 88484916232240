import {FC} from 'react'
import {Domain} from '../../../core/_models'

type Props = {
  domain: Domain
}

const DomainStatusCell: FC<Props> = ({domain}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>{domain.domain_status}</div>
  </div>
)

export {DomainStatusCell}
