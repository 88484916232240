import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'

const GitsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddGitModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-git-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={openAddGitModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Git
      </button>
    </div>
  )
}

export {GitsListToolbar}
