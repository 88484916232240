import {Link, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import SSL from './components/SSL'
import Domain from './components/Domain'
import Tabs from '../../../../../utils/Tabs'

const DomainManager = () => {
  const {applicationId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='dc_monitoring_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Domain Manager</h3>
                  </div>
                </div>
              </div>
              <div className='d-flex pt-3'>
                <Tabs
                  tabs={[
                    {name: 'Domain', path: `/application/${applicationId}/domain-manager/domain`},
                    {name: 'SSL', path: `/application/${applicationId}/domain-manager/ssl`},
                  ]}
                />
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route path='domain' element={<Domain />} />
        <Route path='ssl' element={<SSL />} />
        <Route index element={<Navigate to='domain' />} />
      </Route>
    </Routes>
  )
}

export default DomainManager
