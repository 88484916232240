/* eslint-disable */
import {Link, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Backup from './components/Backup'
import BackupSettingsPage from './components/BackupSettings'
import Restore from './components/Restore'
import Tabs from '../../../../../utils/Tabs'

const BackupRestore = () => {
  const {applicationId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Backup & Restore</h3>
                  </div>
                </div>
              </div>
              <div className='d-flex pt-3'>
                <Tabs
                  tabs={[
                    {
                      name: 'Backup',
                      path: `/application/${applicationId}/backup-restore/backup`,
                    },
                    /*
                    {
                      name: 'Schedule Backup',
                      path: `/application/${applicationId}/backup-restore/settings`,
                    }, */
                    {name: 'Restore', path: `/application/${applicationId}/backup-restore/restore`},
                  ]}
                />
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route path='backup' element={<Backup />} />
        <Route path='restore' element={<Restore />} />
        <Route path='settings' element={<BackupSettingsPage />} />
      </Route>

      <Route index element={<Navigate to='backup' />} />
    </Routes>
  )
}

export default BackupRestore
