import {Link, useParams} from 'react-router-dom'

export function DNS() {
  const {cloudflareDomainId} = useParams()

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='dns_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>DNS</h3>
          </div>
        </div>

        <div className='alert alert-primary d-flex align-items-center p-3 mx-8 my-6'>
          <div className='d-flex flex-column'>
            <span className=''>
              <Link
                to={
                  `/integrations/cloudflare/domain/${cloudflareDomainId}/records`
                  // show DNS records through this URL : `/domain/${cloudflareDomainId}/records`
                }
              >
                Click here to check the DNS records of this domain.
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
