import axios, {AxiosResponse} from 'axios'
import {Storage, StoragesQueryResponse} from './_models'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {ApiResponse} from '../../../../auth'

const API_URL = process.env.REACT_APP_THEME_API_URL
const STORAGE_INTEGRATION_URL = `${API_URL}/v1/integration/storage/api-key`

// TODO: add query filter for search bar on BE
// query filter for asc desc columns
const getStorages = (query?: string): Promise<StoragesQueryResponse> => {
  return axios
    .get(`${STORAGE_INTEGRATION_URL}/all`)
    .then((d: AxiosResponse<StoragesQueryResponse>) => d.data)
}

const getAvailableStorages = async (): Promise<Storage[] | undefined> => {
  const response = await axios.get(`${STORAGE_INTEGRATION_URL}/all`)

  if (response.data.code) {
    throw new Error(response.data.message)
  }

  return response.data.data
}

const createStorage = async (storage: Storage) => {
  const response = await axios.post<ApiResponse>(STORAGE_INTEGRATION_URL, storage)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const updateStorage = async (storage: Storage) => {
  const response = await axios.patch(`${STORAGE_INTEGRATION_URL}/${storage.id}`, storage)

  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const deleteStorage = async (storageId: ID) => {
  const response = await axios.delete(`${STORAGE_INTEGRATION_URL}/${storageId}`)
  if (response.data.code) {
    throw new Error(response.data.message)
  }
  return response.data.data
}

const getStorageById = (id: ID): Promise<Storage | undefined> => {
  return axios
    .get(`${STORAGE_INTEGRATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Storage>>) => response.data)
    .then((response: Response<Storage>) => response.data)
}

const deleteSelectedStorages = (storageIds: Array<ID>): Promise<void> => {
  const requests = storageIds.map((id) => axios.delete(`${STORAGE_INTEGRATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getStorages,
  getAvailableStorages,
  createStorage,
  updateStorage,
  deleteStorage,
  getStorageById,
  deleteSelectedStorages,
}
