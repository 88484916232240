import {useFormik} from 'formik'
import * as Yup from 'yup'

import {purgeSelectedURLs} from '../../../../domains-list/core/_requests'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../../utils/NotifyUtils'
import {useState} from 'react'
import clsx from 'clsx'

type CustomPurgeModalProps = {
  title: string
  content: string
  setIsOpen: (isOpen: boolean) => void
  domainId: string
}

const cachePurgeSchema = Yup.object().shape({
  urls: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .matches(
      /^(https?:\/\/[\da-z.-]+(?:\.[a-z.]{2,6})+(?:[/\w.-]*)*\/?(?:[?&][a-z-]+(?:=[^&]*)?)?(?:#[\w-]+)?)(\r?\nhttps?:\/\/[\da-z.-]+(?:\.[a-z.]{2,6})+(?:[/\w.-]*)*\/?(?:[?&][a-z-]+(?:=[^&]*)?)?(?:#[\w-]+)?)*$/,
      'Invalid URL format. URLs should be line-separated.'
    )
    .max(500, 'Maximum 5000 symbols')
    .required('List of urls is required'),
})

const CustomPurgeModal = ({title, content, setIsOpen, domainId}: CustomPurgeModalProps) => {
  const [urls] = useState({urls: ''})

  const formik = useFormik({
    initialValues: urls,
    validationSchema: cachePurgeSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      purgeSelectedURLs(domainId, values.urls.split('\n'))
        .then((data) => {
          notifySuccess('Cache purge successful')
        })
        .catch((error) => {
          notifyError(`Cache purge failed: ${error.message}`)
        })
        .finally(() => {
          setSubmitting(false)
          setIsOpen(false)
        })
    },
  })
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_subscription_delete'
        role='dialog'
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{title}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>

            <div className='modal-body'>
              <form
                id='kt_modal_add_domain_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_domain_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_domain_header'
                  data-kt-scroll-wrappers='#kt_modal_add_domain_scroll'
                  data-kt-scroll-offset='3400px'
                >
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>URLs</label>
                    <p className='text-muted'>{content}</p>
                    <textarea
                      placeholder='Enter the Urls'
                      {...formik.getFieldProps('urls')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.urls && formik.errors.urls},
                        {
                          'is-valid': formik.touched.urls && !formik.errors.urls,
                        }
                      )}
                      name='urls'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    ></textarea>
                    {formik.touched.urls && formik.errors.urls && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.urls}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='text-center pt-5'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-domains-modal-action='cancel'
                    onClick={() => setIsOpen(false)}
                    disabled={formik.isSubmitting}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-domains-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>Submit</span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default CustomPurgeModal
