import {FC} from 'react'
import {Git} from '../../../core/_models'

type Props = {
  git: Git
}

const GitNameCell: FC<Props> = ({git}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>{git.name}</p>
    </div>
  </div>
)

export {GitNameCell}
