import {FC} from 'react'
import {Record} from '../../../core/_models'

type Props = {
  record: Record
}

const RecordValueCell: FC<Props> = ({record}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column mx-2' style={{maxWidth: '350px'}}>
        {record.content}
      </div>
      {record.priority != undefined && record.priority !== 0 && (
        <span
          className={`badge badge-primary fw-bolder me-auto`}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Priority'
        >
          {record.priority}
        </span>
      )}
    </div>
  )
}

export {RecordValueCell}
