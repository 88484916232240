import React, {useState} from 'react'

export function TieredCacheTopology() {
  const [cacheTopology, setCacheTopology] = useState<'smart' | 'disable'>('smart')

  const handleCacheTopologyChange = (topology: 'smart' | 'disable') => {
    setTimeout(() => {
      setCacheTopology(topology)
    }, 500)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10 w-100' id='tiered_cache_topology_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Tiered Cache Topology</h3>
          </div>
        </div>

        <div className='d-flex flex-column mx-8 my-6'>
          {/* Cache Topology */}
          <div className='rounded-3 border p-4 mb-4 d-flex'>
            <div className='me-4 flex-grow-1' style={{flex: '1'}}>
              <h5 className='fw-bolder d-flex align-items-center'>Cache Topology</h5>
              <p className='text-muted'>
                Choose the cache topology for improved performance and efficiency.
              </p>
            </div>
            <div
              className='d-flex border-start flex-column justify-content-center align-items-center'
              style={{flex: '0 0 30%'}}
            >
              <div className='form-check mt-2'>
                <div className='form-check-label mb-4'>
                  <input
                    type='radio'
                    className='form-check-input'
                    checked={cacheTopology === 'smart'}
                    onChange={() => handleCacheTopologyChange('smart')}
                  />
                  Smart Tiered Caching Topology
                </div>
                <div className='form-check-label'>
                  <input
                    type='radio'
                    className='form-check-input'
                    checked={cacheTopology === 'disable'}
                    onChange={() => handleCacheTopologyChange('disable')}
                  />
                  Disable Tiered Cache
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
