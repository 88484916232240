import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {ServerServicesSetting} from '../../../servers-list/core/_models'
import {
  getServerServicesSettingsWithTemplate,
  updateServerServiceSettings,
} from '../../../servers-list/core/_requests'
import {notifyError, notifySuccess} from '../../../../../utils/NotifyUtils'
import * as Yup from 'yup'

export function Settings() {
  const {serverId} = useParams()

  const [basicSettings, setBasicSettings] = useState<ServerServicesSetting[]>()
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [editedSettings, setEditedSettings] = useState<string>('')
  const [editedSettingTemplate, setEditedSettingTemplate] = useState<string>('')
  const [validationError, setValidationError] = useState<string | null>(null)

  useEffect(() => {
    getServerServicesSettingsWithTemplate(serverId ?? '').then((resp) => {
      setBasicSettings(resp)
    })
  }, [serverId])

  const handleEditClick = (index: number) => {
    setValidationError(null)
    setEditedSettingTemplate(basicSettings![index].template)
    if (index === editingIndex) {
      setEditingIndex(null)
    } else {
      setEditingIndex(index)
      setEditedSettings(
        Object.keys(basicSettings![index].settings)
          .map((key) =>
            basicSettings![index].template
              .replace('{{name}}', key)
              .replace('{{value}}', basicSettings![index].settings[key])
          )
          .join('\n')
      )
    }
  }

  const handleSaveClick = async (index: number) => {
    if (index === null) return

    const validationSchema = Yup.object().shape({
      editedSettings: Yup.string().test('isValid', 'Invalid format', (value) => {
        if (!value) return true
        const lines = value.split('\n')
        return lines.every((line) => {
          return new RegExp(
            editedSettingTemplate.replace('{{name}}', '.+').replace('{{value}}', '.+')
          ).test(line.trim())
        })
      }),
    })

    validationSchema
      .validate({editedSettings})
      .then(async () => {
        const success = await updateServerServiceSettings(
          serverId ?? '',
          basicSettings![index].service,
          basicSettings![index].version,
          editedSettings
        )

        if (success) {
          notifySuccess('Settings updated')
          getServerServicesSettingsWithTemplate(serverId ?? '').then((resp) => {
            setBasicSettings(resp)
          })

          setEditingIndex(null)
        } else {
          notifyError('Unable to save the settings')
        }
      })
      .catch((error) => {
        setValidationError(error.message)
      })
  }

  return (
    <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade show active' id='basic_settings' role='tabpanel'>
          <div className='card mb-5 mb-xl-5' id='dc_server_settings_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title'>
                <h3 className='fw-bolder'>Server Services Settings</h3>
              </div>
            </div>

            <div className='card-body'>
              <div className='alert alert-primary d-flex align-items-center p-4 mb-10'>
                <div className='d-flex flex-column'>
                  <span>
                    Please restart the service to get the changed settings into effect. You can
                    restart the services from{' '}
                    <u>
                      <Link to={`/server/${serverId}/services`}>Services</Link>
                    </u>{' '}
                    page.
                  </span>
                </div>
              </div>
              {basicSettings &&
                basicSettings.map((item, index) => {
                  if (!item.settings) return

                  const isEditing = index === editingIndex

                  return (
                    <div key={index} className='row mb-8'>
                      <h4 className='col-lg-3 fw-bold text-dark mb-4'>
                        {item.service}-{item.version}
                      </h4>
                      <div className='col-lg-1'>
                        {isEditing ? (
                          <button
                            className='btn btn-icon fs-10 mb-4 btn-sm'
                            onClick={() => handleEditClick(index)}
                          >
                            <i className='fas fa-times text-danger'></i>
                          </button>
                        ) : (
                          <button
                            className='btn btn-icon fs-10 mb-4 btn-sm'
                            onClick={() => handleEditClick(index)}
                          >
                            <i className='fas fa-pencil-alt text-primary'></i>
                          </button>
                        )}
                      </div>
                      {isEditing ? (
                        <div className='row mb-1'>
                          <div className='col-lg-3'>
                            <textarea
                              rows={5}
                              className='form-control'
                              value={editedSettings}
                              onChange={(e) => {
                                setEditedSettings(e.target.value)
                                setValidationError(null)
                              }}
                            />
                            {validationError && (
                              <div className='text-danger'>{validationError}</div>
                            )}
                          </div>
                          <div className='col-lg-1'>
                            <button
                              className='btn btn-light-success btn-sm'
                              onClick={() => handleSaveClick(index)}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      ) : (
                        Object.keys(item.settings).map((key) => (
                          <div className='row mb-1' key={key}>
                            <label className='col-lg-3 fw-bolder text-muted'>{key}: </label>
                            <div className='col-lg-9'>
                              <span className='fw-bold fs-6 text-dark'>{item.settings[key]}</span>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )
                })}
              {(!basicSettings || !basicSettings.length) && (
                <div className='alert alert-danger d-flex align-items-center p-5 mx-8 my-5'>
                  <div className='d-flex flex-column'>
                    <span>No services installed yet!</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
