import React, {useEffect, useState} from 'react'
import {getServerServicesStatus, restartService} from '../../../servers-list/core/_requests'
import {useParams} from 'react-router-dom'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../utils/NotifyUtils'

const Services = () => {
  const {serverId} = useParams()
  const [servicesStatus, setServicesStatus] = useState<any>(null)
  const [error, setError] = useState<any>()

  const getServicesStatus = () => {
    setServicesStatus(null)
    getServerServicesStatus(serverId)
      .then((data) => {
        setServicesStatus(data)
      })
      .catch((e) => {
        setError('No active service found!')
      })
  }

  const handleRestart = (service: string, version: string) => {
    const id = notifyProgress('Restart in progress')
    restartService(serverId, service, version)
      .then((data) => {
        if (data) {
          notifySuccess('Restart successful', id)
          getServicesStatus()
        }
      })
      .catch((error) => {
        notifyError(`Restart failed: ${error}`, id)
      })
  }

  useEffect(() => {
    getServicesStatus()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
      <div className='d-flex overflow-auto'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Services</h3>
          </div>
        </div>
      </div>
      {error ? (
        <div className='alert alert-danger d-flex align-items-center p-5 mx-8 my-5'>
          <div className='d-flex flex-column'>
            <span>{error}</span>
          </div>
        </div>
      ) : (
        <div className='table-responsive px-5'>
          <table className='table gs-7 gy-7 gx-7'>
            {!servicesStatus?.length ? (
              <div className='w-100 text-center my-10'>
                <span className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            ) : (
              <>
                <thead>
                  <tr className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200'>
                    <th>Service</th>
                    <th>Version</th>
                    <th>Status</th>
                    <th>Control</th>
                  </tr>
                </thead>
                <tbody>
                  {servicesStatus.map((service: any) => {
                    const key = `${service.service}:${service.version}`
                    const value = service.status

                    return (
                      <tr key={key}>
                        <td>{service.service}</td>
                        <td>{service.version}</td>
                        <td>
                          <i
                            className='fa-solid fa-circle'
                            style={{color: value == 'Running' ? ' #00cc00' : '#fd0909'}}
                          ></i>{' '}
                          {value}
                        </td>
                        <td
                          style={{cursor: 'pointer'}}
                          onClick={() => handleRestart(service.service, service.version)}
                        >
                          <i
                            className='fa-solid fa-arrows-rotate'
                            style={{fontSize: '15px', color: '#009ef7'}}
                          ></i>{' '}
                          Restart
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </>
            )}
          </table>
        </div>
      )}
    </div>
  )
}

export default Services
