import {DNSRecordType, initialRecord, Record} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {RecordsListLoading} from '../RecordsListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {useParams} from 'react-router-dom'
import {createRecord, updateRecord} from '../../core/_requests'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../utils/NotifyUtils'
// import {createRecord} from '../../core/_requests'

type Props = {
  isRecordLoading: boolean
  record: Record
}

const editRecordSchema = Yup.object().shape({
  record_name: Yup.string()
    .min(0, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .required('Record name is required'),
})

const RecordEditModalForm: FC<Props> = ({record, isRecordLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {domainId} = useParams()

  //TODO: Make sure that all port, weight, proiority is not passed for all the record types
  const [recordForEdit] = useState<Record>({
    record_name: record.record_name || initialRecord.record_name,
    id: record.id || initialRecord.id,
    record_type: record.record_type || initialRecord.record_type,
    record_data: record.record_data || initialRecord.record_data,
    ttl: record.ttl || initialRecord.ttl,
    is_proxied: record.is_proxied || initialRecord.is_proxied,
  })

  const [selectedRecordType, setSelectedRecordType] = useState<DNSRecordType>(
    recordForEdit.record_type
  )
  const handleRecordTypeChange = (type: DNSRecordType) => {
    formik.setFieldValue('record_type', type)
    setSelectedRecordType(type)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: recordForEdit,
    validationSchema: editRecordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (values.record_type != DNSRecordType.SRV && values.record_type != DNSRecordType.MX) {
        delete values.record_data.priority
      }
      if (values.record_type != DNSRecordType.SRV) {
        delete values.record_data.port
        delete values.record_data.weight
      }
      if (isNotEmpty(values.id)) {
        updateRecord(values.id, values)
          .then((data) => {
            notifySuccess('Record updation successful')
          })
          .catch((error) => {
            notifyError(`Record updation failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      } else {
        const toastId = notifyProgress('Record addition in progress..')
        await createRecord(values, domainId)
          .then((data) => {
            notifySuccess('Record addition successful', toastId)
          })
          .catch((error) => {
            notifyError(`Record addition failed: ${error.message}`, toastId)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_record_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_record_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_record_header'
          data-kt-scroll-wrappers='#kt_modal_add_record_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            <input
              placeholder='Record Name'
              {...formik.getFieldProps('record_name')}
              type='text'
              name='record_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.record_name && formik.errors.record_name},
                {
                  'is-valid': formik.touched.record_name && !formik.errors.record_name,
                }
              )}
              autoComplete='off'
              disabled={
                formik.isSubmitting ||
                isRecordLoading ||
                (record.record_name != undefined && record.record_name.length > 0)
              }
            />
            {formik.touched.record_name && formik.errors.record_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.record_name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Type</label>
            <select
              disabled={record.record_name != undefined && record.record_name.length > 0}
              className='form-select form-select-solid form-select-lg'
              {...formik.getFieldProps('record_type')}
              name='record_type'
              value={selectedRecordType}
              onChange={(e: any) => {
                handleRecordTypeChange(e.target.value)
              }}
            >
              {Object.values(DNSRecordType)?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {formik.touched.record_type && formik.errors.record_type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.record_type}</div>
              </div>
            )}

            {formik.touched.record_type && formik.errors.record_type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.record_type}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Value</label>
            <input
              placeholder='Record Value'
              {...formik.getFieldProps('record_data.value')}
              type='text'
              name='record_data.value'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {
                  'is-invalid':
                    formik.touched.record_data?.value && formik.errors.record_data?.value,
                },
                {
                  'is-valid':
                    formik.touched.record_data?.value && !formik.errors.record_data?.value,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isRecordLoading}
            />
            {formik.touched.record_data?.value && formik.errors.record_data?.value && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.record_data?.value}</span>
                </div>
              </div>
            )}
          </div>
          {(selectedRecordType === DNSRecordType.MX ||
            selectedRecordType === DNSRecordType.SRV) && (
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Priority</label>
              <input
                placeholder='Priority'
                {...formik.getFieldProps('record_data.priority')}
                type='number'
                name='record_data.priority'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.record_data?.priority && formik.errors.record_data?.priority,
                  },
                  {
                    'is-valid':
                      formik.touched.record_data?.priority && !formik.errors.record_data?.priority,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isRecordLoading}
              />
              {formik.touched.record_data?.priority && formik.errors.record_data?.priority && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.record_data?.priority}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedRecordType === DNSRecordType.SRV && (
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Weight</label>
              <input
                placeholder='Weight'
                {...formik.getFieldProps('record_data.weight')}
                type='number'
                name='record_data.weight'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.record_data?.weight && formik.errors.record_data?.weight,
                  },
                  {
                    'is-valid':
                      formik.touched.record_data?.weight && !formik.errors.record_data?.weight,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isRecordLoading}
              />
              {formik.touched.record_data?.weight && formik.errors.record_data?.weight && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.record_data?.weight}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedRecordType === DNSRecordType.SRV && (
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Port</label>
              <input
                placeholder='Port'
                {...formik.getFieldProps('record_data.port')}
                type='number'
                name='record_data.port'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.record_data?.port && formik.errors.record_data?.port,
                  },
                  {
                    'is-valid':
                      formik.touched.record_data?.port && !formik.errors.record_data?.port,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isRecordLoading}
              />
              {formik.touched.record_data?.port && formik.errors.record_data?.port && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.record_data?.port}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-records-modal-action='cancel'
            disabled={formik.isSubmitting || isRecordLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-records-modal-action='submit'
            disabled={isRecordLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isRecordLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isRecordLoading) && <RecordsListLoading />}
    </>
  )
}

export {RecordEditModalForm}
