import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ApplicationObjectCache} from '../../../../applications-list/core/_models'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {
  getObjectCacheStateByApplication,
  purgeApplicationObjectCache,
  updateApplicationObjectCacheState,
} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const ObjectCache = () => {
  const {applicationId} = useParams()

  const [objectCacheInfo, setObjectCacheInfo] = useState<ApplicationObjectCache | undefined>({
    state: false,
  })

  const [isUpdating, setIsUpdating] = useState(false)

  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)
  const [isPurgeCacheButtonActive, setIsPurgeCacheButtonActive] = useState(false)
  const [isPurgeCacheConfirmationVisible, setIsPurgeCacheConfirmationVisible] = useState(false)

  useEffect(() => {
    getObjectCacheStateByApplication(applicationId ?? '').then((resp) => {
      setObjectCacheInfo(resp)
      setIsPurgeCacheButtonActive(resp!.state)
    })
  }, [])

  const handleToggleChange = () => {
    setObjectCacheInfo({
      ...objectCacheInfo!,
      state: !objectCacheInfo!.state,
    })

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  const handleSaveButtonClick = () => {
    setIsUpdating(true)

    updateApplicationObjectCacheState(applicationId ?? '', objectCacheInfo!.state).then(
      (response) => {
        if (response) {
          notifySuccess('Object cache is updated')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          notifyError('Something went wrong')
        }
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      }
    )
  }

  const handleConfirmPurgeCache = () => {
    setIsPurgeCacheConfirmationVisible(false)
    setIsUpdating(true)

    purgeApplicationObjectCache(applicationId ?? '').then((response) => {
      if (response) {
        notifySuccess('Cache purge successful')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Something went wrong')
      }
      setIsUpdating(false)
    })
  }

  return (
    <div className='container-fluid' id='object_cache_tab'>
      <TabHeader heading='Object Cache' />
      <TabBody>
        <div className='row mb-10'>
          <div className='col-lg-12 mt-3'>
            <div className='row align-items-center'>
              <div className='col-lg-2'>Object Cache Status:</div>
              <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-25px'
                  type='checkbox'
                  checked={objectCacheInfo!.state}
                  onChange={handleToggleChange}
                  style={{
                    backgroundColor: objectCacheInfo!.state ? 'green' : 'grey',
                    borderColor: objectCacheInfo!.state ? 'green' : 'grey',
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <button
                  type='button'
                  className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                  onClick={handleSaveButtonClick}
                  disabled={!isSaveButtonActive || isUpdating}
                >
                  <span className='indicator-label'>
                    {isUpdating ? 'Please wait . . .' : 'Save'}
                  </span>
                  {isUpdating && (
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className='separator border-secondary my-6'></div>
          <div className='mt-2'>
            <button
              type='button'
              className={`btn ${isUpdating ? 'btn-light' : 'btn-success'} me-10`}
              onClick={() => setIsPurgeCacheConfirmationVisible(true)}
              disabled={!isPurgeCacheButtonActive || isUpdating}
            >
              <span className='indicator-label'>
                {isUpdating ? 'Please wait . . .' : 'Purge Cache'}
              </span>
              {isUpdating && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </TabBody>
      {isPurgeCacheConfirmationVisible && (
        <ConfirmSuccessModal
          setIsOpen={setIsPurgeCacheConfirmationVisible}
          title='Are you sure?'
          btnTitle='Purge Cache'
          content='Proceed with the cache purge?'
          successFunction={handleConfirmPurgeCache}
        />
      )}
    </div>
  )
}

export default ObjectCache
