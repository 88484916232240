import {FC} from 'react'
import {Record} from '../../../core/_models'

type Props = {
  record: Record
}

const RecordValueCell: FC<Props> = ({record}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column mx-2'>{record.record_data.value}</div>
      {record.record_data.priority != undefined && record.record_data.priority !== 0 && (
        <span
          className={`badge badge-primary fw-bolder me-auto`}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Priority'
        >
          {record.record_data.priority}
        </span>
      )}
    </div>
  )
}

export {RecordValueCell}
