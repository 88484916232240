import React from 'react'
import {Link} from 'react-router-dom'

type TabProps = {
  tabs: {name: string; path: string}[]
}

const Tabs = ({tabs}: TabProps) => {
  return (
    <div className='pb-5 px-9'>
      <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6'>
        {tabs.map((tab) => {
          return (
            <li className='nav-item w-md-200px me-0' key={tab.name}>
              <Link
                to={tab.path}
                className={`nav-link ` + (window.location.pathname === tab.path && 'active')}
              >
                {tab.name}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Tabs
