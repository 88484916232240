import {FC} from 'react'
import {Domain} from '../../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  domain: Domain
}

const DomainNameCell: FC<Props> = ({domain}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/domain/${domain.id}/records`} className='text-gray-800 text-hover-primary mb-1'>
        {domain.domain_name}
      </Link>
    </div>
  </div>
)

export {DomainNameCell}
