import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {AccessDetailsDto, FTPDetails} from '../../../applications-list/core/_models'
import {getAccessDetails, getFTPDetails} from '../../../applications-list/core/_requests'
import PasswordField from '../../../../../utils/PasswordField'

const AccessDetails = () => {
  const {applicationId} = useParams()

  const [accessDetails, setAccessDetails] = useState<AccessDetailsDto>()
  const [ftpDetails, setFTPDetails] = useState<FTPDetails>()

  useEffect(() => {
    getAccessDetails(applicationId ?? '').then((resp) => {
      setAccessDetails(resp)
    })

    getFTPDetails(applicationId ?? '').then((resp) => {
      setFTPDetails(resp)
    })
  }, [])

  return (
    <div className='card mb-3 mb-xl-10' id='dc_access_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Services</h3>
        </div>
      </div>
      {accessDetails ? (
        accessDetails.accessDetails.map((accessDetail) => {
          return (
            <>
              <div className='px-9 py-4'>{accessDetail.service}</div>
              {Object.keys(accessDetail.details).map((detail) => {
                return (
                  <div className='px-9' key={detail}>
                    <div className='row mb-4'>
                      <label className='col-lg-4 fw-bold text-muted'>{detail}: </label>
                      <div className='col-lg-8'>
                        {accessDetail.details[detail]['type'] === 'password' ? (
                          <PasswordField password={accessDetail.details[detail]['value'] ?? ''} />
                        ) : (
                          <span className='fw-bolder fs-6 text-dark'>
                            {accessDetail.details[detail]['value']}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}

              <div className='separator border-secondary my-6'></div>

              <div className='px-9 py-4'>SFTP Details</div>
              <div className='px-9'>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-bold text-muted'>Server IP</label>
                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{ftpDetails?.ip}</span>
                  </div>
                </div>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-bold text-muted'>Username</label>
                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>{ftpDetails?.username}</span>
                  </div>
                </div>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-bold text-muted'>Password</label>
                  <div className='col-lg-8 fv-row'>
                    <PasswordField password={ftpDetails?.password ?? ''} />
                  </div>
                </div>
              </div>
            </>
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}

export default AccessDetails
