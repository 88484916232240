import {initialStorage, Storage, availablePlatforms, StorageProvider} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {StoragesListLoading} from '../StoragesListLoading'
import {createStorage, updateStorage} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'

type Props = {
  isStorageLoading: boolean
  storage: Storage
}

const editStorageSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .matches(/^[a-zA-Z0-9_-]+$/, 'Only letters, numbers, underscores, and dashes are allowed')
    .required('Name is required'),
  description: Yup.string().max(2000, 'Maximum 2000 symbols'),
  region: Yup.string().max(50, 'Maximum 50 symbols').required(),
  bucket_name: Yup.string().max(200, 'Maximum 200 symbols').required(),
  access_key_id: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(200, 'Maximum 200 symbols')
    .required(),
  secret_access_key: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(200, 'Maximum 200 symbols')
    .required(),
})

const StorageEditModalForm: FC<Props> = ({storage, isStorageLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [selectedStorageProvider, setSelectedStorageProvider] = useState<string>()
  const {refetch} = useQueryResponse()

  const [storageForEdit] = useState<Storage>({
    ...storage,
    name: storage.name || initialStorage.name,
    description: storage.description || initialStorage.description,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleStorageProviderChange = (
    event: React.MouseEvent<HTMLImageElement>,
    provider: string
  ) => {
    formik.setFieldValue('platform', provider)
    setSelectedStorageProvider(provider)
  }

  const formik = useFormik({
    initialValues: storageForEdit,
    validationSchema: editStorageSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      values.api_key = {
        access_key_id: values.access_key_id,
        secret_access_key: values.secret_access_key,
        region: values.region,
        bucket_name: values.bucket_name,
      }

      if (isNotEmpty(values.id)) {
        updateStorage(values)
          .then((data) => {
            notifySuccess('Storage updation successful')
          })
          .catch((error) => {
            notifyError(`Storage updation failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      } else {
        await createStorage(values)
          .then((data) => {
            notifySuccess('Storage addition successful')
          })
          .catch((error) => {
            notifyError(`Storage addition failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_storage_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_storage_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_storage_header'
          data-kt-scroll-wrappers='#kt_modal_add_storage_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Storage Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isStorageLoading}
              style={{marginLeft: 14 + 'px'}}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Description</label>
            {/* end::Label */}

            {/* begin::Text */}
            <textarea
              placeholder='Write something about the storage'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isStorageLoading}
              style={{marginLeft: 14 + 'px'}}
            ></textarea>
            {/* end::Text */}
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.description}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {!itemIdForUpdate && (
            <div
              className='row w-100 mb-5'
              data-kt-buttons='true'
              data-kt-buttons-target='.form-check-image, .form-check-input'
            >
              <label className='required fw-bold fs-6 mb-2'>Storage Provider</label>
              {availablePlatforms?.map((option: any) => (
                <div className='col'>
                  <label
                    className={`form-check-image ${selectedStorageProvider === option && 'active'}`}
                  >
                    <div className='form-check-wrapper'>
                      <img
                        src={`/media/storageproviders/${option}.jpg`}
                        onClick={(e) => handleStorageProviderChange(e, option)}
                      />
                    </div>
                  </label>
                </div>
              ))}
            </div>
          )}

          <div className='row w-100 mb-7'>
            {/* begin::Input group */}
            <div className='col-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Access Key ID</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Access Key ID'
                {...formik.getFieldProps('access_key_id')}
                type='text'
                name='access_key_id'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.access_key_id && formik.errors.access_key_id},
                  {
                    'is-valid': formik.touched.access_key_id && !formik.errors.access_key_id,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isStorageLoading}
                style={{marginLeft: 5 + 'px'}}
              />
              {formik.touched.access_key_id && formik.errors.access_key_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.access_key_id}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='col-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Secret Access Key</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Secret Access Key'
                {...formik.getFieldProps('secret_access_key')}
                type='text'
                name='secret_access_key'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.secret_access_key && formik.errors.secret_access_key,
                  },
                  {
                    'is-valid':
                      formik.touched.secret_access_key && !formik.errors.secret_access_key,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isStorageLoading}
                style={{marginLeft: 5 + 'px'}}
              />
              {formik.touched.secret_access_key && formik.errors.secret_access_key && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.secret_access_key}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}
          </div>

          <div className='row w-100 mb-7'>
            {/* begin::Input group */}
            <div className='col-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Region</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Region'
                {...formik.getFieldProps('region')}
                type='text'
                name='region'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.region && formik.errors.region},
                  {
                    'is-valid': formik.touched.region && !formik.errors.region,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isStorageLoading}
                style={{marginLeft: 5 + 'px'}}
              />
              {formik.touched.region && formik.errors.region && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.region}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='col-6'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Bucket Name</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Bucket Name'
                {...formik.getFieldProps('bucket_name')}
                type='text'
                name='bucket_name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.bucket_name && formik.errors.bucket_name,
                  },
                  {
                    'is-valid': formik.touched.bucket_name && !formik.errors.bucket_name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isStorageLoading}
                style={{marginLeft: 5 + 'px'}}
              />
              {formik.touched.bucket_name && formik.errors.bucket_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bucket_name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-storages-modal-action='cancel'
            disabled={formik.isSubmitting || isStorageLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-storages-modal-action='submit'
            disabled={
              isStorageLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isStorageLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isStorageLoading) && <StoragesListLoading />}
    </>
  )
}

export {StorageEditModalForm}
