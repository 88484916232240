import {Column} from 'react-table'
import {CloudActionsCell} from './CloudActionsCell'
import {CloudCustomHeader} from './CloudCustomHeader'
import {CloudDescriptionCell} from './CloudDescriptionCell'
import {CloudNameCell} from './CloudNameCell'
import {Cloud} from '../../../core/_models'
import {CloudPlatformCell} from './CloudPlatformCell'
import {CloudVersionCell} from './CloudVersionCell'

const cloudsColumns: ReadonlyArray<Column<Cloud>> = [
  {
    Header: (props) => (
      <CloudCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <CloudNameCell cloud={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CloudCustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    id: 'description',
    Cell: ({...props}) => <CloudDescriptionCell cloud={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CloudCustomHeader tableProps={props} title='Platform' className='min-w-125px' />
    ),
    id: 'platform',
    Cell: ({...props}) => <CloudPlatformCell cloud={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CloudCustomHeader tableProps={props} title='Version' className='min-w-125px' />
    ),
    id: 'version',
    Cell: ({...props}) => <CloudVersionCell cloud={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CloudCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CloudActionsCell id={props.data[props.row.index].id} />,
  },
]

export {cloudsColumns}
