import React, {useEffect, useState} from 'react'
import {CloudflareSettings} from '../../../domains-list/core/_models'
import {getCloudflareSetting, setCloudflareSetting} from '../../../domains-list/core/_requests'
import {notifyError} from '../../../../../../utils/NotifyUtils'

type CloudflareDropdownSettingProps = {
  title: string
  description: string
  setting: CloudflareSettings
  options: [option: string, key: string][]
  cloudflareDomainId: string | undefined
  isPro?: boolean
  isIntegerAPI?: boolean
}

const CloudflareDropdownSetting = ({
  title,
  description,
  setting,
  cloudflareDomainId,
  options,
  isPro,
  isIntegerAPI,
}: CloudflareDropdownSettingProps) => {
  const [settingValue, setSettingValue] = useState<string>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getCloudflareSetting(cloudflareDomainId ?? '', setting).then((resp) => {
      setSettingValue(resp != undefined ? resp : '')
      setLoading(false)
    })
  }, [])

  const handleSettingChange = (changedValue: string) => {
    setLoading(true)
    setCloudflareSetting(
      cloudflareDomainId ?? '',
      setting,
      isIntegerAPI ? +changedValue : changedValue
    )
      .then((resp) => {
        setSettingValue(resp != undefined ? resp : false)
      })
      .catch((error) => {
        console.log(error.message)
        notifyError(error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className='rounded-3 border p-4 mb-4 d-flex'>
      <div className='me-4 flex-grow-1' style={{flex: '1'}}>
        <h5 className='fw-bolder d-flex align-items-center'>
          {title}
          {isPro && <span className='badge badge-warning ms-2'>REQUIRES PRO</span>}
        </h5>
        <p className='text-muted'>{description}</p>
      </div>
      <div
        className='d-flex border-start flex-row justify-content-around align-items-center'
        style={{flex: '0 0 20%'}}
      >
        <select
          className='form-select'
          style={{width: '80%'}}
          value={settingValue}
          onChange={(e) => handleSettingChange(e.target.value)}
        >
          {options.map((option) => {
            return (
              <option key={option[1]} value={option[1]}>
                {option[0]}
              </option>
            )
          })}
        </select>
        <div style={{width: '20px'}}>
          {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>
    </div>
  )
}

export default CloudflareDropdownSetting
