import {Response} from '../../../../../_metronic/helpers'

export enum DNSRecordType {
  A = 'A',
  AAAA = 'AAAA',
  CNAME = 'CNAME',
  MX = 'MX',
  TXT = 'TXT',
  // NS = 'NS',
  SRV = 'SRV',
}

export const initialRecord: Record = {
  id: '',
  record_data: {
    value: '',
    priority: 0,
    weight: 0,
    port: 0,
  },
  record_type: DNSRecordType.A,
  record_name: '',
  ttl: 600,
  is_proxied: false,
}

export type RecordsQueryResponse = Response<Array<Record>>

export type Record = {
  id: string
  record_type: DNSRecordType
  record_name: string
  record_data: {
    value?: string
    priority?: number
    weight?: number
    port?: number
  }
  ttl: number
  is_proxied: boolean
}
