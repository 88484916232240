import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ApplicationPageCache, PurgeType} from '../../../../applications-list/core/_models'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import {
  getPageCacheStateByApplication,
  purgeApplicationCache,
  updateApplicationPageCacheState,
} from '../../../../applications-list/core/_requests'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const PageCache = () => {
  const {applicationId} = useParams()

  const [pageCacheInfo, setPageCacheInfo] = useState<ApplicationPageCache | undefined>({
    state: false,
  })

  const [isUpdating, setIsUpdating] = useState(false)

  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  const [isPurgeCacheButtonActive, setIsPurgeCacheButtonActive] = useState(false)
  const [isPurgeAllCacheButtonActive, setIsPurgeAllCacheButtonActive] = useState(false)
  const [isPurgeCacheConfirmationVisible, setIsPurgeCacheConfirmationVisible] = useState(false)

  const [urlInput, setUrlInput] = useState('')
  const [validationError, setValidationError] = useState<string | null>(null)
  const [purgeType, setPurgeType] = useState<PurgeType>()

  useEffect(() => {
    getPageCacheStateByApplication(applicationId ?? '').then((resp) => {
      setPageCacheInfo(resp)
      setIsPurgeAllCacheButtonActive(resp!.state)
    })
  }, [])

  const handleToggleChange = () => {
    setPageCacheInfo({
      ...pageCacheInfo!,
      state: !pageCacheInfo!.state,
    })

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  const handleSaveButtonClick = () => {
    setIsUpdating(true)

    updateApplicationPageCacheState(applicationId ?? '', pageCacheInfo!.state).then((response) => {
      if (response) {
        notifySuccess('Page cache is updated')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Something went wrong')
      }
      setIsUpdating(false)
      setIsSaveButtonActive(false)
    })
  }

  const handleUrlInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value

    setUrlInput(value)
    validateUrls(value)
  }

  const stringToUrls = (input: string): string[] => {
    return input.split('\n').map((url) => url.trim())
  }

  const validateUrls = (input: string) => {
    const urls = stringToUrls(input)

    if (!urls.some((url) => url.trim() !== '')) {
      setValidationError('Please enter at least one URL.')
      setIsPurgeCacheButtonActive(false)
      return
    }

    for (const url of urls) {
      if (!url.trim().startsWith('http://') && !url.trim().startsWith('https://')) {
        setValidationError('Invalid URL format. URLs must start with http(s)://')
        setIsPurgeCacheButtonActive(false)
        return
      }
    }

    setValidationError(null)
    setIsPurgeCacheButtonActive(true)
  }

  const handleConfirmPurgeCache = async () => {
    setIsPurgeCacheConfirmationVisible(false)
    setIsUpdating(true)

    let body

    if (purgeType === PurgeType.SELECTED) {
      body = {urls: stringToUrls(urlInput)}
    }

    const response = await purgeApplicationCache(applicationId ?? '', body)

    if (response) {
      notifySuccess('Cache purge successful')
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } else {
      notifyError('Something went wrong')
    }

    setIsUpdating(false)
  }

  return (
    <div className='container-fluid' id='page_cache_tab'>
      <TabHeader heading='Page Cache' />
      <TabBody>
        <div className='row mb-10'>
          <div className='col-lg-12 mt-3'>
            <div className='row align-items-center'>
              <div className='col-lg-2'>Page Cache Status:</div>
              <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-25px'
                  type='checkbox'
                  checked={pageCacheInfo!.state}
                  onChange={handleToggleChange}
                  style={{
                    backgroundColor: pageCacheInfo!.state ? 'green' : 'grey',
                    borderColor: pageCacheInfo!.state ? 'green' : 'grey',
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <button
                  type='button'
                  className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                  onClick={handleSaveButtonClick}
                  disabled={!isSaveButtonActive || isUpdating}
                >
                  <span className='indicator-label'>
                    {isUpdating ? 'Please wait . . .' : 'Save'}
                  </span>
                  {isUpdating && (
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className='separator border-secondary my-6'></div>
          <div className='mt-2'>
            <textarea
              className='form-control mb-3'
              rows={5}
              placeholder='Enter URLs here...'
              onChange={handleUrlInputChange}
              style={{minHeight: '150px'}}
              disabled={!isPurgeAllCacheButtonActive}
            ></textarea>
          </div>
          {validationError && <div className='text-danger mb-2'>{validationError}</div>}
          <div className='mt-2'>
            <button
              type='button'
              className={`btn ${isUpdating ? 'btn-light' : 'btn-success'} me-10`}
              onClick={() => {
                setPurgeType(PurgeType.SELECTED)
                setIsPurgeCacheConfirmationVisible(true)
              }}
              disabled={!isPurgeCacheButtonActive || isUpdating}
            >
              <span className='indicator-label'>
                {isUpdating ? 'Please wait . . .' : 'Purge Cache'}
              </span>
              {isUpdating && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <button
              type='button'
              className={`btn ${isUpdating ? 'btn-light' : 'btn-success'}`}
              onClick={() => {
                setPurgeType(PurgeType.ALL)
                setIsPurgeCacheConfirmationVisible(true)
              }}
              disabled={!isPurgeAllCacheButtonActive || isUpdating}
            >
              <span className='indicator-label'>
                {isUpdating ? 'Please wait . . .' : 'Purge All'}
              </span>
              {isUpdating && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </TabBody>

      {isPurgeCacheConfirmationVisible && (
        <ConfirmSuccessModal
          setIsOpen={setIsPurgeCacheConfirmationVisible}
          title='Are you sure?'
          btnTitle={purgeType === PurgeType.SELECTED ? 'Purge Cache' : 'Purge All'}
          content={
            purgeType === PurgeType.SELECTED
              ? 'Proceed with the cache purge?'
              : '(Not Recommended) Do you really want to purge all cache?'
          }
          successFunction={handleConfirmPurgeCache}
        />
      )}
    </div>
  )
}

export default PageCache
