import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ProjectsListWrapper} from './projects-list/ProjectsList'
import KnowledgeBaseAccordion from '../common/knowledgebase/KnowledgeBaseAccordion'

const projectsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Project Management',
    path: '/projects/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProjectsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={projectsBreadcrumbs}>Project List</PageTitle>
              <KnowledgeBaseAccordion
                body={`Projects are available to group/tag the applications so that there is a logical tracking of which application belongs to which project
                <br>
                <br>
                The project list page provides the following details
                <ol>
                  <li><b>Project Name</b>: The project name assigned to the project by the user</li>
                  <li><b>Project Description</b>: The project description given by the user</li>
                  <li><b>Project Actions</b>: The available project actions include edit and delete</li>
                </ol>
                The <mark className="bg-primary"><b>Add Project</b></mark> button is to be used to create a new project.`}
              />
              <ProjectsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/projects/list' />} />
    </Routes>
  )
}

export default ProjectsPage
