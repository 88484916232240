import {useQuery} from 'react-query'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {ServerEditModalForm} from './ServerEditModalForm'
import {getServerById} from '../../core/_requests'
import {useListView} from '../../core/ListViewProvider'

const ServerEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: server,
    error,
  } = useQuery(
    `${'servers-list'}-server-${itemIdForUpdate}`,
    () => {
      return getServerById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <ServerEditModalForm isServerLoading={isLoading} server={{server_id: undefined, name: ''}} />
    )
  }

  if (!isLoading && !error && server) {
    return <ServerEditModalForm isServerLoading={isLoading} server={server} />
  }

  return null
}

export {ServerEditModalFormWrapper}
