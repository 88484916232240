import {FC} from 'react'
import {Storage} from '../../../core/_models'

type Props = {
  storage: Storage
}

const StoragePlatformCell: FC<Props> = ({storage}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>{storage.platform}</p>
    </div>
  </div>
)

export {StoragePlatformCell}
