import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  getPriceDifference,
  getScalingOptions,
  getServerById,
} from '../../../../servers-list/core/_requests'
import {
  CloudAccountType,
  CloudProvider,
  CurrencySymbol,
  InstanceOptionDetails,
  ServerState,
} from '../../../../servers-list/core/_models'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'
import {ServerScaleButton} from './ServerScaleButton'

const Scale = () => {
  const {serverId} = useParams()

  const [serverState, setServerState] = useState<ServerState>()
  const [isExternalCloud, setIsExternalCloud] = useState(false)

  const [instanceOptions, setInstanceOptions] = useState<InstanceOptionDetails>()
  const [selectedInstanceOption, setSelectedInstanceOption] = useState<string>()

  const handleInstanceOptionChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedInstanceOption(event.target.value)

    const priceDifference = await getPriceDifference(serverId, Number(event.target.value))
    setPriceDifference(priceDifference.data)
  }

  const [priceDifference, setPriceDifference] = useState(0)

  useEffect(() => {
    getServerById(serverId).then((resp) => {
      setServerState(resp?.server_state)
      setIsExternalCloud(resp?.cloud_account_type === CloudAccountType.EXTERNAL)
    })

    getScalingOptions(serverId).then((resp) => {
      setInstanceOptions(resp.data)
    })
  }, [])

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='basic_data_tab'>
      <TabHeader heading='Scale' />
      <TabBody>
        {!instanceOptions ? (
          <div className='alert alert-warning d-flex align-items-center p-4 mb-10'>
            <div className='d-flex flex-column'>
              <h5 className='mb-1'>* Scaling not available for custom instances!</h5>
              <span>
                Since custom instances are deployed by users, they cannot be controlled for server
                level operations like scaling.
              </span>
            </div>
          </div>
        ) : (
          serverState !== ServerState.STOPPED && (
            <div className='alert alert-warning d-flex align-items-center p-4 mb-10'>
              <div className='d-flex flex-column'>
                <h5 className='mb-1'>* Server should be stopped first!</h5>
                <span>
                  Since scaling updates the resources, it requires being stopped to proceed.
                </span>
              </div>
            </div>
          )
        )}

        {serverState === ServerState.STOPPED && (
          <div className='w-100'>
            <select
              className='form-select form-select-solid form-select-lg'
              name='instance_option_id'
              value={selectedInstanceOption ?? ''}
              onChange={handleInstanceOptionChange}
            >
              <option value=''>Select an option</option>
              {instanceOptions?.instances?.map((option: any) => (
                <option key={option.id} value={option.id.toString()}>
                  {option.cpu} CPU, {option.ram} MB RAM
                  {option.cloud_provider === CloudProvider.DIGITALOCEAN
                    ? `, ${option.storage_type}`
                    : option.cloud_provider === CloudProvider.VULTR
                    ? `, ${option.instance_category}`
                    : null}
                </option>
              ))}
            </select>

            {!isExternalCloud && (
              <>
                <p className='text-gray-800 fw-normal mt-8 mb-6 fs-3 fw-bold'>
                  Price Difference:{' '}
                  <span className='fs-4 fw-normal'>
                    {CurrencySymbol.USD}
                    {priceDifference} + 18% GST
                  </span>
                </p>

                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-4 mb-5'>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <div className='fs-6 text-gray-600'>
                        * For downscaling (if available), we'll extend the subscription end date as
                        per the price difference.
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isExternalCloud && <p />}
            <ServerScaleButton
              data={{instance_option_id: Number(selectedInstanceOption)}}
              disabled={priceDifference === 0 || serverState !== ServerState.STOPPED}
              productId={serverId}
              isExternalCloud={isExternalCloud}
            />
          </div>
        )}
      </TabBody>
    </div>
  )
}

export default Scale
