import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {GitsListWrapper} from './gits-list/GitsList'
import KnowledgeBaseAccordion from '../../common/knowledgebase/KnowledgeBaseAccordion'

const gitsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Git Management',
    path: '/integrations/git/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const GitsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={gitsBreadcrumbs}>Git List</PageTitle>
              <KnowledgeBaseAccordion
                body={`This page is to integrate users git accounts with Cloudphant account. We currently support Github, Gitlab and Bitbucket integrations.
                <br>
                <br>
                The project list page provides the following details
                <ol>
                  <li><b>Name</b>: The name assigned to the git integration by the user</li>
                  <li><b>Description</b>: The description given by the user</li>
                  <li><b>Platform</b>: The git platform to which the integration belong</li>
                  <li><b>Version</b>: The version would increment if you make any changes to the integration details</li>
                  <li><b>Actions</b>: The actions include edit and delete.</li>
                </ol>
                The <mark className="bg-primary"><b>Add Git</b></mark> button is to be used to create a new git integration.`}
              />
              <GitsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/integrations/git/list' />} />
    </Routes>
  )
}

export default GitsPage
