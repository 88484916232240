import {FC} from 'react'
import {Cloud} from '../../../core/_models'

type Props = {
  cloud: Cloud
}

const CloudDescriptionCell: FC<Props> = ({cloud}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <p className='text-gray-800 mb-1'>{cloud.description}</p>
    </div>
  </div>
)

export {CloudDescriptionCell}
