type ConfirmSuccessModalProps = {
  title: string
  btnTitle: string
  content: string
  successFunction: () => void
  setIsOpen: (isOpen: boolean) => void
}

const ConfirmSuccessModal = ({
  title,
  btnTitle,
  content,
  successFunction,
  setIsOpen,
}: ConfirmSuccessModalProps) => {
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_subscription_restart'
        role='dialog'
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{title}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>

            <div className='modal-body text-start'>
              <p>{content}</p>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={() => setIsOpen(false)}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-success'
                onClick={() => {
                  successFunction()
                  setIsOpen(false)
                }}
              >
                {btnTitle}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ConfirmSuccessModal
