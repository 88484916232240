import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getNameserversByDomain} from '../../../domains-list/core/_requests'
import {Domain} from '../../../domains-list/core/_models'

type NsRecordsPropsType = {
  domain: Domain
}

const NsRecords = ({domain}: NsRecordsPropsType) => {
  const [nameServers, setNameServers] = useState<string[]>()

  useEffect(() => {
    getNameserversByDomain(domain.id).then((ns) => {
      setNameServers(ns)
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <h4 className='fs-1 text-gray-800 w-bolder mb-6'>Update Nameservers</h4>
        <p className='fs-6 text-gray-600 mb-6'>
          Your Nameservers are still not pointing to our systems, please update the following
          nameservers with your registrar
        </p>
        <div className='border border-1 border-secondary rounded p-3 bg-primary'>
          {nameServers?.map((ns, index) => {
            return <p className='fw-semibold fs-5 mb-2 text-white'>{ns}</p>
          })}
        </div>
        <p className='fs-6 text-gray-600 mb-6 mt-6'>
          Already updated? Sit back and relax for the records to propogate! It can usually take from
          a few minutes to 48 hours to reflect
        </p>
      </div>
    </div>
  )
}

export default NsRecords
