/* eslint-disable */
import React from 'react'
import {Link, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import SwapSpace from './components/SwapSpace'
import Networking from './components/Networking'
import Tabs from '../../../../../utils/Tabs'

const Optimizations = () => {
  const {serverId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
            <div className='d-flex overflow-auto'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Optimizations</h3>
                </div>
              </div>
            </div>
            <div className='d-flex pt-3'>
              <Tabs
                tabs={[
                  {name: 'Swap Space', path: `/server/${serverId}/optimizations/swap-space`},
                  {name: 'Networking', path: `/server/${serverId}/optimizations/networking`},
                ]}
              />
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path='swap-space' element={<SwapSpace />} />
        <Route path='networking' element={<Networking />} />
      </Route>

      <Route index element={<Navigate to='swap-space' />} />
    </Routes>
  )
}

export default Optimizations
