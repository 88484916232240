import {DNSRecordType, initialRecord, Record} from '../../core/_models'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import {isNotEmpty} from '../../../../../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {RecordsListLoading} from '../RecordsListLoading'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useListView} from '../../core/ListViewProvider'
import {useParams} from 'react-router-dom'
import {createRecord, updateRecord} from '../../core/_requests'
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from '../../../../../../../../../utils/NotifyUtils'

type Props = {
  isRecordLoading: boolean
  record: Record
}

const editRecordSchema = Yup.object().shape({
  name: Yup.string()
    .min(0, 'Minimum 3 symbols')
    .max(500, 'Maximum 500 symbols')
    .required('Record name is required'),
})

const RecordEditModalForm: FC<Props> = ({record, isRecordLoading}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {cloudflareDomainId} = useParams()

  //TODO: Make sure that all port, weight, proiority is not passed for all the record types
  const [recordForEdit] = useState<Record>({
    name: record.name || initialRecord.name,
    id: record.id || initialRecord.id,
    type: record.type || initialRecord.type,
    priority: record.priority || initialRecord.priority,
    weight: record.weight || initialRecord.weight,
    port: record.port || initialRecord.port,
    ttl: record.ttl || initialRecord.ttl,
    content: record.content || initialRecord.content,
    proxied: record.proxied || initialRecord.proxied,
    proxiable: record.proxiable || initialRecord.proxiable,
  })

  const [selectedRecordType, setSelectedRecordType] = useState<DNSRecordType>(recordForEdit.type)
  const handleRecordTypeChange = (type: DNSRecordType) => {
    formik.setFieldValue('type', type)
    setSelectedRecordType(type)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: recordForEdit,
    validationSchema: editRecordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (values.type != DNSRecordType.MX) {
        delete values.priority
      }
      // if (values.type != DNSRecordType.SRV) {
      //   delete values.port
      //   delete values.weight
      // }
      if (isNotEmpty(values.id)) {
        updateRecord(cloudflareDomainId, values.id, values)
          .then((data) => {
            notifySuccess('Record updation successful')
          })
          .catch((error) => {
            notifyError(`Record updation failed: ${error.message}`)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      } else {
        const toastId = notifyProgress('Record addition in progress..')
        await createRecord(values, cloudflareDomainId)
          .then((data) => {
            notifySuccess('Record addition successful', toastId)
          })
          .catch((error) => {
            notifyError(`Record addition failed: ${error.message}`, toastId)
            console.log(error.message)
          })
          .finally(() => {
            setSubmitting(false)
            cancel(true)
          })
      }
    },
  })

  console.log(formik.getFieldProps('proxied'))
  console.log(formik.getFieldProps('proxiable'))
  return (
    <>
      <form
        id='kt_modal_add_record_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_record_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_record_header'
          data-kt-scroll-wrappers='#kt_modal_add_record_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            <input
              placeholder='Record Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={
                formik.isSubmitting ||
                isRecordLoading ||
                (record.name != undefined && record.name.length > 0)
              }
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Type</label>
            <select
              disabled={record.name != undefined && record.name.length > 0}
              className='form-select form-select-solid form-select-lg'
              {...formik.getFieldProps('type')}
              name='type'
              value={selectedRecordType}
              onChange={(e: any) => {
                handleRecordTypeChange(e.target.value)
              }}
            >
              {Object.values(DNSRecordType)?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {formik.touched.type && formik.errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.type}</div>
              </div>
            )}

            {formik.touched.type && formik.errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.type}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Value</label>
            <input
              placeholder='Record Value'
              {...formik.getFieldProps('content')}
              type='text'
              name='content'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {
                  'is-invalid': formik.touched?.content && formik.errors?.content,
                },
                {
                  'is-valid': formik.touched?.content && !formik.errors?.content,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isRecordLoading}
            />
            {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>TTL</label>
            <input
              placeholder='Record Value'
              {...formik.getFieldProps('ttl')}
              type='number'
              name='ttl'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {
                  'is-invalid': formik.touched?.ttl && formik.errors?.ttl,
                },
                {
                  'is-valid': formik.touched?.ttl && !formik.errors?.ttl,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isRecordLoading}
            />
            {formik.touched.ttl && formik.errors.ttl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.ttl}</span>
                </div>
              </div>
            )}
          </div>
          {selectedRecordType === DNSRecordType.MX && (
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Priority</label>
              <input
                placeholder='Priority'
                {...formik.getFieldProps('priority')}
                type='number'
                name='priority'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.priority && formik.errors.priority,
                  },
                  {
                    'is-valid': formik.touched.priority && !formik.errors.priority,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isRecordLoading}
              />
              {formik.touched.priority && formik.errors.priority && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.priority}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* NOTE: Not giving SRV option as it won't really be used */}
          {/* {selectedRecordType === DNSRecordType.SRV && (
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Weight</label>
              <input
                placeholder='Weight'
                {...formik.getFieldProps('weight')}
                type='number'
                name='weight'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.weight && formik.errors.weight,
                  },
                  {
                    'is-valid':
                      formik.touched.weight && !formik.errors.weight,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isRecordLoading}
              />
              {formik.touched.weight && formik.errors.weight && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.weight}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {selectedRecordType === DNSRecordType.SRV && (
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Port</label>
              <input
                placeholder='Port'
                {...formik.getFieldProps('port')}
                type='number'
                name='port'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.port && formik.errors.port,
                  },
                  {
                    'is-valid':
                      formik.touched.port && !formik.errors.port,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isRecordLoading}
              />
              {formik.touched.port && formik.errors.port && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.port}</span>
                  </div>
                </div>
              )}
            </div>
          )} */}

          {formik.getFieldProps('proxiable').value && (
            <div className='fv-row mb-7 mt-2 d-flex flex-row justify-content-between'>
              <label className='required fw-bold fs-6 mb-2'>Proxied</label>
              <label className='form-switch'>
                <input
                  {...formik.getFieldProps('proxied')}
                  checked={formik.getFieldProps('proxied').value}
                  type='checkbox'
                  name='proxied'
                  className={clsx(
                    'form-check-input ',
                    {
                      'is-invalid': formik.touched.proxied && formik.errors.proxied,
                    },
                    {
                      'is-valid': formik.touched.proxied && !formik.errors.proxied,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isRecordLoading}
                />
              </label>
              {formik.touched.proxied && formik.errors.proxied && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.proxied}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-records-modal-action='cancel'
            disabled={formik.isSubmitting || isRecordLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-records-modal-action='submit'
            disabled={
              isRecordLoading ||
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.touched ||
              !formik.dirty
            }
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isRecordLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isRecordLoading) && <RecordsListLoading />}
    </>
  )
}

export {RecordEditModalForm}
