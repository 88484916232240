import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {AccessDetailsDto} from '../../../../applications-list/core/_models'
import {
  addDomainToApplication,
  getDomainByApplication,
} from '../../../../applications-list/core/_requests'
import {notifyError, notifyProgress, notifySuccess} from '../../../../../../utils/NotifyUtils'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const Domain = () => {
  const {applicationId} = useParams()

  const [domain, setDomain] = useState<any>()
  const [domainName, setDomainName] = useState<string>()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  useEffect(() => {
    getDomainByApplication(applicationId ?? '').then((resp) => {
      setDomain(resp)
      if (resp?.domain_name) {
        setDomainName(resp.domain_name)
      }
    })
  }, [])

  const addDomainHandler = () => {
    if (!domainName) return
    const notifyId = notifyProgress('Domain addition in progress')
    addDomainToApplication(applicationId ?? '', domainName)
      .then(() => {
        getDomainByApplication(applicationId ?? '').then((resp) => {
          if (resp?.domain_name) {
            setDomain(resp)
            setDomainName(resp.domain_name)
          }
        })
        notifySuccess('Domain Addition Successful', notifyId)
      })
      .catch((error) => {
        notifyError('Domain addition failed: ' + error.message, notifyId)
      })
  }

  return (
    <>
      <div className='card mb-3 mb-xl-10 w-100' id='dc_access_details_view'>
        <TabHeader heading='Domain' />
        <TabBody>
          {!domain ? (
            <div className='w-100 text-center'>
              <span className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </span>
            </div>
          ) : (
            <>
              <p className='fs-6 text-gray-600'>
                Add domains without https, http or any extensions e.g. cloudphant.com.
              </p>
              <div className='input-group mb-4'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Domain Name (ex: cloudphant.com)'
                  aria-label='Domains'
                  aria-describedby='basic-addon2'
                  value={domainName}
                  onChange={(e) => {
                    let regex = new RegExp(
                      /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
                    )
                    if (regex.test(e.target.value) == true) {
                      setIsButtonDisabled(false)
                    } else {
                      setIsButtonDisabled(true)
                    }
                    setDomainName(e.target.value.trim())
                  }}
                />
                <button
                  className='btn btn-primary'
                  id='basic-addon2'
                  onClick={addDomainHandler}
                  disabled={isButtonDisabled}
                >
                  Add Domain
                </button>
              </div>
              {isButtonDisabled == true && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      <b>
                        Invalid domain name, please make sure it doesn't contain http(s) or any
                        illegal characters.
                      </b>
                    </span>
                  </div>
                </div>
              )}
              {domain?.domain_name && !domain?.is_valid_a_record && (
                <div className='alert alert-primary d-flex align-items-center p-4 py-5'>
                  <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>

                  <div className='d-flex flex-column'>
                    <h3 className='mb-1 text-primary mb-4'>A record Not Verified!</h3>
                    <br />

                    <p className='fs-6 text-primary'>
                      Please add the following IP address as the A record to the domain
                      <br className='mb-2' />
                      <strong> A Record: </strong> {domain?.a_record}
                    </p>
                  </div>
                </div>
              )}
              {domain?.domain_name && domain?.is_valid_a_record && (
                <div className='alert alert-success d-flex align-items-center p-4 py-5'>
                  <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>

                  <div className='d-flex flex-column'>
                    <h3 className='mb-1 text-success mb-4'>A record Verified!</h3>
                    <br />

                    <p className='fs-6 text-success'>
                      Your domain is now pointing to this application. You can now add the SSL
                      certificates and start sharing your application to the world!
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </TabBody>
      </div>
    </>
  )
}

export default Domain
