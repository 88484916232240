import React, {useEffect, useState} from 'react'
import {MonitService, ServerServiceStatus} from '../../../../servers-list/core/_models'
import {getMonitService, updateMonitService} from '../../../../servers-list/core/_requests'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import PasswordField from '../../../../../../utils/PasswordField'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const Monit = () => {
  const [monitService, setMonitService] = useState<MonitService>({
    username: '',
    password: '',
    url: '',
    state: false,
    status: ServerServiceStatus.STOPPED,
  })
  const {serverId} = useParams()
  const [error, setError] = useState<any>()

  const [isUpdating, setIsUpdating] = useState(false)
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false)

  const getMonit = () => {
    getMonitService(serverId)
      .then((data) => {
        setMonitService(data)
      })
      .catch((e) => {
        setError('Failed to retrieve monit state: ' + e.message)
      })
  }

  useEffect(() => {
    getMonit()
  }, [])

  const handleSaveButtonClick = () => {
    setIsUpdating(true)
    updateMonitService(serverId ?? '', monitService.state)
      .then((response) => {
        if (response) {
          notifySuccess('Monit state changed successfully...')
        } else {
          notifyError('Monit state change failed...')
          setMonitService((x) => {
            x.state = !monitService.state
            return x
          })
        }
        getMonit()
      })
      .catch((error) => {
        notifyError(error.message)
        setMonitService((x) => {
          x.state = !monitService.state
          return x
        })
      })
      .finally(() => {
        setIsUpdating(false)
        setIsSaveButtonActive(false)
      })
  }

  const handleToggleChange = () => {
    setMonitService((x) => {
      x.state = !x.state
      return x
    })

    setIsSaveButtonActive(!isSaveButtonActive)
  }

  return (
    <div className='card mb-3 mb-xl-10 w-100' id='basic_data_tab'>
      <TabHeader heading='Monit' />
      <TabBody>
        {monitService?.status === ServerServiceStatus.UNKNOWN && (
          <div className='alert alert-danger d-flex align-items-center p-4'>
            <div className='d-flex flex-column'>
              <p className='mb-1'>
                Seems like your monit installation had FAILED! Try enabling monit
              </p>
            </div>
          </div>
        )}
        {monitService?.status === ServerServiceStatus.PENDING ? (
          <div className='alert alert-warning d-flex align-items-center p-4'>
            <div className='d-flex flex-column'>
              <h5 className='mb-1'>Monit operation is in progress!</h5>
              <span>Please wait for some time and try again.</span>
            </div>
          </div>
        ) : (
          <>
            <div className='row mb-10'>
              <div className='col-lg-12 mt-3'>
                <div className='row align-items-center'>
                  <div className='col-lg-2'>Monit Status:</div>
                  <div className='col-lg-1 form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      checked={monitService.state}
                      onChange={handleToggleChange}
                      style={{
                        backgroundColor: monitService.state ? 'green' : 'grey',
                        borderColor: monitService.state ? 'green' : 'grey',
                      }}
                    />
                  </div>
                  <div className='col-lg-6'>
                    <button
                      type='button'
                      className={`btn ${isUpdating ? 'btn-warning' : 'btn-primary'} me-10`}
                      onClick={handleSaveButtonClick}
                      disabled={!isSaveButtonActive || isUpdating}
                    >
                      <span className='indicator-label'>
                        {isUpdating ? 'Please wait . . .' : 'Save'}
                      </span>
                      {isUpdating && (
                        <span className='indicator-progress'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {monitService.state && (
              <div>
                <div className='border p-3 mt-3' style={{marginRight: '20px'}}>
                  <div className='px-6' key={'url'}>
                    <div className='row mb-4'>
                      <label className='col-lg-2 fw-bold text-muted'>MONIT URL: </label>
                      <div className='col-lg-8'>
                        <a href={monitService.url} target='_blank'>
                          <span className='fw-bolder fs-6'>{monitService.url}</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  {monitService.username.length > 0 && (
                    <>
                      <div className='px-6'>
                        <div className='row mb-4'>
                          <label className='col-lg-2 fw-bold text-muted'>MONIT USERNAME: </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {monitService.username}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='px-6'>
                        <div className='row mb-4'>
                          <label className='col-lg-2 fw-bold text-muted'>MONIT PASSWORD: </label>
                          <div className='col-lg-8'>
                            <PasswordField password={monitService.password ?? ''} />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </TabBody>
    </div>
  )
}

export default Monit
