// eslint-disable-next-line
import React from 'react'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Tabs from '../../../../../utils/Tabs'
import MalwareScanner from './components/MalwareScanner'

const Security = () => {
  const {applicationId} = useParams()

  return (
    <Routes>
      <Route
        element={
          <>
            <div className='card mb-5 mb-xl-10' id='dc_settings_view'>
              <div className='d-flex overflow-auto'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Security</h3>
                  </div>
                </div>
              </div>
              <div className='d-flex pt-3'>
                <Tabs
                  tabs={[
                    {
                      name: 'Malware Scanner',
                      path: `/application/${applicationId}/security/malware-scanner`,
                    },
                  ]}
                />
                <Outlet />
              </div>
            </div>
          </>
        }
      >
        <Route
          path='malware-scanner'
          element={
            <>
              <MalwareScanner />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='malware-scanner' />} />
    </Routes>
  )
}

export default Security
