import {Link, Navigate, Outlet, Route, Routes, useLocation, useParams} from 'react-router-dom'
import {Configuration} from './components/configuration'
import {TieredCacheTopology} from './components/tieredCache'

export function Caching() {
  return (
    <Routes>
      <Route
        element={
          <div className='card mb-5 mb-xl-10' id='caching_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Caching</h3>
              </div>
            </div>

            <div className='d-flex pt-3'>
              {/* <div className='pb-5 px-9'>
                  <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6'>
                    <li className='nav-item w-md-200px me-0 mb-4'>
                      <Link
                        to={`/integrations/cloudflare/domain/${cloudflareDomainId}/caching/configuration`}
                        className={
                          `nav-link ` +
                          (location.pathname ===
                            `/integrations/cloudflare/domain/${cloudflareDomainId}/caching/configuration` && 'active')
                        }
                      >
                        Configuration
                      </Link>
                    </li>
                    <li className='nav-item w-md-200px me-0 mb-4'>
                      <Link
                        to={`/integrations/cloudflare/domain/${cloudflareDomainId}/caching/tiered-caching`}
                        className={
                          `nav-link ` +
                          (location.pathname ===
                            `/integrations/cloudflare/domain/${cloudflareDomainId}/caching/tiered-caching` && 'active')
                        }
                      >
                        Tiered Caching
                      </Link>
                    </li> 
                  </ul>
                </div> */}
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path='configuration' element={<Configuration />} />
        <Route path='tiered-caching' element={<TieredCacheTopology />} />
      </Route>

      <Route index element={<Navigate to='configuration' />} />
    </Routes>
  )
}
