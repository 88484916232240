/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid max-h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='mt-5 d-md-none'>
            <Link to='/'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-2.png')} className='h-55px' />
            </Link>
            <hr className='mt-10' style={{color: '#808080'}}></hr>
          </div>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>

        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='https://cloudphant.com/terms-conditions/' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='https://cloudphant.com/privacy-policy/' className='px-5' target='_blank'>
              Privacy
            </a>

            <a href='https://cloudphant.com/pricing' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='https://cloudphant.com/' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <div
        className='d-none d-md-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        <div className='d-flex flex-column flex-lg-row-fluid flex-center py-15 px-5 px-md-15 w-100'>
          <Link to='/' style={{marginBottom: '55px'}}>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-60px' />
          </Link>
          <img
            className='mx-auto w-275px w-md-50 w-xl-400px mb-20 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          />
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            High Performance Wordpress Stack
          </h1>
          <div className='text-white fs-base text-center'>
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              Cloudphant
            </a>
            is the only managed wordpress stack you will ever need. Save your time from managing
            unnecessary plugins, optimizations and server side heavy liftings. We have got you
            covered on all of this.
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
