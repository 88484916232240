import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import AccessDetails from './application-settings/components/access-details/AccessDetails'
import {ApplicationHeader} from './application-settings/ApplicationHeader'
import SSL from './application-settings/components/domains/components/SSL'
import Crons from './application-settings/components/cron/Crons'
import Logs from './application-settings/components/logs/Logs'
import Staging from './application-settings/components/staging/Staging'
import BackupRestore from './application-settings/components/backup-restore/BackupRestore'
import Optimizations from './application-settings/components/optimizations/Optimizations'
import ApplicationPageTips from './knowledge-base/ApplicationPageTips'
import DomainManager from './application-settings/components/domains/DomainManager'
import Monitoring from './application-settings/components/monitoring/Monitoring'
import Security from './application-settings/components/security/Security'

const applicationBreadcrumbs: Array<PageLink> = [
  {
    title: 'Website Settings',
    path: '/application/:applicationId/access-details',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Domain',
    path: '/application/:applicationId/domain',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'SSL',
    path: '/application/:applicationId/ssl',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Staging',
    path: '/application/:applicationId/staging',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Backup & Restore',
    path: '/application/:applicationId/backup-restore',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Optimizations',
    path: '/application/:applicationId/optimizations',
    isSeparator: true,
    isActive: false,
  },
]

const ApplicationPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <ApplicationHeader />
            <ApplicationPageTips />
            <Outlet />
          </>
        }
      >
        <Route
          path=':applicationId/access-details'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Access Details</PageTitle>
              <AccessDetails />
            </>
          }
        />
        <Route
          path=':applicationId/domain-manager/*'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Domain Manager</PageTitle>
              <DomainManager />
            </>
          }
        />
        <Route
          path=':applicationId/staging'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Staging</PageTitle>
              <Staging />
            </>
          }
        />
        <Route
          path=':applicationId/backup-restore/*'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Backup & Restore</PageTitle>
              <BackupRestore />
            </>
          }
        />
        <Route
          path=':applicationId/optimizations/*'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Optimizations</PageTitle>
              <Optimizations />
            </>
          }
        />
        <Route
          path=':applicationId/crons'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Crons</PageTitle>
              <Crons />
            </>
          }
        />
        <Route
          path=':applicationId/logs/*'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Logs</PageTitle>
              <Logs />
            </>
          }
        />
        <Route
          path=':applicationId/security/*'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Security</PageTitle>
              <Security />
            </>
          }
        />
        <Route
          path=':applicationId/monitoring/*'
          element={
            <>
              <PageTitle breadcrumbs={applicationBreadcrumbs}>Monitoring</PageTitle>
              <Monitoring />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ApplicationPage
