import {KTCard} from '../../../../../../../../_metronic/helpers'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RecordEditModal} from './components/record-add-edit-model/RecordEditModal'
import {RecordsListHeader} from './components/table/header/RecordsListHeader'
import {RecordsTable} from './components/table/RecordsTable'
const DomainRecords = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        <RecordsListHeader />
        <RecordsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <RecordEditModal />}
    </>
  )
}

const DomainRecordsWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DomainRecords />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DomainRecordsWrapper}
