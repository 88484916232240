import {FC} from 'react'
import {Application} from '../../../core/_models'

type Props = {
  application: Application
}

const ApplicationProjectCell: FC<Props> = ({application}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>{application.project?.name}</div>
  </div>
)

export {ApplicationProjectCell}
