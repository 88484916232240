import {useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody} from '../../../../../../../_metronic/helpers'
import {DomainsListLoading} from '../DomainsListLoading'
import {DomainsListPagination} from '../DomainsListPagination'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {cloudflareDomainsColumns} from './columns/_columns'
import {CloudflareDomain} from '../../core/_models'
import {useQueryResponseData, useQueryResponseLoading} from '../../core/QueryResponseProvider'

const DomainsTable = () => {
  const isLoading = useQueryResponseLoading()
  const isKeyAdded = true
  // Check if key is added or not then assign value here

  const cloudflareDomains = useQueryResponseData()
  const data = useMemo(() => cloudflareDomains, [cloudflareDomains])
  const columns = useMemo(() => cloudflareDomainsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <>
      {isLoading ? (
        <DomainsListLoading />
      ) : (
        <KTCardBody className='py-4'>
          {!isKeyAdded && (
            <div className='alert alert-warning d-flex align-items-center p-4 mb-10'>
              <div className='d-flex flex-column'>
                <h5 className='mb-1'>You need to Add Key first!</h5>
                <span>
                  Go to <a href='/integrations'>Cloudflare tab</a> under integrations and add key
                  there.
                </span>
              </div>
            </div>
            // Add URL of tab where key needs to be added here
          )}
          {isKeyAdded && (
            <div className='table-responsive'>
              <table
                id='kt_table_cloudflareDomains'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<CloudflareDomain>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<CloudflareDomain>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <DomainsListPagination />
          {isLoading && <DomainsListLoading />}
        </KTCardBody>
      )}
    </>
  )
}

export {DomainsTable}
