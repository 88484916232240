import {RecordsListSearchComponent} from './RecordsListSearchComponent'
import {RecordsListToolbar} from './RecordsListToolbar'

const RecordsListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <RecordsListSearchComponent />
      <div className='card-toolbar'>
        <RecordsListToolbar />
      </div>
    </div>
  )
}

export {RecordsListHeader}
